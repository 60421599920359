import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

declare global {
    interface Window {
      gtag?: (
        key: string,
        trackingId: string,
        config: { page_path: string }
      ) => void
    }
}

const GoogleAnalytics = () => {
    const { listen } = useHistory();

    useEffect(() => {
        const unlisten = listen((location) => {
            const trackingId = "UA-166304270-1";
            if (!window.gtag) return
            if (!trackingId) {
              console.log(
                'Tracking not enabled, as `trackingId` was not given and there is no `GA_MEASUREMENT_ID`.'
              )
              return
            }
      
            window.gtag('config', trackingId, { page_path: location.pathname })
          })
      
          return unlisten
    }, [listen]);

    return (
        <>
        </>
    );
}

export default GoogleAnalytics;