import React, { createContext, useState, useEffect, useCallback, useContext } from 'react';
import { GlobalContext } from '../Global/GlobalContext';
import { toast } from 'react-toastify';

type ChallengeProviderProps = {
    children?: any
}

interface IGlobalContext {
    state?: any,
    setLoading?: Function
}

interface IDay {
    _id: string,
    wid: string,
    name?: string,
    type: "video" | "workout",
    data?: any,
    video?: string
}

interface ILog {
    pid?: string,
    wid: string,
    data: any
}

interface IExercise {
    _id: string,
    name: string,
    category?: string[],
    description?: string,
    video?: string,
    image?: string,
    cardioOnly: boolean
}

const ChallengeContext = createContext({});

const ChallengeProvider = ({ children }: ChallengeProviderProps) => {
    const globalVariables: any = window;
    const globalContext: IGlobalContext = useContext(GlobalContext);

    const [exercises, setExercises] = useState<IExercise[] | null>(null);
    const [day, setDay] = useState<IDay | undefined>(undefined);
    const [exerciseIndex, setExerciseIndex] = useState<number>(1);
    const [log, setLog] = useState<ILog | any>(null);
    const [timer, setTimer] = useState<string | null>(null);
    const [timeLoop, setTimeLoop] = useState<any>(undefined);

    const fetchExercises = useCallback(async () => {
        await fetch(`${globalVariables.api}/workout/exercise/all`, {
            method: 'GET',
            credentials: 'include',
            mode: 'cors',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            })
        })
        .then(async (res) => {
            let data = await res.json();

            if(res.status === 200) {
                setExercises(data);
            } else {
                return;
            }
        })
        .catch(err => {
            return;
        });
    }, []);

    const initialiseWorkout = () => {
        initialiseTimer();
    }

    const initialiseTimer = () => {
        var startTime = Math.floor(new Date().getTime() / 1000); //Get the starting time (right now) in seconds

        function startTimeCounter() {
            var now = Math.floor(Date.now() / 1000); // get the time now
            var diff = (now - startTime); // diff in seconds between now and start
            var h: any = 0;
            var m: any = Math.floor(diff / 60); // get minutes value (quotient of diff)
            if(m >= 60) {
                h = Math.floor((m / 60));
                m = parseInt(m) - (parseInt(h) * 60);
            }
            var s = Math.floor(diff % 60); // get seconds value (remainder of diff)
            h = checkTime(h);
            m = checkTime(m); // add a leading zero if it's single digit
            s = checkTime(s); // add a leading zero if it's single digit
            setTimer(`${h}:${m}:${s}`);
            // setTimeout(startTimeCounter, 500); // set a timeout to update the timer

        }

        function checkTime(i: any) {
            if (i < 10) {i = "0" + i};  // add zero in front of numbers < 10
            return i;
        }

        let timeLoop = setInterval(() => startTimeCounter(), 500);
        setTimeLoop(timeLoop);
    }

    const completeWorkout = async () => {
        globalContext.setLoading && globalContext.setLoading(true);

        const logObj = {
            ...log,
            date: new Date(),
            duration: timer
        };

        let result = await fetch(`${globalVariables.api}/workout/challenge/log`, {
            method: 'POST',
            credentials: 'include',
            mode: 'cors',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }),
            body: JSON.stringify(logObj)
        })
        .then(res => {
            if(res.status === 200) {
                // resetLogging();
                globalContext.setLoading && globalContext.setLoading(false);
                // toast.success("Workout complete - well done!");
                return true;
            } else if (res.status === 500) {
                globalContext.setLoading && globalContext.setLoading(false);
                toast.error("There has been a server error. Please try again.");
                return false;
            } else {
                globalContext.setLoading && globalContext.setLoading(false);
                toast.error("There has been a server error. Please try again.");
                return false;
            }
        })
        .catch(err => {
            globalContext.setLoading && globalContext.setLoading(false);
            toast.error("There has been a server error. Please try again.");
            return false;
        });

        return result;
    }

    const resetLogging = async () => {
        window.clearInterval(timeLoop);
        setDay(undefined);
        setExerciseIndex(1);
        setLog(null);
        setTimer(null);
    }

    const updateLogData = (e: any, setIndex: number, superset: string | undefined = undefined, dropset = false) => {
        let logData = {...log};

        if(superset) {
            logData.data[exerciseIndex - 1].supersetExercises.forEach((ssExercise: any) => {
                ssExercise.sets[setIndex].log = ssExercise.sets[setIndex].log ? {...ssExercise.sets[setIndex].log} : {};
                ssExercise.sets[setIndex].log = {
                    ...ssExercise.sets[setIndex].log,
                    [e.target.name]: e.target.value
                }
            });
        } else {
            logData.data[exerciseIndex - 1].sets[setIndex].log = {
                ...logData.data[exerciseIndex - 1].sets[setIndex].log,
                [e.target.name]: e.target.value
            }
        }

        setLog(logData);
    }

    useEffect(() => {
        if(globalContext.state.isLoggedIn) {
            fetchExercises();
        }
    }, [fetchExercises, globalContext.state.isLoggedIn]);

    useEffect(() => {
        if(day) {
            let logObj = {
                did: day._id,
                ...day
            }

            setLog(logObj);
            initialiseTimer();
        }
        
    }, [day]);

    return (
        <ChallengeContext.Provider value={{
            state: {
                exercises,
                day,
                log,
                timer,
                exerciseIndex
            },
            setDay,
            setLog,
            initialiseWorkout,
            completeWorkout,
            resetLogging,
            setExerciseIndex,
            updateLogData
            }}>
              {children}
        </ChallengeContext.Provider>
    );
}

const ChallengeConsumer = ChallengeContext.Consumer;

export { ChallengeContext, ChallengeProvider, ChallengeConsumer }