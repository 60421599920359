import React from 'react';
import Carousel from 'react-multi-carousel';
import "react-multi-carousel/lib/styles.css";
import { Link } from 'react-router-dom';
import Recipe from '../../Containers/Recipe/Recipe';

const RecipeCollections = () => {
    const globalVariables: any = window;
    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 10
          },
          desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 8
          },
          tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 5
          },
          mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 2
          }
    }

    return (
        <div className="recipe-carousel">
            <h2>
                Recipe Collections
            </h2>
            <Carousel responsive={responsive}
                        showDots={false}
                        arrows={false}
                        infinite={false}>
                <Link to="/recipes/Pre-workout" className="recipe-carousel-card collection">
                    <img src={`${globalVariables.api}/media/images-1589493957947.jpeg`} />
                    <div className="recipe-carousel-card__overlay"></div>
                    <div className="recipe-carousel-card__title">Pre-workout</div>
                </Link>
                <Link to="/recipes/Post-workout" className="recipe-carousel-card collection">
                    <img src={`${globalVariables.api}/media/images-1593428204999.jpeg`} />
                    <div className="recipe-carousel-card__overlay"></div>
                    <div className="recipe-carousel-card__title">Post-workout</div>
                </Link>
                <Link to="/recipes/calories/300" className="recipe-carousel-card collection">
                    <img src={`${globalVariables.api}/media/images-1589488050655.jpeg`} />
                    <div className="recipe-carousel-card__overlay"></div>
                    <div className="recipe-carousel-card__title">Less than 300kcals</div>
                </Link>
                <Link to="/recipes/calories/400" className="recipe-carousel-card collection">
                    <img src={`${globalVariables.api}/media/images-1589493482720.jpeg`} />
                    <div className="recipe-carousel-card__overlay"></div>
                    <div className="recipe-carousel-card__title">Less than 400kcals</div>
                </Link>
                <Link to="/recipes/calories/500" className="recipe-carousel-card collection">
                    <img src={`${globalVariables.api}/media/images-1590154244023.jpeg`} />
                    <div className="recipe-carousel-card__overlay"></div>
                    <div className="recipe-carousel-card__title">Less than 500kcals</div>
                </Link>
                <Link to="/recipes/calories/600" className="recipe-carousel-card collection">
                    <img src={`${globalVariables.api}/media/images-1589223083191.jpg`} />
                    <div className="recipe-carousel-card__overlay"></div>
                    <div className="recipe-carousel-card__title">Less than 600kcals</div>
                </Link>
            </Carousel>
        </div>
    );
}

export default RecipeCollections;