import React, { useState, useEffect, useCallback, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Header from '../../Components/Header/Header';

// Contexts
import { GlobalContext } from '../../Contexts/Global/GlobalContext';

interface IGuide {
    _id: string,
    title: string,
    slug: string,
    duration?: number,
    description?: string,
    video?: string,
    image?: string,
    date: Date,
    type: string
    [x:string]: any
}

interface IGlobalContext {
    state?: any,
    setIsLoggedIn?: Function,
    setLoading?: Function,
    setInitialAppData?: Function,
    setPlan?: Function
}

interface IDatePick {
    open: boolean,
    date: Date | undefined
}

interface IExistingOption {
    open: boolean,
    action: undefined | "delete" | "concurrent"
}

interface IGuideDetailProps {
    history: any
}

const GuideDetail = ({ history }: IGuideDetailProps) => {
    const [guide, setGuide] = useState<IGuide | undefined>(undefined);
    const [datePicker, setDatePicker] = useState<IDatePick>({ open: false, date: undefined });
    const [existingOption, setExistingOption] = useState<IExistingOption>({open: false, action: undefined});
    const globalVariables: any = window;

    const globalContext: IGlobalContext = useContext(GlobalContext);

    const { slug } = useParams();
    
    const getGuide = useCallback(async () => {
        let globalVariables: any = window;
        globalContext.setLoading && globalContext.setLoading(true);
        await fetch(`${globalVariables.api}/workout/detail/${slug}`, {
            method: 'GET',
            credentials: 'include',
            mode: 'cors',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            })
        })
        .then(async (res) => {
            let data = await res.json();

            if(res.status === 200) {
                setGuide(data);
                globalContext.setLoading && globalContext.setLoading(false);
            } else {
                toast.error("There was an error fetching plan data.");
                globalContext.setLoading && globalContext.setLoading(false);
            }
        })
        .catch(err => {
            console.log(err);
            toast.error("There was an error fetching plan data.");
            globalContext.setLoading && globalContext.setLoading(false);
        });
    }, []);

    useEffect(() => {
        getGuide();
    }, [getGuide]);

    const existingCheck = () => {
        if(globalContext.state.plan && globalContext.state.plan.length > 0) {
            setExistingOption({...existingOption, open: true});
        } else {
            setDatePicker({...datePicker, open: true});
        }
    }

    const subscribeToPlan = async () => {
        globalContext.setLoading && globalContext.setLoading(true);
        const data = { workoutid: guide && guide._id, startDate: datePicker.date };

        let existing, action;

        existing = globalContext.state.plan && globalContext.state.plan.length > 0 ? globalContext.state.plan.map((plan: any) => plan._id) : [];
        action = existingOption.action;

        await fetch(`${globalVariables.api}/plan/subscribe/options`, {
            method: 'POST',
            credentials: 'include',
            mode: 'cors',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }),
            body: JSON.stringify({...data, existing, action})
        })
        .then(async res => {
            let data = await res.json();
            if(res.status === 200) {
                setDatePicker({...datePicker, open: false});

                if(globalContext.setPlan) {
                    if(existingOption.action === "delete") {
                        let arr = [];

                        arr.push(data);

                        globalContext.setPlan(arr);
                    } else {
                        let arr = globalContext.state.plan ? globalContext.state.plan : [];
                        arr.push(data);
                        globalContext.setPlan(arr);
                    }
                }
                setTimeout(() => {
                    globalContext.setLoading && globalContext.setLoading(false);
                    toast.success("You are now subscribed to the plan.");
                    history.push("/");
                }, 2000);
            } else if (res.status === 401) {
                window.location.href = "/";
            } else {
                console.log("here");
                toast.error("There was a problem subscribing to this plan. Please try again.");
                globalContext.setLoading && globalContext.setLoading(false);
            }
        })
        .catch(err => {
            console.log(err);
            toast.error("There was a problem subscribing to this plan. Please try again.");
            globalContext.setLoading && globalContext.setLoading(false);
        });
    }

    return (
        <>
        <Header title="Plan Detail" />
        <div className="home-container">
        {guide &&
            <>
                <div className="btn-float-container">
                    <div className="split-layout">
                    <div className="video-library-item detail">
                        <div className="video-library-item__image">
                            {guide.image && <img src={`${globalVariables.api}/media/${guide.image}`} alt={guide.title} />}
                            {guide.duration &&
                                <div className="video-library-item__time">
                                    <svg>
                                        <use xlinkHref="/assets/icon-library.svg#stopwatch" />
                                    </svg>
                                    <span>{guide.duration} mins</span>
                                </div>
                            }
                        </div>
                    </div>
                    <div>
                        <div className="video-library-item__title detail">
                            {guide.title}
                        </div>
                        {guide.description &&
                            <p dangerouslySetInnerHTML={{__html: guide.description}}></p>
                        }
                    </div>
                    </div>
                    <div className="btn-float-bottom">
                        <button className="btn btn--block" onClick={() => existingCheck()}>
                            Start Plan  
                        </button>
                    </div>
                </div>
                <div className={`overlay ${datePicker.open || existingOption.open ? 'is-active' : ''}`} onClick={() => { setDatePicker({open: false, date: new Date()}); setExistingOption({open: false, action: undefined})}}></div>
                <div className={`modal ${datePicker.open ? 'is-open' : ''}`}>
                    <h2>When would you like to start?</h2>
                    <div className="form-item">
                        <label>Date</label>
                        <input type="date" onChange={(e) => setDatePicker({...datePicker, date: new Date(e.target.value)})} />
                    </div>
                    <button className="btn btn--block btn--green" disabled={datePicker.date === undefined} onClick={() => subscribeToPlan()}>Let's Go!</button>
                </div>
                <div className={`modal ${existingOption.open ? 'is-open' : ''}`}>
                    <h2>You are already currently following a plan. Would you like to stop your existing plan or follow alongside your existing plan?</h2>
                    <div className="modal__actions">
                        <button className="btn" onClick={() => { setExistingOption({open: false, action: "delete"}); setDatePicker({...datePicker, open: true})}}>Stop</button>
                        <button className="btn" onClick={() => { setExistingOption({open: false, action: "concurrent"}); setDatePicker({...datePicker, open: true})}}>Concurrent</button>
                    </div>
                </div>
            </>
        }
        </div>
        </>
    );
}

export default GuideDetail;