import React, { useEffect, useCallback, useState, useContext } from 'react';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { GlobalContext } from '../../Contexts/Global/GlobalContext';
import Header from '../../Components/Header/Header';
import GuideCarousel from '../../Components/GuideCarousel/GuideCarousel';
import UpcomingChallenges from '../../Components/UpcomingChallenges/UpcomingChallenges';
import WorkoutSubNav from '../../Components/SubNavs/WorkoutSubNav';

interface IWorkoutCategory {
    _id: string,
    name: string,
    [x:string]: any
}

interface IGuide {
    _id: string,
    date: Date,
    data: any,
    category: string[],
    type: string,
    premium: boolean,
    title: string,
    slug: string,
    description?: string,
    video?: string,
    image?: string
}

interface IGlobalContext {
    setLoading?: Function
}

const WorkoutCategory = () => {
    const globalContext: IGlobalContext = useContext(GlobalContext);
    const [categories, setCategories] = useState<IWorkoutCategory[] | undefined>(undefined);
    const [selection, setSelection] = useState<string>("workout");
    const globalVariables: any = window;
    const [guides, setGuides] = useState<IGuide[] | undefined>(undefined);

    const fetchGuides = useCallback(async () => {
        globalContext.setLoading && globalContext.setLoading(true);
        await fetch(`${globalVariables.api}/workout/Guide`, {
            method: 'GET',
            credentials: 'include',
            mode: 'cors',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            })
        })
        .then(async (res) => {
            let data = await res.json();

            if(res.status === 200) {
                setGuides(data);
                globalContext.setLoading && globalContext.setLoading(false);
            } else {
                toast.error("There was an issue fetching plans.");
                globalContext.setLoading && globalContext.setLoading(false);
            }
        })
        .catch(err => {
            console.log(err);
            toast.error("There was an issue fetching plans.");
            globalContext.setLoading && globalContext.setLoading(false);
        });
    }, []);

    const fetchWorkoutCategories = useCallback(async () => {
        await fetch(`${globalVariables.api}/workout/category/all`, {
            method: 'GET',
            credentials: 'include',
            mode: 'cors',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            })
        })
        .then(async (res) => {
            let data = await res.json();

            if(res.status === 200) {
                setCategories(data);
            } else if (res.status === 401) {
                window.location.href = "/";
            } else {
                // setIsLoggedIn(false);
                // setInitialised(true);
                // setLoading(false);
            }
        })
        .catch(err => {
            console.log(err);
            // setIsLoggedIn(false);
            // setInitialised(true);
        });
    }, []);

    useEffect(() => {
        fetchGuides();
        fetchWorkoutCategories();
    }, [fetchWorkoutCategories]);

    return (
        <>
            <Header title="Workout" />
            <WorkoutSubNav />
            {selection === "workout" ?
            <div className="home-container">
                <UpcomingChallenges />
                {/* <h2 className="section-title">Workout Plans</h2>
                <GuideCarousel />
                <h2 className="section-title">Workout Categories</h2> */}
                <div className="category-grid">
                    <Link to="/favourites/workout" className="category-grid-item favourites">
                        My Favourites
                        <svg>
                            <use xlinkHref="/assets/icon-library.svg#star-solid" />
                        </svg>
                    </Link>
                    {/* {categories && categories.map((category, index) =>
                        <Link key={index} to={`/workout/${category.name}`} className="category-grid-item">
                            <div className="category-grid-item__image">
                            <img src="/assets/workout-categories/abs.jpg" />
                            </div>
                            <div className="category-grid-item__inner">
                                {category.name}
                                <svg>
                                    <use xlinkHref="/assets/icon-library.svg#chevron-right" />
                                </svg>
                            </div>
                        </Link>
                    )} */}
                    <Link to="/workout/15min" className="category-grid-item">
                    <div className="category-grid-item__image">
                            <img src="/assets/workout-categories/15min.jpg" />
                        </div>
                        <div className="category-grid-item__inner">
                                15 Minute Workouts
                                <svg>
                                    <use xlinkHref="/assets/icon-library.svg#chevron-right" />
                                </svg>
                        </div>
                    </Link>
                    <Link to="/workout/Abs" className="category-grid-item">
                        <div className="category-grid-item__image">
                            <img src="/assets/workout-categories/abs.jpg" />
                        </div>
                        <div className="category-grid-item__inner">
                                Abs
                                <svg>
                                    <use xlinkHref="/assets/icon-library.svg#chevron-right" />
                                </svg>
                        </div>
                    </Link>
                    <Link to="/workout/Full%20Body" className="category-grid-item">
                        <div className="category-grid-item__image">
                            <img src="/assets/workout-categories/full-body.jpeg" />
                        </div>
                        <div className="category-grid-item__inner">
                                Full Body
                                <svg>
                                    <use xlinkHref="/assets/icon-library.svg#chevron-right" />
                                </svg>
                        </div>
                    </Link>
                    <Link to="/workout/HIIT" className="category-grid-item">
                    <div className="category-grid-item__image">
                            <img src="/assets/workout-categories/hiit.jpg" />
                        </div>
                        <div className="category-grid-item__inner">
                                HIIT
                                <svg>
                                    <use xlinkHref="/assets/icon-library.svg#chevron-right" />
                                </svg>
                        </div>
                    </Link>
                    <Link to="/workout/Legs" className="category-grid-item">
                    <div className="category-grid-item__image">
                            <img src="/assets/workout-categories/legs.jpg" />
                        </div>
                        <div className="category-grid-item__inner">
                                Legs
                                <svg>
                                    <use xlinkHref="/assets/icon-library.svg#chevron-right" />
                                </svg>
                        </div>
                    </Link>
                    <Link to="/workout/Upper%20Body" className="category-grid-item">
                    <div className="category-grid-item__image">
                            <img src="/assets/workout-categories/upper-body.jpg" />
                        </div>
                        <div className="category-grid-item__inner">
                                Upper Body
                                <svg>
                                    <use xlinkHref="/assets/icon-library.svg#chevron-right" />
                                </svg>
                        </div>
                    </Link>
                    <Link to="/workout/Warm%20Ups%20and%20Cool%20Downs" className="category-grid-item">
                    <div className="category-grid-item__image">
                            <img src="/assets/workout-categories/warm-ups.jpg" />
                        </div>
                        <div className="category-grid-item__inner">
                                Warm Ups & Cool Downs
                                <svg>
                                    <use xlinkHref="/assets/icon-library.svg#chevron-right" />
                                </svg>
                        </div>
                    </Link>
                    <Link to="/workout/Form%20Help" className="category-grid-item">
                    <div className="category-grid-item__image">
                            <img src="/assets/workout-categories/form-help.jpg" />
                        </div>
                        <div className="category-grid-item__inner">
                                Form Help
                                <svg>
                                    <use xlinkHref="/assets/icon-library.svg#chevron-right" />
                                </svg>
                        </div>
                    </Link>
                </div>
            </div>
            :
            <div className="home-container">
                <div className="video-library">
                    {guides && guides.map((guide, index) =>
                        <Link key={index} to={`/plan/detail/${guide.slug}`} className="video-library-item">
                        <div className="video-library-item__image">
                            {guide.image &&
                                <img src={`${globalVariables.api}/media/${guide.image}`} alt={guide.title} />
                            }
                            {guide.slug === null &&
                                <div className="video-library-item__upgrade">
                                    <div>
                                        <p>Only available for Premium Members</p>
                                        <Link to="/go-premium" className="btn">Upgrade</Link>
                                    </div>
                                </div>
                            }
                        </div>
                        <div className="video-library-item__title">
                            {guide.title}
                        </div>
                    </Link>
                    )}
                </div>
            </div>
            }
            
        </>
    );
}

export default WorkoutCategory;