import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import IconDropdown from '../IconDropdown/IconDropdown';
import ChallengeScheduleDay from './ChallengeScheduleDay';

interface IChallenge {
    _id: string,
    name: string,
    slug: string,
    duration: number,
    description: string,
    image: string,
    startDate: Date,
    enrollBy: Date,
    weeks: any[]
}

interface IWeek {
    _id: string,
    cid: string,
    progress: boolean,
    checkin: boolean,
    days: string[]
}

interface IChallengeScheduleProps {
    challenge: IChallenge,
    removeChallengeItem: Function,
    participant: any
}

interface IChallengeLog {
    did: string,
    [x:string]: any
}

const ChallengeScheduleItem = ({ challenge, removeChallengeItem, participant }: IChallengeScheduleProps) => {
    const globalVariables: any = window;
    const [week, setWeek] = useState<IWeek | undefined>(undefined);
    const [diff, setDiff] = useState<string | undefined>(undefined);
    const [logs, setLogs] = useState<IChallengeLog[] | undefined>(undefined);
    const [other, setOther] = useState<any>({checkIn: false, progress: false});

    const fetchWeeks = useCallback(async (diff: number) => {
        await fetch(`${globalVariables.api}/workout/challenge/week/${challenge._id}`, {
            method: 'GET',
            credentials: 'include',
            mode: 'cors',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            })
        })
        .then(async (res) => {
            let data = await res.json();

            if(res.status === 200) {
                setDiff((diff + 1).toString());
                setWeek(data[diff]);
            } 
        })
        .catch(err => {
            console.log(err);
        });
    }, []);

    const fetchLogs = async () => {
        if(week) {
            await fetch(`${globalVariables.api}/workout/challenge/log/${week._id}`, {
                method: 'GET',
                credentials: 'include',
                mode: 'cors',
                headers: new Headers({
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                })
            })
            .then(async (res) => {
                let data = await res.json();
    
                if(res.status === 200) {
                    setLogs(data);
                } 
            })
            .catch(err => {
                console.log(err);
            });
        }
    }

    const fetchOther = async () => {
        if(diff) {
            let date = moment(challenge.startDate).add(diff, "weeks").toDate();
            // let date = moment(challenge.startDate).subtract(2, "weeks").toDate();
            let checkIns = await fetch(`${globalVariables.api}/checkin/gte/${date}`, {
                method: 'GET',
                credentials: 'include',
                mode: 'cors',
                headers: new Headers({
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                })
            })
            .then(async (res) => {
                let data = await res.json();
    
                if(res.status === 200) {
                    if(data.length > 0) {
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    return false;
                }
            })
            .catch(err => {
                console.log(err);
                return false;
            });

            let progress = await fetch(`${globalVariables.api}/progress/gte/${date}`, {
                method: 'GET',
                credentials: 'include',
                mode: 'cors',
                headers: new Headers({
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                })
            })
            .then(async (res) => {
                let data = await res.json();
    
                if(res.status === 200) {
                    if(data.length > 0) {
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    return false;
                }
            })
            .catch(err => {
                console.log(err);
                return false;
            });
            

            setOther({checkIn: checkIns, progress: progress});
        }
    }

    useEffect(() => {
        // What week are we on
        let now = moment(),
        start = moment(challenge.startDate),
        end = moment(challenge.startDate).add(challenge.duration, "weeks"),
        diff = start.diff(now, "weeks") * -1;

        if(diff < challenge.duration) {
            fetchWeeks(diff);
        }
        
    }, []);

    useEffect(() => {
        if(!logs && week) {
            fetchLogs();
            fetchOther();
        }
    }, [week]);

    return (
        <>
            <div className="basic-container">
                <h2 className="section-title section-title--icon">
                    <svg>
                        <use xlinkHref="/assets/icon-library.svg#list" />    
                    </svg>    
                    Challenge Schedule
                    <IconDropdown icon="options" location="right">
                        <a href="" onClick={(e) => { e.preventDefault(); removeChallengeItem(participant._id); }}>Remove</a>
                    </IconDropdown>
                </h2>
                <div className="plan-card">
                    <div className="plan-card__image">
                        <img src={`${globalVariables.api}/media/${challenge.image}`} />
                    </div>
                    <div className="plan-card__header">
                        <div className="plan-card__name">{challenge.name}</div>
                        
                        <div className="plan-card__week">
                        <p>Week <span>{diff && diff}</span></p>
                        </div>
                    </div>
                    <div className="plan-card__itinerary">
                        {week && week.checkin &&
                            <Link to={`/check-in`} className={`plan-card__item ${other.checkIn ? 'is-complete': ''}`}>
                                <p>Check-In</p>
                                <span>
                                    <svg>
                                        <use xlinkHref={`/assets/icon-library.svg#${other.checkIn ? 'check': 'arrow-right'}`} />
                                    </svg>
                                </span>
                            </Link>
                        }
                        {week && week.progress &&
                            <Link to={`/progress`} className={`plan-card__item ${other.progress ? 'is-complete': ''}`}>
                                <p>Progress Photo</p>
                                <span>
                                    <svg>
                                        <use xlinkHref={`/assets/icon-library.svg#${other.progress ? 'check': 'arrow-right'}`} />
                                    </svg>
                                </span>
                            </Link>
                        }
                        {week && week.days.map((day, index) =>
                            <ChallengeScheduleDay day={day} index={index} complete={logs && logs.filter(log => log.did === day).length > 0} key={index} />
                        )}
                    </div>
                </div>   
            </div>
        </>
    );
}

export default ChallengeScheduleItem;