import React, { useCallback, useEffect, useState } from 'react';

const LoggingTimerCountDown = ({time, start, stopTimer}: any) => {
    const [timeLeft, setTimeLeft] = useState<number>(time);

    useEffect(() => {

        if(!start) {
            setTimeLeft(time);
            return;
        }

        if(timeLeft === 0) {
            setTimeLeft(time);
            stopTimer();
            return;
        }

        const intervalId = setInterval(() => {
            setTimeLeft(timeLeft - 1);
        }, 1000);

        return () => clearInterval(intervalId);

    }, [timeLeft, start]);

    return (
        <>
            <div className="countdown">
                <div className="countdown__inner"><span>{timeLeft}</span></div>
            </div>
        </>
    );
}

export default LoggingTimerCountDown;