import React from 'react';
import { Link } from 'react-router-dom';
import ProfileCard from '../../Components/ProfileCard/ProfileCard';
import LogCard from '../../Components/LogCard/LogCard';
import NutritionCard from '../../Components/NutritionCard/NutritionCard';
import ScheduleCard from '../../Components/ScheduleCard/ScheduleCard';
import RecipeCarousel from '../../Components/RecipeCarousel/RecipeCarousel';
import WorkoutCategoryCarousel from '../../Components/WorkoutCategoryCarousel/WorkoutCategoryCarousel';
import ChallengeSchedule from '../../Components/ChallengeSchedule/ChallengeSchedule';

const Home = () => {
    return (
      <>
        <ProfileCard />
        <NutritionCard />
        <ChallengeSchedule />
        <ScheduleCard />
        {/* <WorkoutCategoryCarousel /> */}
        <RecipeCarousel />
        {/* <div className="home-container">
            <div className="action-panel">
                <Link to="/workout" className="action-item">
                    <svg>
                        <use xlinkHref={`/assets/icon-library.svg#dumbbell`} />
                    </svg>
                    <span className="action-item__title">Workout</span>
                </Link>
                <Link to="/nutrition" className="action-item">
                    <svg>
                        <use xlinkHref={`/assets/icon-library.svg#nutrition`} />
                    </svg>
                    <span className="action-item__title">Nutrition</span>
                </Link>
                <Link to="/check-in" className="action-item">
                    <svg>
                        <use xlinkHref={`/assets/icon-library.svg#clock`} />
                    </svg>
                    <span className="action-item__title">Check-in</span>
                </Link>
                <Link to="/progress" className="action-item">
                    <svg>
                        <use xlinkHref={`/assets/icon-library.svg#camera`} />
                    </svg>
                    <span className="action-item__title">Progress</span>
                </Link>
            </div>
            <div className="grid-2-col">
                <LogCard />
                <Link to="/knowledge" className="card generic-card grad-bg-green">
                    <div className="generic-card__inner">
                        <div className="generic-card__icon">
                            <svg>
                                <use xlinkHref="/assets/icon-library.svg#knowledge" />
                            </svg>
                        </div>
                        <p className="generic-card__txt-lrg mb-1">Blog</p>
                    </div>
                </Link>
            </div>
        </div> */}
      </>  
    );
}

export default Home;