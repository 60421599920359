import React, { useState, useContext } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { useSwipeable, Swipeable } from 'react-swipeable'
import { GlobalContext } from '../../Contexts/Global/GlobalContext';
import logo from '../../logo-dark.svg';

type MainLayoutProps = {
    children: any
}

interface IGlobalContext {
    state?: any,
    logOut?: Function
}

const MainLayout = ({ children }: MainLayoutProps) => {
    const globalContext: IGlobalContext = useContext(GlobalContext);
    const [navOpen, setNavOpen] = useState(false);
    const swipeHandler = useSwipeable({ onSwiped: (e) => handleSwipeAction(e) });

    const handleSwipeAction = (e: any) => {
        let dir = e.dir;

        // if(dir === "Right") {
        //     setNavOpen(false);
        // } else if (dir === "Left") {
        //     setNavOpen(true);
        // }
    }

    return (
        <div className={`main-container ${navOpen ? '': ''}`}>
            <nav className={`site-nav ${navOpen ? 'is-open': ''}`}>
                <div className="site-nav__main">
                    <Link to="/" className="site-nav__logo">
                        <img src={logo} alt="Stronger You Logo" />
                    </Link>
                    <div className="site-nav__primary">
                        <NavLink exact activeClassName="is-active" to="/" className="menu-icon">
                            <svg>
                                <use xlinkHref="/assets/icon-library.svg#home" />
                            </svg>
                            <span>Home</span>
                        </NavLink>
                        <NavLink activeClassName="is-active" to="/workout" className="menu-icon">
                            <svg>
                                <use xlinkHref="/assets/icon-library.svg#dumbbell" />
                            </svg>
                            <span>Workout</span>
                        </NavLink>
                        <NavLink activeClassName="is-active" to="/nutrition" className="menu-icon">
                            <svg>
                                <use xlinkHref="/assets/icon-library.svg#nutrition" />
                            </svg>
                            <span>Nutrition</span>
                        </NavLink>
                        <NavLink activeClassName="is-active" to="/progress" className="menu-icon">
                            <svg>
                                <use xlinkHref="/assets/icon-library.svg#camera" />
                            </svg>
                            <span>Progress</span>
                        </NavLink>
                    </div>
                    <div className="site-nav__trigger">
                        <button className="nav-trigger" onClick={() => setNavOpen(!navOpen)}>
                            <svg>
                                <use xlinkHref="/assets/icon-library.svg#menu" />
                            </svg>
                        </button>
                    </div>
                </div>
                <ul className="site-menu">
                    <li>
                        <NavLink exact activeClassName="is-active" to="/" className="menu-item" onClick={() => setNavOpen(false)}>
                            <div className="menu-item__icon">
                                <svg>
                                    <use xlinkHref="/assets/icon-library.svg#home" />
                                </svg>
                            </div>
                            <div className="menu-item__label">Home</div>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink exact activeClassName="is-active" to="/workout" className="menu-item" onClick={() => setNavOpen(false)}>
                            <div className="menu-item__icon">
                                <svg>
                                    <use xlinkHref="/assets/icon-library.svg#dumbbell" />
                                </svg>
                            </div>
                            <div className="menu-item__label">Workout</div>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink exact activeClassName="is-active" to="/nutrition" className="menu-item" onClick={() => setNavOpen(false)}>
                            <div className="menu-item__icon">
                                <svg>
                                    <use xlinkHref="/assets/icon-library.svg#nutrition" />
                                </svg>
                            </div>
                            <div className="menu-item__label">Nutrition</div>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink exact activeClassName="is-active" to="/progress" className="menu-item" onClick={() => setNavOpen(false)}>
                            <div className="menu-item__icon">
                                <svg>
                                    <use xlinkHref="/assets/icon-library.svg#camera" />
                                </svg>
                            </div>
                            <div className="menu-item__label">Progress</div>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink exact activeClassName="is-active" to="/recipes" className="menu-item" onClick={() => setNavOpen(false)}>
                            <div className="menu-item__icon">
                                <svg>
                                    <use xlinkHref="/assets/icon-library.svg#nutrition" />
                                </svg>
                            </div>
                            <div className="menu-item__label">Recipes</div>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink exact activeClassName="is-active" to="/logbook" className="menu-item" onClick={() => setNavOpen(false)}>
                            <div className="menu-item__icon">
                                <svg>
                                    <use xlinkHref="/assets/icon-library.svg#logbook" />
                                </svg>
                            </div>
                            <div className="menu-item__label">Log Book</div>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink exact activeClassName="is-active" to="/knowledge" className="menu-item" onClick={() => setNavOpen(false)}>
                            <div className="menu-item__icon">
                                <svg>
                                    <use xlinkHref="/assets/icon-library.svg#knowledge" />
                                </svg>
                            </div>
                            <div className="menu-item__label">Blog</div>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink exact activeClassName="is-active" to="/profile" className="menu-item" onClick={() => setNavOpen(false)}>
                            <div className="menu-item__icon">
                                <svg>
                                    <use xlinkHref="/assets/icon-library.svg#account" />
                                </svg>
                            </div>
                            <div className="menu-item__label">Account</div>
                        </NavLink>
                    </li>
                    <button className="menu-item menu-item--end" onClick={() => {globalContext.logOut && globalContext.logOut()}}>
                        <div className="menu-item__icon">
                            <svg>
                                <use xlinkHref="/assets/icon-library.svg#logout" />
                            </svg>
                        </div>
                        <div className="menu-item__label">Logout</div>
                    </button>
                    
                </ul>
            </nav>
            <main className={`site-main ${navOpen ? 'is-open': ''}`} {...swipeHandler}>
                {children}
            </main>
        </div>
    );
}

export default MainLayout;