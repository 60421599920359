import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../../Components/Header/Header';

const NotFound = () => {
    return (
        <>
            <Header title="Oops - Page Not Found" />
            <div className="home-container">
                <Link to="/" className="btn btn--block">Back to home</Link>
            </div>
        </>
    );
}

export default NotFound;