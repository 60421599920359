import React from 'react';

interface INutrition {
    _id?: string,
    uid?: string,
    date?: Date,
    kcal?: number,
    protein?: number,
    carbohydrates?: number,
    fats?: number
}

interface ICheckInAdjustmentProps {
    open?: boolean,
    nutritionData: INutrition | undefined,
    close: Function
}

const CheckInAdjustment = ({ open, nutritionData, close }: ICheckInAdjustmentProps) => {
    return (
        <>
            <div className={`overlay ${open ? 'is-active' : ''}`}></div>
            <div className={`modal ${open ? 'is-open' : ''}`}>
                {nutritionData &&
                    <>
                    <h2>Nutrition Adjustment</h2>
                    <p>To keep you on track towards your fitness goals, we have made some adjustments to your nutrition. You can view your new nutrition targets below (and within your nutrition tab).</p>
                    <h2>Daily Target</h2>
                    <div className="macro-breakdown mb-1">
                        <div className="macro-breakdown__kcal">
                            <p>{nutritionData.kcal} <span>kcals</span></p>
                        </div>
                        <div className="macro-breakdown__pcf">
                            <div className="macro-breakdown__item protein">
                                <p>{nutritionData.protein}g <span>Protein</span></p>
                            </div>
                            <div className="macro-breakdown__item carbs">
                                <p>{nutritionData.carbohydrates}g <span>Carbs</span></p>
                            </div>
                            <div className="macro-breakdown__item fats">
                                <p>{nutritionData.fats}g <span>Fats</span></p>
                            </div>
                        </div>
                    </div>
                    </>
                }
                <button className="btn btn--block btn--green" onClick={() => close()}>Continue</button>
            </div>
        </>
    );
}

export default CheckInAdjustment;