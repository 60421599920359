import React, { useState, useEffect, useCallback } from 'react';
import LoggingRounds from './LoggingRounds';
import LoggingSet from './LoggingSet';
import LoggingSuperset from './LoggingSuperset';

interface ILoggingExerciseProps {
    currentStep: number,
    workout: any,
    exercises: any,
    logGhost: any,
    sound: boolean,
    showControls: Function
}

interface IExercise {
    _id: string,
    name: string,
    category?: string[],
    description?: string,
    video?: string,
    image?: string,
    cardioOnly: boolean
}

interface IVideoCarousel {
    current: number,
    list: string[]
}

const LoggingExercise = ({ currentStep, workout, exercises, logGhost, sound, showControls }: ILoggingExerciseProps) => {
    const [data, setData] = useState<IExercise | undefined>(undefined);
    const [ghost, setGhost] = useState<any>(undefined);
    const [videoCarousel, setVideoCarousel] = useState<IVideoCarousel | undefined>(undefined);
    const [superset, setSuperset] = useState<boolean>(false);
    const alphabet: string[] = ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z"];

    useEffect(() => {
        let exerciseData = exercises.filter((exercise: any) => exercise._id === workout[currentStep - 1].eid)[0];

        if(workout[currentStep - 1].superset) {
            let list: string[] = [exerciseData.video];

            setSuperset(true);

            workout[currentStep - 1].supersetExercises.forEach((ssExercise: any) => {
                let exerciseData = exercises.filter((exercise: any) => exercise._id === ssExercise.eid)[0];
                list.push(exerciseData.video)
            });

            setVideoCarousel({current: 0, list: list});
        } else {
            setSuperset(false);
            setVideoCarousel(undefined);
        }

        setData(exerciseData);
    }, [currentStep]);

    useEffect(() => {
        let ghostData = undefined;

        if(logGhost) {
            console.log(logGhost);
            ghostData = logGhost.exercises[currentStep - 1];
        }

        setGhost(ghostData);
    }, [logGhost, currentStep]);

    return (
        <>
        {data &&
        <>
            {superset && workout[currentStep - 1].supersetExercises.length > 0 ?
                <LoggingRounds data={workout[currentStep - 1]} exercises={exercises} showControls={showControls} />
            :
            <div className="home-container">
            <div className="workout-exercise">
            {videoCarousel ? 
                <>
                    <div className="workout-exercise__video">
                        <iframe src={`https://player.vimeo.com/video/${videoCarousel.list[videoCarousel.current]}?muted=${sound ? 0 : 1}`} frameBorder="0" allow="autoplay; fullscreen"></iframe>
                        <div className="workout-exercise__video-controls">
                            {videoCarousel.current > 0 &&
                                <button className="icon-btn" onClick={() => setVideoCarousel({...videoCarousel, current: videoCarousel.current - 1})}>
                                    <svg>
                                        <use xlinkHref="/assets/icon-library.svg#chevron-left" />
                                    </svg>
                                </button>
                            }
                            {videoCarousel.current < (videoCarousel.list.length - 1) &&
                                <button className="icon-btn" onClick={() => setVideoCarousel({...videoCarousel, current: videoCarousel.current + 1})}>
                                    <svg>
                                        <use xlinkHref="/assets/icon-library.svg#chevron-right" />
                                    </svg>
                                </button>
                            }
                        </div>
                    </div>
                </>
            :
                <>
                {data.video &&
                    <div className="workout-exercise__video">
                        <iframe src={`https://player.vimeo.com/video/${data.video}?muted=${sound ? 0 : 1}`} frameBorder="0" allow="autoplay; fullscreen"></iframe>
                    </div>
                }
                </>
            }
            {/* {workout[currentStep - 1].superset && 
                <div className="workout-label">{workout[currentStep - 1].supersetExercises.length > 1 ? 'Giant Set' : 'Superset'}</div>
            }
            {workout[currentStep - 1].superset ?
                <div className="workout-exercise__title">
                    <p><b>A.</b> {data.name}</p>
                    {workout[currentStep - 1].supersetExercises.map((exercise: any, index: any) => {
                        let eData = exercises.filter((e: any) => e._id === exercise.eid)[0];

                        return <p key={index}><b>{alphabet[index + 1].toUpperCase()}.</b> {eData.name}</p>
                    })}
                </div>
            :
                <div className="workout-exercise__title">{data.name}</div>
            } */}
            <div className="workout-exercise__title">{data.name}</div>
            {workout[currentStep - 1].sets.map((set: any, index: any) =>
                <>
                    {/* {workout[currentStep - 1].superset && <div className="superset-header">{workout[currentStep - 1].supersetExercises.length > 1 ? `Giant Set ${index + 1}` : `Superset ${index + 1}`}</div>} */}
                    <LoggingSet setIndex={index} setData={set} ghostData={ghost ? ghost.sets[index] : null} exerciseIndex={currentStep} superset={workout[currentStep - 1].superset} key={index} />
                    {/* {workout[currentStep - 1].superset && workout[currentStep - 1].supersetExercises.map((ssExercise: any, ssi: any) => 
                        <LoggingSuperset setIndex={index} ssIndex={ssi} exerciseId={workout[currentStep - 1].id} exerciseIndex={currentStep} setData={ssExercise.sets[index]} ghostData={ghost && ghost.supersetExercises[ssi].sets[index]} key={ssi} />
                    )} */}
                </>
            )}
        </div>
        </div>
            }
        </>
        }
        </>
    );
}

export default LoggingExercise;