import React, { useContext, useState, FormEvent } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { toast } from 'react-toastify';
import Header from '../../Components/Header/Header';

// Contexts
import { GlobalContext } from '../../Contexts/Global/GlobalContext';

interface IGlobalContext {
    setLoading?: Function,
    setUser?: Function,
    setInitialAppData?: Function
}

interface IUpgradeProps {
    history: any,
    newUser?: boolean
}

interface ICouponData {
    cid?: string,
    name?: string,
    percentage?: number,
    error?: string,
    type?: string,
    duration?: number
}

const Upgrade = ({ newUser = false, history }: IUpgradeProps) => {
    const price = 12.99;
    const globalContext = useContext<IGlobalContext>(GlobalContext);
    const [coupon, setCoupon] = useState<string>("");
    const [couponData, setCouponData] = useState<ICouponData | null>(null);
    const [couponApply, setCouponApply] = useState<string | null>(null);
    const globalVariables: any = window;
    const stripe = useStripe();
    const elements = useElements();

    const checkCouponCode: Function = async () => {
        globalContext.setLoading && globalContext.setLoading(true);

        await fetch(`${globalVariables.api}/coupon/${coupon}`, {
            method: 'GET',
            credentials: 'include',
            mode: 'cors',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            })
        })
        .then(async (res) => {
            let data = await res.json();
            globalContext.setLoading && globalContext.setLoading(false);
            setCouponApply(null);
            if(res.status === 200) {
                setCouponData(data);
            } else if(res.status === 422) {
                setCouponData({ error: data.error });
            } else {
                setCouponData({ error: "Invalid coupon code." });
            }
        })
        .catch(err => {
            globalContext.setLoading && globalContext.setLoading(false);
            setCouponData({ error: "Invalid coupon code." });
        });
    }

    const handleSubmit: Function = async (e: FormEvent) => {
        e.preventDefault();
        if(coupon !== "" && !couponData) {
            setCouponApply("Make sure to apply your code or delete if you no longer wish to use.");
        } else {
            globalContext.setLoading && globalContext.setLoading(true);

            if (!stripe || !elements) {
                // Stripe.js has not loaded yet. Make sure to disable
                // form submission until Stripe.js has loaded.
                globalContext.setLoading && globalContext.setLoading(false);
                toast.error("There was an error loading this action. Please refresh the page and try again.");
                return;
            }
    
            const cardElement = elements.getElement(CardElement);
    
            if(cardElement) {
                const result = await stripe.createPaymentMethod({
                    type: 'card',
                    card: cardElement
                });
    
                stripePaymentMethodHandler(result);
            } else {
                globalContext.setLoading && globalContext.setLoading(false);
                toast.error("There was an issue loading this action. Please refresh the page and try again.");
            }
        }
    }

    const stripePaymentMethodHandler: Function = async (result: any) => {
        if(result.error) {
            console.log(result.error);
            globalContext.setLoading && globalContext.setLoading(false);
            toast.error("There was an issue loading this action. Please refresh the page and try again.");
        } else {
            // Check if coupon exists and what type it is

            await fetch(`${globalVariables.api}/user/${newUser ? 'upgrade' : 'upgrade'}`, {
                method: 'POST',
                credentials: 'include',
                mode: 'cors',
                headers: new Headers({
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }),
                body: JSON.stringify({ payment_method: result.paymentMethod.id, coupon: couponData ? couponData : null })
            })
            .then(async (res) => {
                if(res.status === 200) {
                    let data = await res.json();
                    globalContext.setUser && globalContext.setUser(data);
                    
                    setTimeout(() => {
                        globalContext.setLoading && globalContext.setLoading(false);
                        if(newUser) {
                            globalContext.setInitialAppData && globalContext.setInitialAppData();
                        }
                        toast.success("Your are now a premium member. You now have access to all premium features!");
                        history.push("/");
                    }, 1500);                    
                } else if (res.status === 500) {
                    globalContext.setLoading && globalContext.setLoading(false);
                    toast.error("There was an issue loading this action. Please refresh the page and try again.");
                    console.log(res);
                    // setError("Server error. Please try again.");
                    // globalContext.setLoading && globalContext.setLoading(false);
                } else {
                    globalContext.setLoading && globalContext.setLoading(false);
                    toast.error("There was an issue loading this action. Please refresh the page and try again.");
                    console.log(res);
                    // setError("Email address or password do not match.");
                    // globalContext.setLoading && globalContext.setLoading(false);
                }
            })
            .catch(err => {
                globalContext.setLoading && globalContext.setLoading(false);
                toast.error("There was an issue loading this action. Please refresh the page and try again.");
                console.log(err);
                // setError("Server error. Please try again.");
                // globalContext.setLoading && globalContext.setLoading(false);
            });
        }
    }

    return (
        <>
            <Header title={newUser ? 'Payment Method' : 'Upgrade'} />
            <div className="home-container">
                {newUser ? 
                <p>Premium members get access to all features and content, including those listed below:</p>
                :
                <p>To access this feature and the many other features below, please upgrade to a premium member:</p>
                }
                <ul className="feature-list">
                    <li><b>All</b> follow along workouts</li>
                    <li><b>All</b> professionally certified plans</li>
                    <li><b>All</b> nutritional recipes</li>
                    <li><b>Smart</b> adaptive personal nutrition</li>
                    <li><b>Progress</b> tracking features</li>
                    <li><b>+</b> much more</li>
                </ul>
                <div className="price">
                    £12.99<span>/month</span>
                </div>
                <div className="internal-container">
                    <div className="form-item">
                        <label>Coupon code</label>
                        <div className="input-addon">
                            <input type="text" name="coupon" placeholder="Enter code here" value={coupon} onChange={(e) => setCoupon(e.target.value)} />
                            {!couponData && <button className="btn" onClick={() => checkCouponCode()}>Apply</button>}
                            {couponData && <button className="btn" onClick={() => { setCouponData(null); setCoupon("");}}>Remove</button>}
                        </div>
                        {couponData && couponData.error && <div className="form-error">{couponData.error}</div>}
                        {couponData && couponData.name && <div className="form-success">{couponData.name} Applied</div>}
                        {couponData && couponData.percentage && <div className="form-success">New price: <b>£{(price * ((100 - couponData.percentage) / 100)).toFixed(2)}</b>/month</div>}
                        {couponApply && coupon !== "" && <div className="form-error">{couponApply}</div>}
                    </div>
                    <form onSubmit={(e) => handleSubmit(e)}>
                    <label>Payment Information</label>
                    <div className="stripe-payment-wrapper">
                        <CardElement
                            options={{
                                hidePostalCode: true,
                                style: {
                                base: {
                                    fontSize: '16px',
                                    color: '#424770',
                                    backgroundColor: '#FFFFFF',
                                    '::placeholder': {
                                    color: '#aab7c4',
                                    },
                                },
                                invalid: {
                                    color: '#9e2146',
                                },
                                },
                            }}
                            />
                    </div>
                        <button className="btn btn--block btn--white" type="submit">{newUser ? 'Go Premium' : 'Upgrade'}</button>
                    </form>
                </div>
            </div>
        </>
    );
}

export default Upgrade;