import React, { useState, useEffect, useCallback } from 'react';
import ReactPlayer from 'react-player';
import Vimeo from '@u-wave/react-vimeo';
import LoggingSet from './LoggingSet';
import LoggingSuperset from './LoggingSuperset';
import LoggingRounds from './LoggingRounds';

interface ILoggingExerciseProps {
    currentStep: number,
    workout: any,
    exercises: any,
    sound: boolean,
    showControls: Function
}

interface IExercise {
    _id: string,
    name: string,
    category?: string[],
    description?: string,
    video?: string,
    image?: string,
    cardioOnly: boolean
}

interface IVideoCarousel {
    current: number,
    list: string[]
}

const LoggingExercise = ({ currentStep, workout, exercises, sound, showControls }: ILoggingExerciseProps) => {
    const [data, setData] = useState<IExercise | undefined>(undefined);
    const [videoCarousel, setVideoCarousel] = useState<IVideoCarousel | undefined>(undefined);
    const [play, setPlay] = useState<boolean>(false);
    const [superset, setSuperset] = useState<boolean>(false);
    const alphabet: string[] = ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z"];

    useEffect(() => {
        let exerciseData = exercises.filter((exercise: any) => exercise._id === workout[currentStep - 1].eid)[0];

        if(workout[currentStep - 1].superset) {
            let list: string[] = [exerciseData.video];

            setSuperset(true);

            workout[currentStep - 1].supersetExercises.forEach((ssExercise: any) => {
                let exerciseData = exercises.filter((exercise: any) => exercise._id === ssExercise.eid)[0];
                list.push(exerciseData.video)
            });

            setVideoCarousel({current: 0, list: list});
        } else {
            setVideoCarousel(undefined);
        }

        setData(exerciseData);
        setPlay(false);
    }, [currentStep]);

    return (
        <>
        {data &&
            <>
            {superset && workout[currentStep - 1].supersetExercises.length > 0 ?
                <LoggingRounds data={workout[currentStep - 1]} exercises={exercises} showControls={showControls} />
            :
            <div className="home-container">
            <div className="workout-exercise">
                {videoCarousel ? 
                    <>
                        <div className="workout-exercise__video">
                            {/* <iframe src={`https://player.vimeo.com/video/${videoCarousel.list[videoCarousel.current]}?muted=${sound ? 0 : 1}`} frameBorder="0" allow="autoplay; fullscreen"></iframe> */}
                            <Vimeo video={videoCarousel.list[videoCarousel.current]} volume={0} muted={true} paused={play === false} loop={true} />
                            <div className="workout-exercise__video-controls">
                                {videoCarousel.current > 0 &&
                                    <button className="icon-btn" onClick={() => {setVideoCarousel({...videoCarousel, current: videoCarousel.current - 1}); setPlay(false);}}>
                                        <svg>
                                            <use xlinkHref="/assets/icon-library.svg#chevron-left" />
                                        </svg>
                                    </button>
                                }
                                {videoCarousel.current < (videoCarousel.list.length - 1) &&
                                    <button className="icon-btn" onClick={() => {setVideoCarousel({...videoCarousel, current: videoCarousel.current + 1}); setPlay(false);}}>
                                        <svg>
                                            <use xlinkHref="/assets/icon-library.svg#chevron-right" />
                                        </svg>
                                    </button>
                                }
                            </div>
                            {/* {play ?
                                    <button className="play-btn play-btn--pause" onClick={() => setPlay(false)}>
                                        <svg>
                                            <use xlinkHref="/assets/icon-library.svg#pause" />
                                        </svg>
                                    </button>
                                :
                                    <button className="play-btn" onClick={() => setPlay(true)}>
                                        <svg>
                                            <use xlinkHref="/assets/icon-library.svg#play" />
                                        </svg>
                                    </button>
                                } */}
                        </div>
                    </>
                :
                    <>
                    {data.video &&
                        <div className="challenge-exercise__video">
                            {/* <iframe src={`https://player.vimeo.com/video/${data.video}?muted=${sound ? 0 : 1}`} frameBorder="0" allow="autoplay; fullscreen"></iframe> */}
                            {/* <iframe src={`https://player.vimeo.com/video/458215597?muted=${sound ? 0 : 1}&autoplay=true`} frameBorder="0" allow="autoplay; fullscreen"></iframe> */}
                            {/* <ReactPlayer url={`https://player.vimeo.com/video/458215597?muted=${sound ? 0 : 1}&autoplay=true`} loop={false} muted={true} playing={false} playsinline={true} /> */}
                            <Vimeo video={data.video} volume={0} muted={true} paused={play === false} loop={true} />
                            {/* {play ?
                                <button className="play-btn play-btn--pause" onClick={() => setPlay(false)}>
                                    <svg>
                                        <use xlinkHref="/assets/icon-library.svg#pause" />
                                    </svg>
                                </button>
                            :
                                <button className="play-btn" onClick={() => setPlay(true)}>
                                    <svg>
                                        <use xlinkHref="/assets/icon-library.svg#play" />
                                    </svg>
                                </button>
                            } */}
                        </div>
                    }
                    </>
                }
                
                <div className="workout-container__inner">
                    {workout[currentStep - 1].superset && 
                        <div className="workout-label">{workout[currentStep - 1].supersetExercises.length > 1 ? 'Giant Set' : 'Superset'}</div>
                    }
                    {workout[currentStep - 1].superset ?
                        <div className="workout-exercise__title">
                            <p><b>A.</b> {data.name}</p>
                            {workout[currentStep - 1].supersetExercises.map((exercise: any, index: any) => {
                                let eData = exercises.filter((e: any) => e._id === exercise.eid)[0];

                                return <p key={index}><b>{alphabet[index + 1].toUpperCase()}.</b> {eData.name}</p>
                            })}
                        </div>
                    :
                        <div className="workout-exercise__title">{data.name}</div>
                    }
                    {workout[currentStep - 1].sets.map((set: any, index: any) =>
                        <>
                            {workout[currentStep - 1].superset && <div className="superset-header">{workout[currentStep - 1].supersetExercises.length > 1 ? `Giant Set ${index + 1}` : `Superset ${index + 1}`}</div>}
                            <LoggingSet setIndex={index} setData={set} exerciseIndex={currentStep} superset={workout[currentStep - 1].superset} key={index} />
                            {workout[currentStep - 1].superset && workout[currentStep - 1].supersetExercises.map((ssExercise: any, ssi: any) => 
                                <LoggingSuperset setIndex={index} ssIndex={ssi} exerciseId={workout[currentStep - 1].id} exerciseIndex={currentStep} setData={ssExercise.sets[index]} key={ssi} />
                            )}
                        </>
                    )}
                </div>
                </div>
            </div>
            }
            </>
        }
        </>
    );
}

export default LoggingExercise;