import React, { useEffect, useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../Components/Header/Header';

interface IRecipe {
    _id: string,
    name: string,
    slug: string,
    ingredients: Array<string>,
    directions: Array<string>,
    kcals: number,
    protein: number,
    carbohydrates: number,
    fats: number,
    time: number,
    prepTime?: number,
    servings?: number,
    image?: string,
    category?: Array<string>
}

const RecipeEditorList = () => {
    const globalVariables: any = window;
    const [recipes, setRecipes] = useState<Array<IRecipe> | null>(null);

    const fetchRecipes = useCallback(async () => {
        await fetch(`${globalVariables.api}/recipe`, {
            method: 'GET',
            credentials: 'include',
            mode: 'cors',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            })
        })
        .then(async (res) => {
            let data = await res.json();

            if(res.status === 200) {
                setRecipes(data);
            } else if (res.status === 401) {
                window.location.href = "/";
            } else {
                alert("Error");
                console.log(res);
            }
        })
        .catch(err => {
            console.log(err);
            alert("error");
        });
    }, []);

    useEffect(() => {
        fetchRecipes();
    }, [fetchRecipes]);

    return (
        <>
            <Header title="Recipes" />
            <div className="home-container">
                <div className="video-library recipe">
                    {recipes && recipes.map((recipe, index) =>
                        <Link key={index} to={`/recipeeditor/edit/${recipe.slug}`} className="video-library-item">
                            <div className="video-library-item__image recipe">
                                <img src={`${globalVariables.api}/media/${recipe.image}`} alt={recipe.name} />
                            </div>
                            <div className="video-library-item__title">
                                {recipe.name}
                            </div>
                        </Link>
                    )}
                </div>
                <br />
                <Link to="/recipeeditor/edit" className="btn">Add +</Link>
            </div>
        </>
    );
}

export default RecipeEditorList;