import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

// Contexts
import { GlobalContext } from '../../Contexts/Global/GlobalContext';
import { toast } from 'react-toastify';
import Schedule from './Schedule';
import GuideCarousel from '../GuideCarousel/GuideCarousel';

interface IGlobalContext {
    state?: any
}

interface IScheduleData {
    week?: number,
    weekCount?: number,
    days?: any[],
    future?: boolean
}

const ScheduleCard = () => {
    const globalContext: IGlobalContext = useContext(GlobalContext);

    return (
        <div className="basic-container basic-container--trans">
            {globalContext.state && globalContext.state.plan ?
                <h2 className="section-title section-title--icon">
                    <svg>
                        <use xlinkHref="/assets/icon-library.svg#list" />
                    </svg> 
                    My Schedule
                </h2>
                :
                <h2 className="section-title section-title--icon">
                    <svg>
                        <use xlinkHref="/assets/icon-library.svg#dumbbell" />
                    </svg>    
                    Workout Plans
                </h2>
            }
            {globalContext.state.user && (globalContext.state.user.type === "premium" || globalContext.state.user.type === "admin") && (globalContext.state && globalContext.state.plan) ? 
                <>
                {globalContext.state.plan.map((plan: any, index: number) => 
                    <Schedule plan={plan} key={index} />
                )}
                </>
                :
                <GuideCarousel />
            }
        </div>
    );
}

export default ScheduleCard;