import React, { useCallback, useEffect, useState } from 'react';
import LoggingRoundsExercise from './LoggingRoundsExercise';

const LoggingRounds = ({ data, exercises, showControls }: any) => {
    const [rounds, setRounds] = useState<number>(0);
    const [currentRound, setCurrentRound] = useState<number>(0);
    const [firstExercise, setFirstExercise] = useState<any>(undefined);

    const initialiseRounds = useCallback(() => {
        if(data) {
            setRounds(data.sets.length);
            setCurrentRound(1);
            showControls(false);

            let exerciseData = exercises.filter((e: any) => e._id === data.eid)[0];
            setFirstExercise(exerciseData);
        }
    }, [data]);

    useEffect(() => {
        initialiseRounds();
    }, []);

    useEffect(() => {
        if(currentRound > 0) {
            if(currentRound === rounds) {
                showControls(true);
            }
        }
    }, [currentRound]);

    return (
        <>
        {data &&
            <div id="roundTop">
            {currentRound > 0 &&
                <>
                    <div className="round-header">
                        <div className="workout-label">{data.supersetExercises.length > 1 ? 'Giant Set' : 'Superset'}</div>
                        <div className="round-header__number">Round <div className="round-header__current">{currentRound}</div> of {rounds}</div>
                    </div>

                    {firstExercise &&
                        <LoggingRoundsExercise exerciseInstructions={data} exerciseData={firstExercise} nextExercise={data.supersetExercises[0].id} exerciseId={data.id} round={currentRound} />
                    }
                    {data.supersetExercises.map((ssExercise: any, index: any) => {
                        var prevExercise,nextExercise,exerciseId;
                        var exerciseData = exercises.filter((e: any) => e._id === ssExercise.eid)[0];

                        if(index === 0) {
                            prevExercise = data.id;
                            nextExercise = data.supersetExercises[index + 1] !== undefined ? data.supersetExercises[index + 1].id : null;
                            exerciseId = ssExercise.id;
                        } else {
                            prevExercise = data.supersetExercises[index - 1].id;
                            nextExercise = data.supersetExercises[index + 1] !== undefined ? data.supersetExercises[index + 1].id : null;
                            exerciseId = ssExercise.id;
                        }

                        return <LoggingRoundsExercise exerciseInstructions={ssExercise} exerciseData={exerciseData} prevExercise={prevExercise} nextExercise={nextExercise} exerciseId={exerciseId} round={currentRound} key={index} />
                    })}
                    {currentRound < rounds &&
                        <a href="#roundTop" className="btn mb-2" onClick={() => setCurrentRound(currentRound + 1)}>Next Round</a>
                    }
                </>
            }
            </div>
        }
        </>
    );
}

export default LoggingRounds;