import React, { useEffect, useCallback, useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import LoggingExercise from './LoggingExercise';
import LoggingControl from './LoggingControl';

// Contexts
import { ChallengeContext } from '../../Contexts/ChallengeContext/ChallengeContext';

// Interfaces

interface IChallengeContext {
    state?: any,
    initialiseWorkout?: Function,
    setExerciseIndex?: Function,
    completeWorkout?: Function,
    resetLogging?: Function,
    setDay?: Function
}

interface ILoggingProps {
    history: any
}

const ChallengeLogging = ({ history }: ILoggingProps) => {
    const globalVariables: any = window;
    const challengeContext: IChallengeContext = useContext(ChallengeContext);
    const [showControls, setShowControls] = useState<boolean>(true);

    const { dayid } = useParams();

    // const initialiseWorkout = useCallback(async (planid, week, dayid) => {
    //     if(!challengeContext.state.workout) {
    //         challengeContext.initialiseWorkout && challengeContext.initialiseWorkout(planid, week, dayid);
    //     }
    // }, []);

    // useEffect(() => {
    //     initialiseWorkout(planid, week, dayid);
    // }, []);

    const fetchDay = useCallback(async () => {
        await fetch(`${globalVariables.api}/workout/challenge/day/${dayid}`, {
            method: 'GET',
            credentials: 'include',
            mode: 'cors',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            })
        })
        .then(async (res) => {
            let data = await res.json();

            if(res.status === 200) {
                challengeContext.setDay && challengeContext.setDay(data);
            } 
        })
        .catch(err => {
            console.log(err);
        });
    }, []);

    useEffect(() => {
        if(!challengeContext.state.day) {
            fetchDay();
        }
    }, []);
    
    return (
        <>
            <div className="workout-container workout-container--portrait">
                {challengeContext.state && challengeContext.state.day && challengeContext.state.exercises &&
                <>
                    <div className="workout-header">
                        <div className="workout-header__inner">
                            <h1>{challengeContext.state.day.name}</h1>
                            {challengeContext.state.timer && 
                                <div className="workout-header__timer">{challengeContext.state.timer}</div>
                            }
                            <div className="workout-header__indicator">
                                {challengeContext.state.day.data.map((exercise: any, index: any) =>
                                    <span className={index + 1 <= challengeContext.state.exerciseIndex ? 'is-solid' : ''} key={index}></span>
                                )}
                            </div>
                        </div>
                    </div>
                    <LoggingExercise currentStep={challengeContext.state.exerciseIndex}
                                     workout={challengeContext.state.day.data}
                                     exercises={challengeContext.state.exercises}
                                     sound={challengeContext.state.sound = false}
                                     showControls={(value: boolean) => setShowControls(value)} />
                    { showControls && <LoggingControl currentStep={challengeContext.state.exerciseIndex} 
                                    stepCount={challengeContext.state.day.data.length}
                                    setStep={(step: number) => challengeContext.setExerciseIndex && challengeContext.setExerciseIndex(step)}
                                    completeWorkout={() => challengeContext.completeWorkout && challengeContext.completeWorkout()}
                                    resetLogging={() => challengeContext.resetLogging && challengeContext.resetLogging()}
                                    history={history} />}
                </>
                }
            </div>
        </>
    );
}

export default ChallengeLogging;