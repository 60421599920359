import React, { useContext, useState } from 'react';
import moment from 'moment';
import Header from '../../Components/Header/Header';

// Contexts
import { GlobalContext } from '../../Contexts/Global/GlobalContext';
import { toast } from 'react-toastify';
import PaymentMethod from '../../Components/PaymentMethod/PaymentMethod';

interface IGlobalContext {
    state?: any,
    setLoading?: Function,
    setUser?: Function
}

interface IUser {
    _id: string,
    firstname: string,
    lastname: string,
    email: string,
    status: string,
    type: string,
    marketing: boolean,
    [x:string]: any
}

const Profile = () => {
    const globalVariables: any = window;
    const globalContext: IGlobalContext = useContext(GlobalContext);
    const [popUp, setPopUp] = useState<boolean>(false);
    const [user, setUser] = useState<any | undefined>(globalContext.state && globalContext.state.user);

    const cancelPremium = async () => {
        globalContext.setLoading && globalContext.setLoading(true);

        await fetch(`${globalVariables.api}/user/cancel`, {
            method: 'GET',
            credentials: 'include',
            mode: 'cors'
        })
        .then(async (res) => {
            let data = await res.json();

            if(res.status === 200) {
                globalContext.setUser && globalContext.setUser({...globalContext.state.user, status: data.status});
            } else if (res.status === 401) {
                window.location.href = "/";
            } else {
                toast.error("There was a problem cancelling your premium subscription. Please try again.");
            }

            globalContext.setLoading && globalContext.setLoading(false);
        })
        .catch(err => {
            globalContext.setLoading && globalContext.setLoading(false);
            toast.error("There was a problem cancelling your premium subscription. Please try again.");
        });
    }

    const handleUserDetailChange = (e: any) => {
        console.log(e.target.type);
        if(e.target.type === "checkbox") {
            setUser({...user, [e.target.name]: !user[e.target.name]});
        } else {
            setUser({...user, [e.target.name]: e.target.value});
        }   
    }

    const updateUser = async () => {
        globalContext.setLoading && globalContext.setLoading(true);

        await fetch(`${globalVariables.api}/user`, {
            method: 'PUT',
            credentials: 'include',
            mode: 'cors',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }),
            body: JSON.stringify({...user})
        })
        .then(res => {
            globalContext.setLoading && globalContext.setLoading(false);
            if(res.status === 200) {
                globalContext.setUser && globalContext.setUser({...user});
                return res.json();
            } else if (res.status === 401) {
                window.location.href = "/";
            } else {
                toast.error("There was a problem updating your details. Please try again.");
                console.log(res);
            }
        })
        .then(res => {
            toast.success("Profile details updated");
        })
        .catch(err => {
            toast.error("There was a problem updating your details. Please try again.");
            console.log(err);
        });
    }

    return (
        <>
            <Header title="My Profile" />
            <div className="home-container">
                {globalContext.state && globalContext.state.user && user &&
                    <>
                        <h2 className="mb-1">My Details</h2>
                        <div className="form-item">
                            <label>First name</label>
                            <input type="text" name="firstname" value={user.firstname} onChange={(e) => handleUserDetailChange(e)} />
                        </div>
                        <div className="form-item">
                            <label>Last name</label>
                            <input type="text" name="lastname" value={user.lastname} onChange={(e) => handleUserDetailChange(e)} />
                        </div>
                        <div className="form-item">
                            <label>Email</label>
                            <input type="email" name="email" value={user.email} onChange={(e) => handleUserDetailChange(e)} />
                        </div>
                        <div className="form-item">
                            <label>
                                <input type="checkbox" name="marketing" checked={user.marketing} onChange={(e) => handleUserDetailChange(e)} />
                                <span>I would like to receive emails from Stronger You with information and offers!</span>
                            </label>
                        </div>
                        <button className="btn btn--green mb-1" onClick={() => updateUser()}>Update</button>
                        {globalContext.state.user.type === "premium" && globalContext.state.user !== 3 && !globalContext.state.user.trial &&
                            <>
                                <hr className="mb-1" />
                                <PaymentMethod />
                                <hr className="mb-1" />
                            </>
                        }
                        {globalContext.state.user.type === "premium" &&
                            <>
                            {globalContext.state.user.status === 3 &&
                                <>
                                    <h2 className="mb-1">Premium Subscription</h2>
                                    <p>Your premium subscription has been cancelled. You will lose access to premium features at the end of your billing period.</p>
                                </>
                            }
                            {globalContext.state.user.status !== 3 && !globalContext.state.user.trial &&
                                <>
                                    <h2 className="mb-1">Cancel Account</h2>
                                    <p>When you cancel your account, you will have access to the premium features until the end of your billing period - at which point you will not be billed and premium subscription will end.</p>
                                    <button className="btn" onClick={() => setPopUp(true)}>Cancel Premium</button>

                                    <div className={`overlay ${popUp ? 'is-active' : ''}`}></div>
                                    <div className={`modal ${popUp ? 'is-open' : ''}`}>
                                        <h2>Are you sure you want to cancel your premium account?</h2>
                                        <button className="btn mr-1" onClick={() => cancelPremium()}>Yes</button>
                                        <button className="btn" onClick={() => setPopUp(false)}>No</button>
                                    </div>
                                </>
                            }
                            {globalContext.state.user.trial &&
                                <div className="internal-container">
                                    <h2 className="mb-1">Stronger You Premium - Free Trial</h2>
                                    <p>You will be put on the free plan once the trial period has ended.</p>
                                    {globalContext.state.user.trialEnd &&
                                        <p>Trial ends: <b>{moment(globalContext.state.user.trialEnd).format("Do MMMM YYYY")}</b></p>
                                    }
                                </div>
                            }
                            </>
                        }
                    </>
                }
            </div>
        </>
    );
}

export default Profile;