import React, { createContext, useState, useEffect, useCallback, useContext } from 'react';
import { GlobalContext } from '../Global/GlobalContext';
import { toast } from 'react-toastify';

type LoggingProviderProps = {
    children?: any
}

interface IGlobalContext {
    state?: any,
    setLoading?: Function
}

interface IWorkout {
    id: string,
    exercises: any
}

interface IExercise {
    _id: string,
    name: string,
    category?: string[],
    description?: string,
    video?: string,
    image?: string,
    cardioOnly: boolean
}

interface ILog {
    pid?: string,
    wid: string,
    data: any
}

interface ILinkHelp {
    planid?: string,
    week?: number,
    dayid?: string
}

const LoggingContext = createContext({});

const LoggingProvider = ({ children }: LoggingProviderProps) => {
    const globalVariables: any = window;
    const globalContext: IGlobalContext = useContext(GlobalContext);

    const [linkHelper, setLinkHelper] = useState<ILinkHelp | null>(null);
    const [singleLinkHelper, setSingleLinkHelper] = useState<any>(null);
    const [workout, setWorkout] = useState<IWorkout | null>(null);
    const [exercises, setExercises] = useState<IExercise[] | null>(null);
    const [timer, setTimer] = useState<string | null>(null);
    const [exerciseIndex, setExerciseIndex] = useState<number>(1);
    const [log, setLog] = useState<ILog | any>(null);
    const [logGhost, setLogGhost] = useState<ILog | any>(null);
    const [timeLoop, setTimeLoop] = useState<any>(undefined);
    const [sound, setSound] = useState<boolean>(false);

    const fetchExercises = useCallback(async () => {
        await fetch(`${globalVariables.api}/workout/exercise/all`, {
            method: 'GET',
            credentials: 'include',
            mode: 'cors',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            })
        })
        .then(async (res) => {
            let data = await res.json();

            if(res.status === 200) {
                setExercises(data);
            } else {
                return;
            }
        })
        .catch(err => {
            return;
        });
    }, []);

    const initialiseWorkout = (planid: any, week: any, dayid: any) => {
        setLinkHelper({planid, week, dayid});
        let workoutNo = undefined;
        let planData = globalContext.state.plan.filter((plan: any) => plan._id === planid)[0],
            weekData = planData.data[parseInt(week) - 1],
            dayData = weekData.days.filter((day: any, index: any) => {
                if(day.id === dayid) {
                    workoutNo = index + 1;
                    return day;
                }
            })[0];

        if(!dayData.name) {
            dayData.name = `Workout ${workoutNo}`;
        }

        let logObj = {
            pid: planData._id,
            wid: weekData.id,
            ...dayData
        };
         
        setWorkout(dayData);
        setLog({...logObj});
        setSound(planData.sound ? true : false);
        initialiseTimer();
        initialiseGhost(dayid);
    }

    const initialiseSingleWorkout = async (workoutslug: any) => {
        setSingleLinkHelper(workoutslug);
        let workoutNo = undefined;
        await fetch(`${globalVariables.api}/workout/detail/${workoutslug}`, {
            method: 'GET',
            credentials: 'include',
            mode: 'cors',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            })
        })
        .then(res => res.json())
        .then(res => {
            // console.log(res);
            res.data[0].title = res.title;
            setWorkout(res.data);
                setLog({wid: res._id, exercises: [...res.data]});
                setSound(res.sound ? true : false);
                initialiseTimer();
                // initialiseGhost(dayid);
        })
        .catch(err => {
            console.log(err);
            globalContext.setLoading && globalContext.setLoading(false);
            toast.error("There has been a server error. Please try again.");
            return false;
        });
    }

    const initialiseGhost = async (id: string) => {
        // To do: Log Ghost
        // API call to get last ~20 logs, look through in reverse order for workout matching current workout
        // If log found that matches, set as ghost log - use this data to display as placeholder for the inputs
        // to allow user to get a quick glimpse of their last inputs

        await fetch(`${globalVariables.api}/log/limit/20`, {
            method: 'GET',
            credentials: 'include',
            mode: 'cors',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            })
        })
        .then(async res => {
            let logs = await res.json();
            if(res.status === 200) {
                let ghost: any = null;

                for(let i = 0; i < logs.length; i++) {
                    if(logs[i].id === id) {
                        console.log(logs[i]);
                        ghost = logs[i];
                        i = 100;
                    }
                }

                setLogGhost(ghost);
                globalContext.setLoading && globalContext.setLoading(false);
                // toast.success("Workout complete - well done!");
                return true;
            } else if (res.status === 500) {
                globalContext.setLoading && globalContext.setLoading(false);
                toast.error("There has been a server error. Please try again.");
                return false;
            } else {
                globalContext.setLoading && globalContext.setLoading(false);
                toast.error("There has been a server error. Please try again.");
                return false;
            }
        })
        .catch(err => {
            console.log(err);
            globalContext.setLoading && globalContext.setLoading(false);
            toast.error("There has been a server error. Please try again.");
            return false;
        });
    }

    const initialiseTimer = () => {
        var startTime = Math.floor(new Date().getTime() / 1000); //Get the starting time (right now) in seconds

        function startTimeCounter() {
            var now = Math.floor(Date.now() / 1000); // get the time now
            var diff = (now - startTime); // diff in seconds between now and start
            var h: any = 0;
            var m: any = Math.floor(diff / 60); // get minutes value (quotient of diff)
            if(m >= 60) {
                h = Math.floor((m / 60));
                m = parseInt(m) - (parseInt(h) * 60);
            }
            var s = Math.floor(diff % 60); // get seconds value (remainder of diff)
            h = checkTime(h);
            m = checkTime(m); // add a leading zero if it's single digit
            s = checkTime(s); // add a leading zero if it's single digit
            setTimer(`${h}:${m}:${s}`);
            // setTimeout(startTimeCounter, 500); // set a timeout to update the timer

        }

        function checkTime(i: any) {
            if (i < 10) {i = "0" + i};  // add zero in front of numbers < 10
            return i;
        }

        let timeLoop = setInterval(() => startTimeCounter(), 500);
        setTimeLoop(timeLoop);
    }

    const updateLogData = (e: any, setIndex: number, superset: string | undefined = undefined, dropset = false) => {
        let logData = {...log};

        if(superset) {
            logData.exercises[exerciseIndex - 1].supersetExercises.forEach((ssExercise: any) => {
                ssExercise.sets[setIndex].log = ssExercise.sets[setIndex].log ? {...ssExercise.sets[setIndex].log} : {};
                ssExercise.sets[setIndex].log = {
                    ...ssExercise.sets[setIndex].log,
                    [e.target.name]: e.target.value
                }
            });
        } else {
            logData.exercises[exerciseIndex - 1].sets[setIndex].log = {
                ...logData.exercises[exerciseIndex - 1].sets[setIndex].log,
                [e.target.name]: e.target.value
            }
        }

        setLog(logData);
    }

    const resetLogging = async () => {
        window.clearInterval(timeLoop);
        setLinkHelper(null);
        setSingleLinkHelper(null);
        setWorkout(null);
        setExerciseIndex(1);
        setLog(null);
        setTimer(null);
    }
    
    const completeWorkout = async () => {
        globalContext.setLoading && globalContext.setLoading(true);

        const logObj = {
            ...log,
            date: new Date(),
            duration: timer
        };

        let result = await fetch(`${globalVariables.api}/log`, {
            method: 'POST',
            credentials: 'include',
            mode: 'cors',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }),
            body: JSON.stringify(logObj)
        })
        .then(res => {
            if(res.status === 200) {
                // resetLogging();
                globalContext.setLoading && globalContext.setLoading(false);
                // toast.success("Workout complete - well done!");
                return true;
            } else if (res.status === 500) {
                globalContext.setLoading && globalContext.setLoading(false);
                toast.error("There has been a server error. Please try again.");
                return false;
            } else {
                globalContext.setLoading && globalContext.setLoading(false);
                toast.error("There has been a server error. Please try again.");
                return false;
            }
        })
        .catch(err => {
            globalContext.setLoading && globalContext.setLoading(false);
            toast.error("There has been a server error. Please try again.");
            return false;
        });

        return result;

    }

    useEffect(() => {
        if(globalContext.state.isLoggedIn) {
            fetchExercises();
        }
    }, [fetchExercises, globalContext.state.isLoggedIn]);

    return (
        <LoggingContext.Provider value={{
            state: {
                exercises,
                workout,
                timer,
                exerciseIndex,
                log,
                logGhost,
                linkHelper,
                singleLinkHelper,
                sound
            },
            initialiseWorkout,
            initialiseSingleWorkout,
            setExerciseIndex,
            updateLogData,
            completeWorkout,
            resetLogging
            }}>
              {children}
        </LoggingContext.Provider>
    );
}

const LoggingConsumer = LoggingContext.Consumer;

export { LoggingContext, LoggingProvider, LoggingConsumer }