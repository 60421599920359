import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import "react-datepicker/dist/react-datepicker.css";

// Contexts
import { GlobalProvider } from './Contexts/Global/GlobalContext';

// Containers
import ScrollToTop from './Containers/ScrollToTop/ScrollToTop';
import AuthRouter from './Containers/AuthRouter/AuthRouter';
import { LoggingProvider } from './Contexts/LoggingContext/LoggingContext';
import GoogleAnalytics from './Components/GoogleAnalytics/GoogleAnalytics';
import { ChallengeProvider } from './Contexts/ChallengeContext/ChallengeContext';

// Load Stripe
// const stripePromise = loadStripe('pk_test_wZLYPSrYJlnUlWNAqw1eCc4p');
const stripePromise = loadStripe('pk_live_3S4Gkla2ZSthnLgW02Wj7p5Z');

function App() {
  return (
    <Router>
      <ScrollToTop>
        <Elements stripe={stripePromise}>
          <GlobalProvider>
            <LoggingProvider>
              <ChallengeProvider>
                <AuthRouter />
              </ChallengeProvider>
            </LoggingProvider>
          </GlobalProvider>
        </Elements>
      </ScrollToTop>
      <ToastContainer />
      <GoogleAnalytics />
    </Router>
  );
}

export default App;
