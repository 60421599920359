import React, { useContext } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { toast } from 'react-toastify';

// Contexts
import { GlobalContext } from '../../Contexts/Global/GlobalContext';

interface IPaymentMethodProps {
    customTitle?: string,
    newUser?: boolean,
    history?: any
}

interface IGlobalContext {
    setLoading?: Function,
    setUser?: Function,
    setInitialAppData?: Function
}

const PaymentMethod = ({ customTitle, newUser = false, history }: IPaymentMethodProps) => {
    const globalContext = useContext<IGlobalContext>(GlobalContext);
    const globalVariables: any = window;
    const stripe = useStripe();
    const elements = useElements();

    const handleSubmit = async (e: any) => {
        e.preventDefault();

        globalContext.setLoading && globalContext.setLoading(true);

            if (!stripe || !elements) {
                // Stripe.js has not loaded yet. Make sure to disable
                // form submission until Stripe.js has loaded.
                globalContext.setLoading && globalContext.setLoading(false);
                toast.error("There was an error loading this action. Please refresh the page and try again.");
                return;
            }
    
            const cardElement = elements.getElement(CardElement);
    
            if(cardElement) {
                const result = await stripe.createToken(cardElement);

                stripePaymentMethodHandler(result);
            } else {
                globalContext.setLoading && globalContext.setLoading(false);
                toast.error("There was an issue loading this action. Please refresh the page and try again.");
            }
    }

    const stripePaymentMethodHandler: Function = async (result: any) => {
        if(result.error) {
            console.log(result.error);
            globalContext.setLoading && globalContext.setLoading(false);
            toast.error("There was an issue loading this action. Please refresh the page and try again.");
        } else {
            // Check if coupon exists and what type it is

            await fetch(`${globalVariables.api}/user/${newUser ? 'upgrade' : 'paymentmethod'}`, {
                method: 'POST',
                credentials: 'include',
                mode: 'cors',
                headers: new Headers({
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }),
                body: JSON.stringify({ token: result.token })
            })
            .then(async (res) => {
                if(res.status === 200) {
                    globalContext.setLoading && globalContext.setLoading(false);
                    if(newUser) {
                        globalContext.setInitialAppData && globalContext.setInitialAppData();
                        history.push("/");
                    } else {
                        toast.success("Your payment method has been updated."); 
                    }                  
                } else if (res.status === 500) {
                    globalContext.setLoading && globalContext.setLoading(false);
                    toast.error("There was an issue loading this action. Please refresh the page and try again.");
                    console.log(res);
                    // setError("Server error. Please try again.");
                    // globalContext.setLoading && globalContext.setLoading(false);
                } else {
                    globalContext.setLoading && globalContext.setLoading(false);
                    toast.error("There was an issue loading this action. Please refresh the page and try again.");
                    console.log(res);
                    // setError("Email address or password do not match.");
                    // globalContext.setLoading && globalContext.setLoading(false);
                }
            })
            .catch(err => {
                globalContext.setLoading && globalContext.setLoading(false);
                toast.error("There was an issue loading this action. Please refresh the page and try again.");
                console.log(err);
                // setError("Server error. Please try again.");
                // globalContext.setLoading && globalContext.setLoading(false);
            });
        }
    }

    return (
        <>
        <form onSubmit={(e) => handleSubmit(e)}>
            <h2 className="mb-1">{customTitle ? customTitle : "Update Payment Method"}</h2>
            <p>Enter your payment method below - future payments will be taken from this card:</p>
            <div className="stripe-payment-wrapper">
                        <CardElement
                            options={{
                                hidePostalCode: true,
                                style: {
                                base: {
                                    fontSize: '16px',
                                    color: '#424770',
                                    backgroundColor: '#FFFFFF',
                                    '::placeholder': {
                                    color: '#aab7c4',
                                    },
                                },
                                invalid: {
                                    color: '#9e2146',
                                },
                                },
                            }} />
            </div>
            <button className="btn mb-1" type="submit">Submit</button>
        </form>
        </>
    );
}

export default PaymentMethod;