import React, { useContext } from 'react';
import LoggingTimerBar from './LoggingTmerBar';
import LoggingTimerHIIT from './LoggingTimerHIIT';

// Contexts
import { LoggingContext } from '../../Contexts/LoggingContext/LoggingContext';

interface ILoggingSetProps {
    setIndex: number,
    setData: any,
    ghostData: any,
    exerciseIndex: number,
    superset: boolean
}

interface ILoggingContext {
    state?: any,
    updateLogData?: Function
}

const timeFormat = (time: string) => {
    let segments: string[] | number[] = time.split('.');
    
    segments = segments.map(segment => parseInt(segment));
    segments[1] = segments[1] < 10 ? ((segments[1] * 10) / 100) * 60 : (segments[1] / 100) * 60;

    return `${segments[0]}Min ${segments[1]}s`;
}

const LoggingSet = ({ setIndex, setData, ghostData, exerciseIndex, superset }: ILoggingSetProps) => {
    const loggingContext: ILoggingContext = useContext(LoggingContext);

    return (
        <div className="workout-set">
            <div className="workout-set__header">
                Set {setIndex + 1}{superset && 'A'}
            </div>
            {setData.type === "reps" &&
                <>
                <div className="workout-set__target">
                    <div>
                        <label>Target</label>
                        <p>{setData.reps.target} <span>Reps</span></p>
                    </div>
                </div>
                <div className="workout-set__logging">
                    <div>
                        <label>Achieved</label>
                        <div className="input-group">
                            <input type="number" name="reps" 
                                   value={loggingContext.state.log.exercises[exerciseIndex - 1].sets[setIndex].log && loggingContext.state.log.exercises[exerciseIndex - 1].sets[setIndex].log.reps ? loggingContext.state.log.exercises[exerciseIndex - 1].sets[setIndex].log.reps : ''}
                                   placeholder={ghostData && ghostData.log ? ghostData.log.reps : ""}
                                   onChange={(e) => loggingContext.updateLogData && loggingContext.updateLogData(e, setIndex)} />
                            <p>Reps</p>
                        </div>
                    </div>
                    <div>
                        <div className="input-group">
                            <input type="number" name="weight"
                                   value={loggingContext.state.log.exercises[exerciseIndex - 1].sets[setIndex].log && loggingContext.state.log.exercises[exerciseIndex - 1].sets[setIndex].log?.weight ? loggingContext.state.log.exercises[exerciseIndex - 1].sets[setIndex].log.weight : ''}
                                   placeholder={ghostData && ghostData.log && ghostData.log.weight}
                                   onChange={(e) => loggingContext.updateLogData && loggingContext.updateLogData(e, setIndex)} />
                            <p>KG</p>
                        </div>
                    </div>
                </div>
                </>
            }
            {setData.type === "range" &&
                <>
                <div className="workout-set__target">
                    <div>
                        <label>Target</label>
                        <p>{setData.range.from}-{setData.range.to} <span>Reps</span></p>
                    </div>
                </div>
                <div className="workout-set__logging">
                    <div>
                        <label>Achieved</label>
                        <div className="input-group">
                            <input type="number" name="reps" 
                                   value={loggingContext.state.log.exercises[exerciseIndex - 1].sets[setIndex].log?.reps ? loggingContext.state.log.exercises[exerciseIndex - 1].sets[setIndex].log.reps : ''}
                                   placeholder={ghostData && ghostData.log.reps}
                                   onChange={(e) => loggingContext.updateLogData && loggingContext.updateLogData(e, setIndex)} />
                            <p>Reps</p>
                        </div>
                    </div>
                    <div>
                        <div className="input-group">
                            <input type="number" name="weight"
                                   value={loggingContext.state.log.exercises[exerciseIndex - 1].sets[setIndex].log?.weight ? loggingContext.state.log.exercises[exerciseIndex - 1].sets[setIndex].log.weight : ''}
                                   placeholder={ghostData && ghostData.log.weight}
                                   onChange={(e) => loggingContext.updateLogData && loggingContext.updateLogData(e, setIndex)} />
                            <p>KG</p>
                        </div>
                    </div>
                </div>
                </>
            }
            {setData.type === "liss" &&
                <>
                <div className="workout-set__target">
                    <div>
                        <label>Target</label>
                        <p>{setData.liss.duration < 1 ? `${setData.liss.duration * 60}s` : `${setData.liss.duration} Minutes`}</p>
                    </div>
                    {setData.pace &&
                        <div>
                            <p> at {setData.pace} <span>km/h</span></p>
                        </div>
                    }
                </div>
                <div className="workout-set__timer">
                    <div>
                        <label>Timer</label>
                        <LoggingTimerBar time={setData.liss.duration} />
                    </div>
                </div>
                </>
            }
            {setData.type === "hiit" &&
                <>
                <div className="workout-set__target">
                    <div>
                        <label>Target (HIIT)</label>
                        <p>{setData.hiit.on}s <span className="text-blue">ON</span></p>
                    </div>
                    <div>
                        <p>{setData.hiit.off}s <span className="text-red">REST</span></p>
                    </div>
                    {setData.pace &&
                        <div>
                            <p> at {setData.pace} <span>km/h</span></p>
                        </div>
                    }
                    <div className="ml-auto">
                        <label>Duration</label>
                        <p>{timeFormat(setData.hiit.duration)}</p>
                    </div>
                </div>
                <div className="workout-set__timer">
                    <div>
                        <label>Timer</label>
                        <LoggingTimerHIIT {...setData.hiit} />
                    </div>
                </div>
            </>}
        </div>
    );
}

export default LoggingSet;