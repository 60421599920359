import React, { useState, useRef, useEffect, useContext } from 'react';
import { toast } from 'react-toastify';
import { GlobalContext } from '../../Contexts/Global/GlobalContext';

interface INutritionDiaryPicker {
    open: boolean,
    recipes: IRecipe[],
    closeModal: Function,
    diaryAddition: Function,
    myMeals: any,
    setMyMeals: Function
}

interface IRecipe {
    _id?: string,
    name: string,
    slug?: string,
    ingredients?: Array<string>,
    directions?: Array<string>,
    kcals: number,
    protein: number,
    carbohydrates: number,
    fats: number,
    time?: number,
    servings?: number,
    image?: string,
    category?: Array<string>
}

interface ISelected {
    open: boolean,
    selected: IRecipe | undefined,
    servings: number
}

interface IManual {
    name: string,
    kcals: number,
    protein: number,
    carbohydrates: number,
    fats: number,
    servings: number,
    save: boolean
}

interface IGlobalContext {
    setLoading?: Function,
    state?: any,
    setFavourites?: Function
}

const NutritionDiaryPicker = ({ open, recipes, closeModal, diaryAddition, myMeals, setMyMeals }: INutritionDiaryPicker) => {
    const globalVariables: any = window;
    const globalContext: IGlobalContext = useContext(GlobalContext);
    const [pickerOption, setPickerOption] = useState("recipes");
    const [filter, setFilter] = useState("all");
    const [viewMyMeals, setViewMyMeals] = useState<boolean>(false);
    const [viewMyFavourites, setViewMyFavourites] = useState<boolean>(false);
    const [selected, setSelected] = useState<ISelected>({open: false, selected: undefined, servings: 1});
    const [manual, setManual] = useState<IManual>({name: "", kcals: 0, protein: 0, carbohydrates: 0, fats: 0, servings: 1, save: false});
    const scrollContainer: any = useRef(null);
    
    const addManual = () => {
        let data = {...selected};
        data.selected = {
            name: manual.name,
            kcals: manual.kcals,
            protein: manual.protein,
            carbohydrates: manual.carbohydrates,
            fats: manual.fats,
            servings: manual.servings,
        }

        if(manual.save) {
            saveToMyMeals(manual);
        }

        setSelected({open: false, selected: undefined, servings: 1});
        setManual({name: "", kcals: 0, protein: 0, carbohydrates: 0, fats: 0, servings: 1, save: false});
        diaryAddition(data.selected);
    }

    const addToDiary = () => {
        let data: any = {...selected};
        data.selected = {
            name: data.selected.name,
            kcals: data.selected.kcals,
            protein: data.selected.protein,
            carbohydrates: data.selected.carbohydrates,
            fats: data.selected.fats,
            servings: selected.servings,
            image: data.selected.image
        }
        
        setSelected({open: false, selected: undefined, servings: 1});
        diaryAddition(data.selected);
    }

    const saveToMyMeals = async (data: IManual) => {
        await fetch(`${globalVariables.api}/nutrition/personal`, {
            method: 'POST',
            credentials: 'include',
            mode: 'cors',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }),
            body: JSON.stringify({...data})
        })
        .then(async (res) => {
            let data = await res.json();

            if(res.status === 200) {
                toast.success("Save to my meals!");
                setMyMeals(data);
            } else if (res.status === 401) {
                window.location.href = "/";
            } else {
                console.log(res);
            }
        })
        .catch(err => {
            console.log(err);
        });
    }

    const handleFilter = (e: any) => {
        if(e.target.value === "MyMeals") {
            setFilter(e.target.value);
            setViewMyMeals(true);
            setViewMyFavourites(false);
        } else if (e.target.value === "Favourites") {
            setFilter(e.target.value);
            setViewMyMeals(false);
            setViewMyFavourites(true);
        } else {
            setFilter(e.target.value);
            setViewMyMeals(false);
            setViewMyFavourites(false);
        }
    }

    useEffect(() => {
        if(open === false) {
            scrollContainer.current && scrollContainer.current.scrollTo(0, 0);
        }
    }, [open]);

    return (
        <>
            <div className={`overlay ${open ? 'is-active' : ''}`} onClick={() => { setTimeout(() => setPickerOption("recipes"), 1000); closeModal() }}></div>
            <div ref={scrollContainer} className={`modal modal--fixed-actions ${open ? 'is-open' : ''}`}>
                <div className="select-tabs select-tabs--alt">
                    <div className={`select-tabs__item ${pickerOption === "recipes" ? 'is-selected' : ''}`}>
                        <label>Recipes</label>
                        <input type="radio" name="pickerOption" value="recipes" checked={pickerOption === "recipes"} onChange={() => setPickerOption("recipes")} />
                    </div>
                    <div className={`select-tabs__item ${pickerOption === "manual" ? 'is-selected' : ''}`}>
                        <label>Manual</label>
                        <input type="radio" name="pickerOption" value="manual" checked={pickerOption === "manual"} onChange={() => setPickerOption("manual")} />
                    </div>
                </div>
                {pickerOption === "recipes" ?
                    <>
                    <div className="form-item mt-1">
                        <label>Show:</label>
                        <select name="filter" value={filter} onChange={(e) => handleFilter(e)}>
                            <option value="all">All</option>
                            <option value="Favourites">My Favourites</option>
                            <option value="Breakfast">Breakfast</option>
                            <option value="Lunch">Lunch</option>
                            <option value="Dinner">Dinner</option>
                            <option value="Desserts">Desserts</option>
                            <option value="Snacks">Snacks</option>
                            <option value="MyMeals" className="standout-option">My Meals</option>
                        </select>
                    </div>
                    <div className="video-library recipe">
                    {!viewMyMeals && !viewMyFavourites && recipes && recipes.map((recipe: IRecipe, index: number) => {
                            return (recipe.category?.includes(filter) || filter === "all") &&
                            <div className="video-library-item" key={index} onClick={() => setSelected({...selected, open: true, selected: recipe})}>
                                <div className="video-library-item__image recipe">
                                    <img src={`${globalVariables.api}/media/${recipe.image}`} alt={recipe.name} />
                                    {recipe.slug === null &&
                                        <div className="recipe-lock">
                                            <svg>
                                                <use xlinkHref="/assets/icon-library.svg#lock" />
                                            </svg>
                                        </div>
                                    }
                                </div>
                                <div className="video-library-item__title">
                                    {recipe.name}
                                </div>
                                {recipe.category?.includes("vegan") &&
                                    <div className="vegan-icon">
                                        <span>V</span>
                                        <div className="vegan-icon__tooltip">
                                            Vegan Friendly
                                        </div>
                                    </div>
                                }
                            </div>
                            }
                    )}
                    </div>
                    {viewMyMeals && 
                        <div>
                        {myMeals && myMeals.items && myMeals.items.length > 0 ?
                            <>
                                {myMeals.items.map((meal: any, index: any) =>
                                    <div className="nutrition-diary-card" key={index} onClick={() => setSelected({...selected, open: true, selected: meal})}>
                                        <div className="nutrition-diary-card__inner">
                                            <div className="nutrition-diary-card__title mb-1">{meal.name}</div>
                                            <div className="nutrition-diary-card__nutrition">
                                                <div className="nutrition-diary-card__stat">{meal.kcals} kcal</div>
                                                <div className="nutrition-diary-card__stat protein"><span></span>{meal.protein}g</div>
                                                <div className="nutrition-diary-card__stat carbohydrates"><span></span>{meal.carbohydrates}g</div>
                                                <div className="nutrition-diary-card__stat fats"><span></span>{meal.fats}g</div></div>
                                        </div>
                                    </div>
                                )}
                            </>
                        :
                        <>
                            <p>You currently to not have any saved meals!</p>
                        </>
                        }
                        </div>
                    }
                    {viewMyFavourites &&
                        <div>
                        {globalContext.state && globalContext.state.favourites && globalContext.state.favourites.recipes.length > 0 ?
                            <div className="video-library recipe">
                                {recipes.filter(recipe => globalContext.state.favourites.recipes.includes(recipe._id)).map((recipe: any, index: any) =>
                                    <div className="video-library-item" key={index} onClick={() => setSelected({...selected, open: true, selected: recipe})}>
                                        <div className="video-library-item__image recipe">
                                            <img src={`${globalVariables.api}/media/${recipe.image}`} alt={recipe.name} />
                                            {recipe.slug === null &&
                                                <div className="recipe-lock">
                                                    <svg>
                                                        <use xlinkHref="/assets/icon-library.svg#lock" />
                                                    </svg>
                                                </div>
                                            }
                                        </div>
                                        <div className="video-library-item__title">
                                            {recipe.name}
                                        </div>
                                        {recipe.category?.includes("vegan") &&
                                            <div className="vegan-icon">
                                                <span>V</span>
                                                <div className="vegan-icon__tooltip">
                                                    Vegan Friendly
                                                </div>
                                            </div>
                                        }
                                    </div>
                                )}
                            </div>
                        :
                        <>
                            <p>You currently to not have any favourite meals!</p>
                        </>
                        }
                        </div>
                    }
                    <div className="modal__actions">
                        <button className="btn btn--block btn--text" onClick={() => { setTimeout(() => setPickerOption("recipes"), 1000); closeModal() }}>Cancel</button>
                    </div>
                    </>
                    :
                    <>
                        <div className="form-item mt-1">
                            <label>Name</label>
                            <input type="text" name="name" min={0} value={manual.name} onChange={(e: any) => setManual({...manual, [e.target.name]: e.target.value})} />
                        </div>
                        <div className="form-item mt-1">
                            <label>Kcals</label>
                            <input type="number" name="kcals" min={0} value={manual.kcals} onChange={(e: any) => setManual({...manual, [e.target.name]: e.target.value})} />
                        </div>
                        <div className="form-item mt-1">
                            <label>Protein</label>
                            <input type="text" name="protein" min={0} value={manual.protein} onChange={(e: any) => setManual({...manual, [e.target.name]: e.target.value})} />
                        </div>
                        <div className="form-item mt-1">
                            <label>Carbohydrates</label>
                            <input type="text" name="carbohydrates" min={0} value={manual.carbohydrates} onChange={(e: any) => setManual({...manual, [e.target.name]: e.target.value})} />
                        </div>
                        <div className="form-item mt-1">
                            <label>Fats</label>
                            <input type="text" name="fats" min={0} value={manual.fats} onChange={(e: any) => setManual({...manual, [e.target.name]: e.target.value})} />
                        </div>
                        <div className="form-item mt-1">
                            <label>Servings</label>
                            <input type="text" name="servings" min={0} value={manual.servings} onChange={(e: any) => setManual({...manual, [e.target.name]: e.target.value})} />
                        </div>
                        <div className="form-item mt-1">
                            <label>
                                <input type="checkbox" name="save" checked={manual.save} onChange={(e: any) => setManual({...manual, [e.target.name]: !manual.save})} />
                                Save to My Meals
                            </label>
                        </div>
                        <div className="modal__actions">
                            <button className="btn btn--text" onClick={() => { setTimeout(() => setPickerOption("recipes"), 1000); closeModal() }}>Cancel</button>
                            <button className="btn" onClick={() => addManual()}>Add +</button>
                        </div>
                    </>
                }
            </div>
            <div className={`overlay level-2 ${selected.open ? 'is-active' : ''}`} onClick={() => setSelected({...selected, open: false})}></div>
            <div className={`modal level-2 ${selected.open ? 'is-open' : ''}`}>
                {selected.selected &&
                    <>
                        <h2>{selected.selected.name}</h2>
                        <div className="nutrition-diary-card__nutrition">
                            <div className="nutrition-diary-card__stat">
                                {(selected.selected.kcals * selected.servings).toFixed(0)} kcal
                            </div>
                            <div className="nutrition-diary-card__stat protein">
                                <span></span>
                                {(selected.selected.protein * selected.servings).toFixed(0)}g
                            </div>
                            <div className="nutrition-diary-card__stat carbohydrates">
                                <span></span>
                                {(selected.selected.carbohydrates * selected.servings).toFixed(0)}g
                            </div>
                            <div className="nutrition-diary-card__stat fats">
                                <span></span>
                                {(selected.selected.fats * selected.servings).toFixed(0)}g
                            </div>
                        </div>
                        <div className="form-item mt-1">
                            <label>Servings</label>
                            <input type="number" step={0.1} min="0" value={selected.servings} onChange={(e) => setSelected({...selected, servings: parseFloat(e.target.value)})} />
                        </div>
                        <div className="modal__actions">
                            <button className="btn btn--text" onClick={() => setSelected({...selected, open: false, servings: 1})}>Close</button>
                            <button className="btn" onClick={() => addToDiary()}>Add +</button>
                        </div>
                    </>
                }

            </div>
        </>
    )
}

export default NutritionDiaryPicker;