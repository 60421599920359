import React, { useState, useEffect, useCallback, useContext } from 'react';
import { v4 as uuidv4} from 'uuid';
import Header from '../../Components/Header/Header';

// Context
import { GlobalContext } from '../../Contexts/Global/GlobalContext';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { date } from 'yup';

interface IGlobalContext {
    setLoading?: Function
}

interface ISet {
    id: string,
    type: "reps" | "range" | "liss" | "hiit" | "failure" | "dropset",
    weight?: number | undefined,
    pace?: number,
    reps?: {
        target: number
    },
    range?: {
        from: number,
        to: number
    },
    liss?: {
        duration: number,
        pace: number
    },
    hiit?: {
        on: number,
        off: number,
        duration: number,
        pace: number
    },
    dropset?: [
        {
            id: string,
            reps: number,
            weight?: number
        }
    ]
}

interface IExercise {
    _id: string,
    name: string,
    category: string[],
    description?: string,
    video?: string,
    cardioOnly: boolean,
    image?: string,
    sets?: ISet[]
}

interface IPicker {
    exercises: string[],
    open: boolean,
    superset?: boolean,
    exerciseid?: string
}

interface IWeek {
    id: string,
    days: IDay[]
}

interface IDay {
    id: string,
    name?: string,
    type: string,
    data: IExercise[],
    video: string,
    wid: string,
    [x:string]: any
}

interface IGuide {
    _id?: string,
    title: string,
    slug?: string,
    description?: string,
    video?: string,
    image?: string,
    data?: any,
    category: string,
    type: string,
    premium: boolean
}

const ChallengeDayEditor = ({ history }: any) => {
    const globalVariables: any = window;
    const globalContext: IGlobalContext = useContext(GlobalContext);
    const [guideMeta, setGuideMeta] = useState<IGuide>({title: "", description: "", video: "", image: "", category: "gym", type: "plan", premium: true});
    const [exercises, setExercises] = useState<IExercise[] | undefined>(undefined);
    const [picker, setPicker] = useState<IPicker>({exercises: [], open: false});
    const [data, setData] = useState<IWeek[] | any>([]);
    const alphabet: string[] = ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z"];
    const [day, setDay] = useState<any>(undefined);
    const [copyid, setCopyid] = useState<string>("");
    
    const { id } = useParams();

    const fetchExercises = useCallback(async () => {
        await fetch(`${globalVariables.api}/workout/exercise/all`, {
            method: 'GET',
            credentials: 'include',
            mode: 'cors',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            })
        })
        .then(async (res) => {
            let data = await res.json();

            if(res.status === 200) {
                setExercises(data);
            } else {
                alert("Error");
                console.log(res);
            }
        })
        .catch(err => {
            console.log(err);
            alert("error");
        });
    }, []);

    const fetchDay = async () => {
        await fetch(`${globalVariables.api}/workout/challenge/day/${id}`, {
            method: 'GET',
            credentials: 'include',
            mode: 'cors',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            })
        })
        .then(async (res) => {
            let data = await res.json();

            if(res.status === 200) {
                setDay(data);
            } else {
                alert("Error");
                console.log(res);
            }
        })
        .catch(err => {
            console.log(err);
            alert("error");
        });
    }

    useEffect(() => {
        fetchExercises();

        if(id) {
            fetchDay();
        }
    }, [fetchExercises]);

    const alterMeta = (e: any) => {
        setDay({...day, [e.target.name]: e.target.value});
    }

    const alterWeek = (action: string, id?: string) => {
        if(action === "add") {
            let id = `week-${uuidv4()}`;

            const week: IWeek = {
                id,
                days: []
            }

            setData([...data, week]);
        } else if (action === "remove") {
            if (id) {
                let weeks = [...data];

                weeks = weeks.filter(week => week.id !== id);

                setData(weeks);
            } else {
                alert("No week id provided.");
            }
        } else if (action === "duplicate") {
            if(id) {
                let weeks: IWeek[] = data.map((a: IWeek) => ({...a})),
                    toCopy = weeks.filter(week => week.id === id)[0];

                toCopy.id = `week-${uuidv4()}`;

                setData([...data, toCopy]);

            } else {
                alert("No week id provided.");
            }
        } else {
            alert("Unknown action");
        }
    }

    const alterDay = (action: string, weekid: string, id?: string, e?: any) => {
    //     if(weekid) {
    //         if(action === "add") {
    //             let weeks: IWeek[] = data.map((a: IWeek) => ({...a}));
    
    //             weeks.forEach(week => {
    //                 if(week.id === weekid) {
    //                     const day = {
    //                         id: `day-${uuidv4()}`,
    //                         exercises: []
    //                     }

    //                     week.days.push(day);
    //                 }
    //             });

    //             setData(weeks);
    //         } else if (action === "remove") {
    //             if(id) {
    //                 let weeks: IWeek[] = data.map((a: IWeek) => ({...a}));
    
    //                 weeks.forEach(week => {
    //                     if(week.id === weekid) {
    //                         week.days = week.days.filter(day => day.id !== id);
    //                     }
    //                 });

    //                 setData(weeks);
    //             } else {
    //                 alert("Day ID must be provided.");
    //             }
    //         } else if (action === "detail") {
    //             let weeks: IWeek[] = data.map((a: IWeek) => ({...a}));
    
    //                 weeks.forEach(week => {
    //                     if(week.id === weekid) {
    //                         week.days.forEach((day: any) => {
    //                             if(day.id === id) {
    //                                 day[e.target.name] = e.target.value;
    //                             }
    //                         });
    //                     }
    //                 });

    //                 setData(weeks);
    //         } else {
    //             alert("Week id is required");
    //         }
    // }
}

    const alterExercise = (action: string, id?: string | null, selected?: string[]) => {
        if(action === "add" || action === "superset") {
    
            if(selected) {

                let exerciseData: any = [];

                selected.forEach(exercise => {
                    let eData: IExercise | undefined = undefined;

                    if(exercises) {
                        eData = exercises && exercises.filter(ex => ex._id === exercise)[0];
                    }
                    
                    if(eData) {
                        if(action === "add") {
                            exerciseData.push({
                                id: `exercise-${uuidv4()}`,
                                eid: exercise,
                                superset: false,
                                sets: [
                                    {
                                        id: `set-${uuidv4()}`,
                                        type: eData.cardioOnly ? 'liss' : 'reps',
                                        weight: undefined,
                                        pace: 7,
                                        reps: {
                                            target: 10
                                        },
                                        repRange: {
                                            from: 8,
                                            to: 12
                                        },
                                        liss: {
                                            duration: 10
                                        },
                                        hiit: {
                                            on: 60,
                                            off: 30,
                                            duration: 10
                                        },
                                        dropset: [
                                            {
                                                reps: 8,
                                                weight: 8
                                            }
                                        ]
                                    }
                                ],
                                supersetExercises: []
                            });
                        } else if (action === "superset") {
                            exerciseData.push({
                                id: `ss-exercise-${uuidv4()}`,
                                eid: exercise,
                                sets: [
                                    {
                                        id: `set-${uuidv4()}`,
                                        type: eData.cardioOnly ? 'liss' : 'reps',
                                        weight: undefined,
                                        pace: 7,
                                        reps: {
                                            target: 10
                                        },
                                        repRange: {
                                            from: 8,
                                            to: 12
                                        },
                                        liss: {
                                            duration: 10
                                        },
                                        hiit: {
                                            on: 60,
                                            off: 30,
                                            duration: 10
                                        },
                                        dropset: [
                                            {
                                                reps: 8,
                                                weight: 8
                                            }
                                        ]
                                    }
                                ]
                            });
                        }
                        
                    } else {
                        alert("Exercise data not found for one of the selected exercises.");
                    }
                });
                let data = [...day.data];

                                if(action === "add") {
                                    data = [...day.data, ...exerciseData];
                                } else if (action === "superset") {
                                    data.forEach((exercise: any) => {
                                        if(exercise.id === id) {
                                            let setCount = exercise.sets.length;

                                            if(setCount > 1) {
                                                exerciseData.forEach((ed: any) => {
                                                    let diff = setCount - ed.sets.length;

                                                    let copyObj = {...ed.sets[0]};
                                                    
                                                    for(let i = 0; i < diff; i++) {
                                                        ed.sets.push({...copyObj, id: `set-${uuidv4()}`});
                                                    }
                                                });
                                            }

                                            exercise.supersetExercises = [...exercise.supersetExercises, ...exerciseData];
                                            exercise.superset = true;
                                        }
                                    });
                                }

                setDay({...day, data: data});
                setPicker({exercises: [], open: false});
            } else {
                alert("No exercises selected");
            }
        } else if (action === "remove") {
            let exercises = [...day.data];

            exercises = exercises.map((a) => ({...a}));
            exercises = exercises.filter(exercise => exercise.id !== id);

            setDay({...day, data: exercises});
        }
    }

    const alterSet = (action: string, exerciseid: string, id?: string, type?: string | null, e?: any, dropsetid?: string) => {
        let data = [...day.data];

                        data.forEach(exercise => {
                            exercise.sets = exercise.sets.map((a: ISet) => ({...a}));
                            
                            if(exercise.id === exerciseid) {
                                if(action === "add") {
                                    if(exercise.sets.length > 0) {
                                        let toCopy = {...exercise.sets[exercise.sets.length - 1]};
                                        toCopy.id = `set-${uuidv4()}`;
                                        exercise.sets.push(toCopy);

                                        if(exercise.superset) {
                                            exercise.supersetExercises.forEach((ssExercise: any) => {
                                                let toCopy = {...ssExercise.sets[0]};
                                                toCopy.id = `set-${uuidv4()}`;

                                                ssExercise.sets.push(toCopy);
                                            });
                                        }
                                    } else {
                                        let eData = exercises && exercises.filter(e => e._id === exercise.eid)[0];
                                        
                                        if(eData) {
                                            exercise.sets.push(
                                                {
                                                    id: `set-${uuidv4()}`,
                                                    type: eData.cardioOnly ? 'liss' : 'reps',
                                                    weight: undefined,
                                                    pace: 7,
                                                    reps: {
                                                        target: 10
                                                    },
                                                    repRange: {
                                                        from: 8,
                                                        to: 12
                                                    },
                                                    liss: {
                                                        duration: 10
                                                    },
                                                    hiit: {
                                                        on: 60,
                                                        off: 30,
                                                        duration: 10
                                                    },
                                                    dropset: [
                                                        {
                                                            id: `dropset-${uuidv4()}`,
                                                            reps: 8,
                                                            weight: 8
                                                        }
                                                    ]
                                                }
                                            );
                                        } else {
                                            alert("No exercise data found for this exercise.");
                                        }
                                    }
                                } else if (action === "remove") {
                                    exercise.sets = exercise.sets.filter((set: any) => set.id !== id);
                                } else if (action === "detail") {
                                    exercise.sets.forEach((set: any) => {
                                        if(set.id === id) {
                                            set[e.target.name] = e.target.value;
                                        }
                                    });
                                } else if (action === "setData") {
                                    exercise.sets.forEach((set: any) => {
                                        if(set.id === id && type) {
                                            set[type] = {...set[type]};
                                            set[type][e.target.name] = e.target.value
                                        }
                                    });
                                } else if (action === "addDropSet") {
                                    exercise.sets.forEach((set: any) => {
                                        if(set.id === id) {
                                            set.dropset = [...set.dropset];
                                            set.dropset.push({id: `dropset-${uuidv4()}`, reps: 10, weight: 5});
                                        }
                                    });
                                } else if (action === "editDropSet") {
                                    exercise.sets.forEach((set: any) => {
                                        if(set.id === id) {
                                            set.dropset = set.dropset.map((a: any) => ({...a}));
                                            set.dropset.forEach((drop: any) => {
                                                if(drop.id === dropsetid) {
                                                    drop[e.target.name] = e.target.value;
                                                }
                                            });
                                        }
                                    });
                                } else if (action === "deleteDropSet") {
                                    exercise.sets.forEach((set: any) => {
                                        set.dropset = set.dropset.map((a: any) => ({...a}));
                                        set.dropset = set.dropset.filter((drop: any) => drop.id !== dropsetid);
                                    });
                                }
                            }
                        });

        setDay({...day, data: data});
    }

    const alterSuperset = (action: string, exerciseid: string, supersetid: string, id: string, e?: any, type?: string, dropsetid?: string) => {
        let data = [...day.data];
                        data.forEach(exercise => {
                            exercise.sets = exercise.sets.map((a: ISet) => ({...a}));
                            
                            if(exercise.id === exerciseid) {
                                if(action === "detail") {
                                    exercise.supersetExercises.forEach((ssExercise: any) => {
                                        if(ssExercise.id === supersetid) {
                                            ssExercise.sets.forEach((ssSet: any) => {
                                                if(ssSet.id === id) {
                                                    ssSet[e.target.name] = e.target.value;
                                                }
                                            });
                                        }
                                    });
                                } else if (action === "setData") {
                                    exercise.supersetExercises.forEach((ssExercise: any) => {
                                        if(ssExercise.id === supersetid) {
                                            ssExercise.sets.forEach((ssSet: any) => {
                                                if(ssSet.id === id && type) {
                                                    ssSet[type] = {...ssSet[type]};
                                                    ssSet[type][e.target.name] = e.target.value;
                                                }
                                            });
                                        }
                                    });
                                } else if (action === "remove") {
                                    exercise.supersetExercises = exercise.supersetExercises.map((a: any) => ({...a}));
                                    exercise.supersetExercises = exercise.supersetExercises.filter((a: any) => a.id !== supersetid);

                                    if(exercise.supersetExercises.length < 1) {
                                        exercise.superset = false;
                                    }
                                } else if (action === "addDropSet") {
                                    exercise.supersetExercises.forEach((ssExercise: any) => {
                                        if(ssExercise.id === supersetid) {
                                            ssExercise.sets.forEach((ssSet: any) => {
                                                if(ssSet.id === id) {
                                                    ssSet.dropset = [...ssSet.dropset];
                                                    ssSet.dropset.push({id: `dropset-${uuidv4()}`, reps: 10, weight: 5});
                                                }
                                            });
                                        }
                                    });
                                } else if (action === "editDropSet") {
                                    exercise.supersetExercises.forEach((ssExercise: any) => {
                                        if(ssExercise.id === supersetid) {
                                            ssExercise.sets.forEach((ssSet: any) => {
                                                if(ssSet.id === id) {
                                                    ssSet.dropset = ssSet.dropset.map((a: any) => ({...a}));
                                                    ssSet.dropset.forEach((drop: any) => {
                                                        if(drop.id === dropsetid) {
                                                            drop[e.target.name] = e.target.value;
                                                        }
                                                    });
                                                }
                                            });
                                        }
                                    });
                                } else if (action === "deleteDropSet") {
                                    exercise.supersetExercises.forEach((ssExercise: any) => {
                                        if(ssExercise.id === supersetid) {
                                            ssExercise.sets.forEach((ssSet: any) => {
                                                if(ssSet.id === id) {
                                                    ssSet.dropset = ssSet.dropset.map((a: any) => ({...a}));
                                                    ssSet.dropset = ssSet.dropset.filter((drop: any) => drop.id !== dropsetid);
                                                }
                                            });
                                        }
                                    });
                                }
                            }
                        });

        setDay({...day, data: data});
    }

    const handeExerciseSelect = (exerciseid: string) => {
        if(picker.exercises.includes(exerciseid)) {
            setPicker({...picker, exercises: [...picker.exercises].filter(exercise => exercise !== exerciseid)});
        } else {
            setPicker({...picker, exercises: [...picker.exercises, exerciseid]});
        }
    }

    const saveWorkout = async () => {
        globalContext.setLoading && globalContext.setLoading(true);
        let guideObj = {
            ...guideMeta,
            data: JSON.stringify(data)
        };

        await fetch(`${globalVariables.api}/workout`, {
            method: 'POST',
            credentials: 'include',
            mode: 'cors',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }),
            body: JSON.stringify(guideObj)
        })
        .then(async res => {
            if(res.status === 200) {
                toast.success("Guide has been saved.");
                let workout = await res.json();
                history.push(`/guide-build/${workout.slug}`);
            } else {
                globalContext.setLoading && globalContext.setLoading(false);
                console.log(await res.json());
            }
        })
        .catch(err => {
            console.log(err);
        });
    }

    const updateWorkout = async () => {
        globalContext.setLoading && globalContext.setLoading(true);

        await fetch(`${globalVariables.api}/workout/challenge/day`, {
            method: 'PUT',
            credentials: 'include',
            mode: 'cors',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }),
            body: JSON.stringify({...day})
        })
        .then(async res => {
            if(res.status === 200) {
                toast.success("Challenge day has been updated.");
                globalContext.setLoading && globalContext.setLoading(false);
            } else {
                globalContext.setLoading && globalContext.setLoading(false);
                console.log(await res.json());
            }
        })
        .catch(err => {
            console.log(err);
        });
    }

    const copyDay = async () => {
        await fetch(`${globalVariables.api}/workout/challenge/day/${copyid}`, {
            method: 'GET',
            credentials: 'include',
            mode: 'cors',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            })
        })
        .then(async (res) => {
            let data = await res.json();

            if(res.status === 200) {
                setDay({...day, name: data.name, data: data.data});
            } else {
                alert("Error");
                console.log(res);
            }
        })
        .catch(err => {
            console.log(err);
            alert("error");
        });
    }

    return (
        <>
            <Header title="Guide Creator" />
            {day &&
                <div className="home-container">
                    <div className="form-item">
                        <label>Day Name (Optional: default to day + index)</label>
                        <input type="text" name="name" value={day.name} onChange={(e) => alterMeta(e)} />
                    </div>
                    <div className="form-item">
                        <label>Category</label>
                        <select name="type" value={day.type} onChange={(e) => alterMeta(e)}>
                            <option value="workout">Workout</option>
                            <option value="video">Video</option>
                        </select>
                    </div>
                    <div className="form-item">
                        <label>Video (vimeo url)</label>
                        <input type="text" name="video" value={day.video} onChange={(e) => alterMeta(e)} />
                    </div>
                    <div className="form-item">
                        <label>Copy Day (paste id)</label>
                        <input type="text" name="copyid" value={copyid} onChange={(e) => setCopyid(e.target.value)} />
                        <button className="btn mt-1" onClick={() => copyDay()}>Copy Workout</button>
                    </div>
                    <div className="internal-container">
                        <h2>Build</h2>
                        <div className="guide-build">
                            <div className="guide-day is-open">
                        {day.data.length > 0 && day.data.map((exercise: any, index: number) =>
                                                <div className="guide-exercise" key={index}>
                                                    <div className="guide-exercise__header">
                                                        <div>
                                                            <p>{index + 1}: {exercise.superset && <b>a.</b>}{exercises && exercises.filter(e => e._id === exercise.eid)[0].name}</p>
                                                            {exercise.superset && exercise.supersetExercises.map((ssExercise: any, ssi: any) => 
                                                                <p><b>{alphabet[ssi + 1]}.</b>{exercises && exercises.filter(e => e._id === ssExercise.eid)[0].name}</p>
                                                            )}
                                                        </div>
                                                        <div className="guide-exercise__actions">
                                                            <button className="icon-btn" onClick={() => setPicker({...picker, open: true, superset: true, exerciseid: exercise.id})}>s</button>
                                                            <button className="icon-btn" onClick={() => alterExercise("remove", exercise.id)}>
                                                                <svg>
                                                                    <use xlinkHref="/assets/icon-library.svg#trash" />
                                                                </svg>
                                                            </button>
                                                            <button className="icon-btn" onClick={() => alterSet("add", exercise.id)}>+</button>
                                                        </div>
                                                    </div>
                                                    <div className="guide-exercise__main">
                                                        {exercise.sets.length > 0 && exercise.sets.map((set: ISet, index: any) => 
                                                            <>
                                                            <div className="guide-set" key={index}>
                                                                <div className="guide-set__header">
                                                                    <p>Set {index + 1}{exercise.superset ? 'a' : ''}</p>
                                                                    <div className="guide-set__actions">
                                                                        <select name="type" value={set.type} onChange={(e) => alterSet("detail", exercise.id, set.id, null, e)}>
                                                                            <option value="liss">LISS</option>
                                                                            <option value="hiit">HIIT</option>
                                                                            {exercises && !exercises.filter(e => e._id === exercise.eid)[0].cardioOnly &&
                                                                                <>
                                                                                    <option value="reps">Reps</option>
                                                                                    <option value="range">Range</option>
                                                                                    <option value="failure">Failure</option>
                                                                                    <option value="dropset">Dropset</option>
                                                                                </>
                                                                            }
                                                                        </select>
                                                                        <button className="icon-btn" onClick={() => alterSet("remove", exercise.id, set.id)}>
                                                                            <svg>
                                                                                <use xlinkHref="/assets/icon-library.svg#trash" />
                                                                            </svg>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                                {set.type === "reps" &&
                                                                    <div className="guide-set__main">
                                                                        <div className="form-item">
                                                                            <label>Reps</label>
                                                                            <input type="number" name="target" value={set.reps?.target} placeholder="Reps" onChange={(e) => alterSet("setData", exercise.id, set.id, "reps", e)} />
                                                                        </div>
                                                                        <div className="form-item">
                                                                            <label>Weight (kg)</label>
                                                                            <input type="number" name="weight" step="0.01" min="0" value={set.weight} placeholder="Weight (optional)" onChange={(e) => alterSet("detail", exercise.id, set.id, null, e)} />
                                                                        </div>
                                                                    </div>
                                                                }
                                                                {set.type === "range" &&
                                                                    <div className="guide-set__main">
                                                                        <div className="form-item">
                                                                            <label>From</label>
                                                                            <input type="number" name="from" value={set.range?.from} placeholder="From" onChange={(e) => alterSet("setData", exercise.id, set.id, "range", e)} />
                                                                        </div>
                                                                        <div className="form-item">
                                                                            <label>To</label>
                                                                            <input type="number" name="to" value={set.range?.to} placeholder="To" onChange={(e) => alterSet("setData", exercise.id, set.id, "range", e)} />
                                                                        </div>
                                                                        <div className="form-item">
                                                                            <label>Weight (kg)</label>
                                                                            <input type="number" name="weight" step="0.01" min="0" value={set.weight} placeholder="Weight (optional)" onChange={(e) => alterSet("detail", exercise.id, set.id, null, e)} />
                                                                        </div>
                                                                    </div>
                                                                }
                                                                {set.type === "liss" && 
                                                                    <div className="guide-set__main">
                                                                        <div className="form-item">
                                                                            <label>Duration (minutes)</label>
                                                                            <input type="number" name="duration" value={set.liss?.duration} placeholder="Duration" onChange={(e) => alterSet("setData", exercise.id, set.id, "liss", e)} />
                                                                        </div>
                                                                        <div className="form-item">
                                                                            <label>Pace</label>
                                                                            <input type="number" name="pace" step="0.01" min="0" value={set.pace} placeholder="Pace" onChange={(e) => alterSet("detail", exercise.id, set.id, null, e)} />
                                                                        </div>
                                                                    </div>
                                                                }
                                                                {set.type === "hiit" &&
                                                                    <div className="guide-set__main">
                                                                        <div className="form-item">
                                                                            <label>On (s)</label>
                                                                            <input type="number" name="on" value={set.hiit?.on} placeholder="On" onChange={(e) => alterSet("setData", exercise.id, set.id, "hiit", e)} />
                                                                        </div>
                                                                        <div className="form-item">
                                                                            <label>Off (s)</label>
                                                                            <input type="number" name="off" value={set.hiit?.off} placeholder="Off" onChange={(e) => alterSet("setData", exercise.id, set.id, "hiit", e)} />
                                                                        </div>
                                                                        <div className="form-item">
                                                                            <label>Duration (minutes)</label>
                                                                            <input type="number" name="duration" value={set.hiit?.duration} placeholder="Duration" onChange={(e) => alterSet("setData", exercise.id, set.id, "hiit", e)} />
                                                                        </div>
                                                                        <div className="form-item">
                                                                            <label>Pace</label>
                                                                            <input type="number" name="pace" step="0.01" min="0" value={set.pace} placeholder="Pace" onChange={(e) => alterSet("detail", exercise.id, set.id, null, e)} />
                                                                        </div>
                                                                    </div>
                                                                }
                                                                {set.type === "dropset" &&
                                                                    <div className="guide-set__main dropset">
                                                                        {set.dropset && set.dropset.map((dropset, index) => 
                                                                        <div className="dropset-item" key={index}>
                                                                                <p>{index + 1}</p>
                                                                                <div className="form-item">
                                                                                    <label>Reps</label>
                                                                                    <input type="number" name="reps" value={dropset.reps} placeholder="Reps" onChange={(e) => alterSet("editDropSet", exercise.id, set.id, "dropset", e, dropset.id)} />
                                                                                </div>
                                                                                <div className="form-item">
                                                                                    <label>Weight (kg)</label>
                                                                                    <input type="number" name="weight" step="0.01" min="0" value={dropset.weight} placeholder="Weight (optional)" onChange={(e) => alterSet("editDropSet", exercise.id, set.id, "dropset", e, dropset.id)} />
                                                                                </div>
                                                                                <button className="icon-btn" onClick={(e) => alterSet("deleteDropSet", exercise.id, set.id, "dropset", e, dropset.id)}>
                                                                                    <svg>
                                                                                        <use xlinkHref="/assets/icon-library.svg#trash" />
                                                                                    </svg>
                                                                                </button>
                                                                            </div>  
                                                                        )}
                                                                        <button className="guide-add extra-small" onClick={() => alterSet("addDropSet", exercise.id, set.id, null)}>Add Dropset +</button>
                                                                    </div>
                                                                }
                                                            </div>
                                                            {exercise.supersetExercises.length > 0 && exercise.supersetExercises.map((ssexercise: any, ssi: any) =>
                                                                <>
                                                                    <div className="guide-set" key={ssi}>
                                                                        <div className="guide-set__header">
                                                                            <p>Set {index + 1}{alphabet[ssi + 1]}</p>
                                                                            <div className="guide-set__actions">
                                                                                <select name="type" value={ssexercise.sets[index].type} onChange={(e) => alterSuperset("detail", exercise.id, ssexercise.id, ssexercise.sets[index].id, e)}>
                                                                                    <option value="liss">LISS</option>
                                                                                    <option value="hiit">HIIT</option>
                                                                                    {exercises && !exercises.filter(e => e._id === ssexercise.eid)[0].cardioOnly &&
                                                                                        <>
                                                                                            <option value="reps">Reps</option>
                                                                                            <option value="range">Range</option>
                                                                                            <option value="failure">Failure</option>
                                                                                            <option value="dropset">Dropset</option>
                                                                                        </>
                                                                                    }
                                                                                </select>
                                                                                <button className="icon-btn" onClick={() => alterSuperset("remove", exercise.id, ssexercise.id, ssexercise.sets[index].id)}>
                                                                                    <svg>
                                                                                        <use xlinkHref="/assets/icon-library.svg#trash" />
                                                                                    </svg>
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                        {ssexercise.sets[index].type === "reps" &&
                                                                            <div className="guide-set__main">
                                                                                <div className="form-item">
                                                                                    <label>Reps</label>
                                                                                    <input type="number" name="target" value={ssexercise.sets[index].reps?.target} placeholder="Reps" onChange={(e) => alterSuperset("setData", exercise.id, ssexercise.id, ssexercise.sets[index].id, e, "reps")} />
                                                                                </div>
                                                                                <div className="form-item">
                                                                                    <label>Weight (kg)</label>
                                                                                    <input type="number" name="weight" step="0.01" min="0" value={ssexercise.sets[index].weight} placeholder="Weight (optional)" onChange={(e) => alterSuperset("detail", exercise.id, ssexercise.id, ssexercise.sets[index].id, e)} />
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                        {ssexercise.sets[index].type === "range" &&
                                                                            <div className="guide-set__main">
                                                                                <div className="form-item">
                                                                                    <label>From</label>
                                                                                    <input type="number" name="from" value={ssexercise.sets[index].range?.from} placeholder="From" onChange={(e) => alterSuperset("setData", exercise.id, ssexercise.id, ssexercise.sets[index].id, e, "range")} />
                                                                                </div>
                                                                                <div className="form-item">
                                                                                    <label>To</label>
                                                                                    <input type="number" name="to" value={ssexercise.sets[index].range?.to} placeholder="To" onChange={(e) => alterSuperset("setData", exercise.id, ssexercise.id, ssexercise.sets[index].id, e, "range")} />
                                                                                </div>
                                                                                <div className="form-item">
                                                                                    <label>Weight (kg)</label>
                                                                                    <input type="number" name="weight" step="0.01" min="0" value={ssexercise.sets[index].weight} placeholder="Weight (optional)" onChange={(e) => alterSuperset("detail", exercise.id, ssexercise.id, ssexercise.sets[index].id, e)} />
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                        {ssexercise.sets[index].type === "liss" && 
                                                                            <div className="guide-set__main">
                                                                                <div className="form-item">
                                                                                    <label>Duration (minutes)</label>
                                                                                    <input type="number" name="duration" value={ssexercise.sets[index].liss?.duration} placeholder="Duration" onChange={(e) => alterSuperset("setData", exercise.id, ssexercise.id, ssexercise.sets[index].id, e, "liss")} />
                                                                                </div>
                                                                                <div className="form-item">
                                                                                    <label>Pace</label>
                                                                                    <input type="number" name="pace" step="0.01" min="0" value={ssexercise.sets[index].pace} placeholder="Pace" onChange={(e) => alterSuperset("detail", exercise.id, ssexercise.id, ssexercise.sets[index].id, e)} />
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                        {ssexercise.sets[index].type === "hiit" &&
                                                                            <div className="guide-set__main">
                                                                                <div className="form-item">
                                                                                    <label>On (s)</label>
                                                                                    <input type="number" name="on" value={ssexercise.sets[index].hiit?.on} placeholder="On" onChange={(e) => alterSuperset("setData", exercise.id, ssexercise.id, ssexercise.sets[index].id, e, "hiit")} />
                                                                                </div>
                                                                                <div className="form-item">
                                                                                    <label>Off (s)</label>
                                                                                    <input type="number" name="off" value={ssexercise.sets[index].hiit?.off} placeholder="Off" onChange={(e) => alterSuperset("setData", exercise.id, ssexercise.id, ssexercise.sets[index].id, e, "hiit")} />
                                                                                </div>
                                                                                <div className="form-item">
                                                                                    <label>Duration (minutes)</label>
                                                                                    <input type="number" name="duration" value={ssexercise.sets[index].hiit?.duration} placeholder="Duration" onChange={(e) => alterSuperset("setData", exercise.id, ssexercise.id, ssexercise.sets[index].id, e, "hiit")} />
                                                                                </div>
                                                                                <div className="form-item">
                                                                                    <label>Pace</label>
                                                                                    <input type="number" name="pace" step="0.01" min="0" value={ssexercise.sets[index].pace} placeholder="Pace" onChange={(e) => alterSuperset("detail", exercise.id, ssexercise.id, ssexercise.sets[index].id, e)} />
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                        {ssexercise.sets[index].type === "dropset" &&
                                                                            <div className="guide-set__main dropset">
                                                                                {ssexercise.sets[index].dropset && ssexercise.sets[index].dropset.map((dropset: any, di: any) => 
                                                                                    <div className="dropset-item" key={di}>
                                                                                        <p>{di + 1}</p>
                                                                                        <div className="form-item">
                                                                                            <label>Reps</label>
                                                                                            <input type="number" name="reps" value={dropset.reps} placeholder="Reps" onChange={(e) => alterSuperset("editDropSet", exercise.id, ssexercise.id, ssexercise.sets[index].id, e, "", dropset.id)} />
                                                                                        </div>
                                                                                        <div className="form-item">
                                                                                            <label>Weight (kg)</label>
                                                                                            <input type="number" name="weight" step="0.01" min="0" value={dropset.weight} placeholder="Weight (optional)" onChange={(e) => alterSuperset("editDropSet", exercise.id, ssexercise.id, ssexercise.sets[index].id, e, "", dropset.id)} />
                                                                                        </div>
                                                                                        <button className="icon-btn" onClick={(e) => alterSuperset("deleteDropSet", exercise.id, ssexercise.id, ssexercise.sets[index].id, e, "", dropset.id)}>
                                                                                            <svg>
                                                                                                <use xlinkHref="/assets/icon-library.svg#trash" />
                                                                                            </svg>
                                                                                        </button>
                                                                                    </div>  
                                                                                )}
                                                                                <button className="guide-add extra-small" onClick={() => alterSuperset("addDropSet", exercise.id, ssexercise.id, ssexercise.sets[index].id)}>Add Dropset +</button>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                </>
                                                            )}
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                            <button className="guide-add small" onClick={() => setPicker({exercises: [], open: true})}>Add Exercise +</button>
                            </div>
                        </div>
                    </div>
                    <div className="action-bar mt-1">
                        <button className="btn" onClick={id ? () => updateWorkout() : () => saveWorkout()}>{id ? 'Update' : 'Save'}</button>
                    </div>
                </div>
            }
            <div className={`overlay ${picker.open ? 'is-active' : ''}`} onClick={() => setPicker({exercises: [], open: false})}></div>
            <div className={`modal ${picker.open ? 'is-open' : ''}`}>
                <h2>Select exercises</h2>
                <div className="video-library">
                    {exercises && exercises.length > 0 ?
                        <>
                        {exercises.map((exercise, index) =>
                            <div className={`video-library-item ${picker.exercises.includes(exercise._id) ? 'is-selected': ''}`} key={index} onClick={() => handeExerciseSelect(exercise._id)}>
                                <div className="video-library-item__image">
                                    {/* <img src="https://unsplash.it/1920/1080" /> */}
                                    {/* <iframe src={`https://player.vimeo.com/video/${exercise.video}?muted=1`} frameBorder="0" allow="autoplay; fullscreen"></iframe> */}
                                </div>
                                <div className="video-library-item__title">{exercise.name}</div>
                            </div>   
                        )}
                        </>
                    :
                        <p>No exercises found.</p>
                    }
                </div>
                <div className="action-bar">
                    <button className="btn" onClick={() => setPicker({exercises: [], open: false})}>Cancel</button>
                    <button className="btn" onClick={picker.superset ? () => alterExercise("superset", picker.exerciseid, picker.exercises) : () => alterExercise("add", null, picker.exercises)}>Add +</button>
                </div>
            </div>
        </>
    );
}

export default ChallengeDayEditor;