import React, { useContext, useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import PremiumOnly from '../PremiumOnly/PremiumOnly';
import Header from '../../Components/Header/Header';
import { GlobalContext } from '../../Contexts/Global/GlobalContext';
import { toast } from 'react-toastify';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import ProgressSubNav from '../../Components/SubNavs/ProgressSubNav';
import DatePicker from 'react-datepicker';

/* TODO: CLEAN UP THIS CODE WITH TYPESCRIPT PRINCIPLES (INTERFACES, TYPES ETC) */

interface IProgressProps {
    history: any
}

interface IGlobalContext {
    setLoading?: Function
}

interface IDelete {
    id: string,
    open: boolean
}

const Progress = ({ history }: IProgressProps) => {
    const globalVariables: any = window;
    const globalContext = useContext<IGlobalContext>(GlobalContext);
    const [dates, setDates] = useState<any>(undefined);
    const [progress, setProgress] = useState<any>(undefined);
    const [lightboxIndex, setLightboxIndex] = useState<number>(0);
    const [lightboxOpen, setLightboxOpen] = useState<boolean>(false);
    const [deleteModal, setDeleteModal] = useState<IDelete>({id: "", open: false});
    // const [month, setMonth] = useState<number>(parseInt(moment().format("m")));
    // const year = 2020;
    const [selectedDate, setSelectedDate] = useState<any>(new Date());

    const getProgress = async () => {
        globalContext.setLoading && globalContext.setLoading(true);

        let month = moment(selectedDate).format("M"),
            year = moment(selectedDate).format("YYYY");

        await fetch(`${globalVariables.api}/progress/month/${month}/year/${year}`, {
            method: 'GET',
            credentials: 'include',
            mode: 'cors',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            })
        })
        .then(async (res) => {
            let data = await res.json();

            if(res.status === 200) {
                let manipulate = [...data],
                    dates: any[] = [];

                manipulate.forEach(item => {
                    item.date = moment(item.date).format("DD-MM-YYYY");
                    dates.push(item.date);
                });

                dates = dates.filter((v, i, a) => a.indexOf(v) === i);

                data.forEach((item: any, index: any) => {
                    item.order = index;
                    item.image = `${globalVariables.api}/media/progress/${item.image}`;
                });

                setDates(dates); 
                setProgress(data);
                globalContext.setLoading && globalContext.setLoading(false);
            } else if (res.status === 401) {
                window.location.href = "/";
            } else {
                globalContext.setLoading && globalContext.setLoading(false);
                toast.error("Error fetching your progress pictures. Please try again.");
            }
        })
        .catch(err => {
            toast.error("Error fetching your progress pictures. Please try again.");
        });
    }

    const fetchProgress = useCallback(async () => {
        getProgress();
    }, [selectedDate]);

    const handleFileUpload = async (e: any) => {
        globalContext.setLoading && globalContext.setLoading(true);
        let items = e.target.files.length;

        let formData = new FormData();
        
        for(let i = 0; i < items; i++) {
            let date = new Date();
            formData.append('images', e.target.files[i]);
            formData.append('date', date.toUTCString());
        }

        await fetch(`${globalVariables.api}/media/progress`, {
            method: 'POST',
            credentials: 'include',
            mode: 'cors',
            body: formData
        })
        .then(async res => {
            if(res.status === 200) {
                setTimeout(() => {
                    getProgress();
                }, 3000);
            } else if (res.status === 401) {
                window.location.href = "/";
            } else {
                globalContext.setLoading && globalContext.setLoading(false);
                console.log(await res.json());
            }
        })
        .catch(err => {
            console.log(err);
        });
    }

    useEffect(() => {
        fetchProgress();
    }, [fetchProgress, selectedDate]);

    const openLightBox = (e: any, order: any) => {
        if(e.target === e.currentTarget) {
            setLightboxIndex(order);
            setLightboxOpen(true);
        }
    }

    const progressDeletion = async () => {
        globalContext.setLoading && globalContext.setLoading(true);

        await fetch(`${globalVariables.api}/progress/${deleteModal.id}`, {
            method: 'DELETE',
            credentials: 'include',
            mode: 'cors'
        })
        .then(async res => {
            if(res.status === 200) {
                globalContext.setLoading && globalContext.setLoading(false);
                let manipulate = [...progress].filter(item => item._id !== deleteModal.id),
                    dates: any[] = [];

                manipulate.forEach(item => {
                    dates.push(item.date);
                });

                dates = dates.filter((v, i, a) => a.indexOf(v) === i);

                setDates(dates);
                setProgress(manipulate);
                setDeleteModal({id: "", open: false});
            } else if (res.status === 401) {
                window.location.href = "/";
            } else {
                globalContext.setLoading && globalContext.setLoading(false);
                toast.error("There was an issue deleting this picture. Please try again.");
            }
        })
        .catch(err => {
            globalContext.setLoading && globalContext.setLoading(false);
            toast.error("There was an issue deleting this picture. Please try again.");
        });
    }

    return (
        <PremiumOnly history={history}>
            <Header title="Progress" />
            <ProgressSubNav />
            <div className="home-container">
                <div className="upload-box">
                    <div className="upload-box__inner">
                        <svg>
                            <use xlinkHref="/assets/icon-library.svg#upload" />
                        </svg>
                        <p className="upload-box__title">Upload Progress Pictures</p>
                    </div>
                    <input type="file" name="image" multiple onChange={(e) => handleFileUpload(e)} />
                </div>
                <div className="upload-library">
                    <div className="upload-library__header">
                        <h2>My Uploads</h2>
                        <DatePicker
                            selected={selectedDate}
                            onChange={(date) => setSelectedDate(date)}
                            dateFormat="MMMM yyyy"
                            minDate={moment("Thu, 30 Apr 2020 23:59:24 +0000").toDate()}
                            maxDate={moment().toDate()}
                            showMonthYearPicker
                            withPortal />
                        {/* <select value={month} onChange={(e) => setMonth(parseInt(e.target.value))}>
                            <option value="5">May</option>
                            <option value="6">June</option>
                            <option value="7">July</option>
                            <option value="8">August</option>
                            <option value="9">September</option>
                            <option value="10">October</option>
                            <option value="11">November</option>
                        </select> */}
                    </div>
                    {dates && dates.length === 0 && <p>Your progress pictures will display here.</p>}
                    {dates && dates.map((date: any, index: any) =>
                        <div className="upload-library__section" key={index}>
                            <h3>{moment(date, "DD-MM-YYYY").format('Do MMMM YYYY')}</h3>
                            <div className="upload-library__grid">
                            {progress && progress.map((item: any, i: any) => {
                                if(item.date === date) {
                                    return <div className="upload-library__item" key={i} onClick={(e) => openLightBox(e, item.order)}>
                                                <img src={`${globalVariables.api}/media/progress/${item.thumbnail}`} onClick={(e) => openLightBox(e, item.order)} />
                                                <button className="delete" onClick={((e) => { e.preventDefault(); setDeleteModal({id: item._id, open: true})})}>
                                                    <svg>
                                                        <use xlinkHref="/assets/icon-library.svg#trash" />
                                                    </svg>
                                                </button>
                                            </div>
                                }
                            }
                            )}
                            </div>
                        </div>
                    )}
                </div>
                {lightboxOpen && 
                    <Lightbox 
                        mainSrc={progress.map((item: any) => item.image)[lightboxIndex]}
                        nextSrc={progress.map((item: any) => item.image)[(lightboxIndex + 1) % progress.map((item: any) => item.image).length]}
                        prevSrc={progress.map((item: any) => item.image)[(lightboxIndex + progress.map((item: any) => item.image).length - 1) % progress.map((item: any) => item.image).length]}
                        onCloseRequest={() => setLightboxOpen(false)}
                        onMovePrevRequest={() =>
                        setLightboxIndex((lightboxIndex + progress.map((item: any) => item.image).length - 1) % progress.map((item: any) => item.image).length)
                        }
                        onMoveNextRequest={() =>
                        setLightboxIndex((lightboxIndex + 1) % progress.map((item: any) => item.image).length)
                        } />}
            </div>
            <div className={`overlay ${deleteModal.open ? 'is-active' : ''}`} onClick={() => setDeleteModal({id: "", open: false})}></div>
            <div className={`modal ${deleteModal.open ? 'is-open' : ''}`}>
                <h2>Are you sure you want to delete this picture?</h2>
                <div className="modal__actions">
                    <button className="btn btn--text" onClick={() => setDeleteModal({id: "", open: false})}>Cancel</button>
                    <button className="btn btn--red" onClick={() => progressDeletion()}>Delete</button>
                </div>
            </div>
        </PremiumOnly>
    )
}

export default Progress;