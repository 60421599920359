import React, { useState, useEffect } from 'react';
import Icon from '../../Components/Icon/Icon';
const countdown = require('../../countdown-beep.mp3');
const iconLibrary = require('../../icon-library.svg');

interface ILoggingTimerBarProps {
    time: number | string | any
}

const LoggingTimerBar = ({ time }: ILoggingTimerBarProps) => {
    const [seconds, setSeconds] = useState<number>(time * 60);
    const [current, setCurrent] = useState<number>(0);
    const [started, setStarted] = useState<boolean>(false);
    const [complete, setComplete] = useState<boolean>(false);

    const start = () => {
        setStarted(true);
        setComplete(false);
        setCurrent(0);
        startTimer();
    }

    const startTimer = (time = 0) => {
        time = time + 1;
        setCurrent(time);

        if(time < seconds) {
            setTimeout(() => {
                startTimer(time);
            }, 1000);
    
            if((seconds - time) === 3) {
                const audio: any = document.getElementsByClassName("audio")[0];
    
                audio && audio.play();
            }
        } else {
            setComplete(true);
        }
    }

    const reset = (time: any) => {
        setSeconds(time);
        setCurrent(0);
        setStarted(false);
        setComplete(false);
    }

    useEffect(() => {
        return () => {
            console.log(time);
        }
    }, [time]);

    return (
        <div className="timer-bar">
            <div className="timer-bar__container">
                <div className={`timer-bar__progress ${complete ? 'is-complete' : ''}`} style={{width: `${(100 / seconds) * current}%`}}>

                </div>
            </div>
            <div className="timer-bar__action">
                {started ?
                <button className="icon-btn" onClick={complete ? () => {start();} : () => {}}>
                    <Icon icon="restart" />
                </button>    
                :
                <button className="icon-btn" onClick={() => start()}>
                    <Icon icon="play" />
                </button>
                }
            </div>
            <audio className="audio">
                <source src={countdown}></source>
            </audio>
        </div>
    );
}

export default LoggingTimerBar;