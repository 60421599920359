import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import ChallengeScheduleItem from './ChallengeScheduleItem';

interface IChallenge {
    _id: string,
    name: string,
    slug: string,
    duration: number,
    description: string,
    image: string,
    startDate: Date,
    enrollBy: Date,
    weeks: any[]
}

const ChallengeSchedule = () => {
    const globalVariables: any = window;
    const [participating, setParticipating] = useState<any>(undefined);
    const [challenges, setChallenges] = useState<IChallenge[] | undefined>(undefined);

    const fetchChallengeData: Function = useCallback(async () => {
        await fetch(`${globalVariables.api}/workout/challenge/me`, {
            method: 'GET',
            credentials: 'include',
            mode: 'cors',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            })
        })
        .then(async (res) => {
            let data = await res.json();

            if(res.status === 200) {
                let active: any = [];
                for(let i = 0; i < data.challenges.length; i++) {
                    let start = moment(data.challenges[i].startDate),
                    // let start = moment().subtract(1, "days"),
                        end = moment(start).add(data.challenges[i].duration, "weeks"),
                        now = moment();

                        // active.push(data.challenges[i]);
                    if(now.isAfter(start) && now.isBefore(end)) {
                        active.push(data.challenges[i]);
                    }
                }

                setParticipating(data.participating);
                setChallenges(active);
            } else if (res.status === 404) {
                return;
            }
        })
        .catch(err => {
            console.log(err);
        });
    }, []);

    const removeChallengeItem: Function = async (id: string) => {
        if(challenges) {
            await fetch(`${globalVariables.api}/workout/challenge/remove/${id}`, {
                method: 'POST',
                credentials: 'include',
                mode: 'cors',
                headers: new Headers({
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                })
            })
            .then(async (res) => {
                let data = await res.json();
    
                if(res.status === 200) {
                    let partItem = participating.filter((item: any) => item._id === id)[0];

                    let items = [...challenges];

                    items = items.filter((item: any) => item._id !== partItem.cid);
            
                    setChallenges(items);
                } else if (res.status === 404) {
                    return;
                }
            })
            .catch(err => {
                console.log(err);
            });
        }
    }

    useEffect(() => {
        fetchChallengeData();
    }, []);

    return (
        <>
        {challenges && challenges.map((challenge: IChallenge, index: number) =>
            <ChallengeScheduleItem challenge={challenge} removeChallengeItem={(id: string) => removeChallengeItem(id)} participant={participating.filter((item: any) => item.cid === challenge._id)[0]} key={index} />
        )    
        }
        </>
    );
}

export default ChallengeSchedule;