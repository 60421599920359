import React, { useEffect, useCallback, useState } from 'react';
import Header from '../../Components/Header/Header';

interface IKnowledge {
    _id: string,
    title: string,
    slug: string,
    file?: string,
    content?: string,
    date: Date,
    image?: string,
    type: string
}

const KnowledgeBase = () => {
    const [knowledgeItems, setKnowledgeItems] = useState<IKnowledge[] | undefined>(undefined);
    const globalVariables: any = window;

    const fetchKnowledgeItems = useCallback(async () => {
        await fetch(`${globalVariables.api}/knowledge`, {
            method: 'GET',
            credentials: 'include',
            mode: 'cors',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            })
        })
        .then(async (res) => {
            let data = await res.json();

            if(res.status === 200) {
                setKnowledgeItems(data.reverse());
            } else if (res.status === 401) {
                window.location.href = "/";
            } else {
                // setIsLoggedIn(false);
                // setInitialised(true);
                // setLoading(false);
            }
        })
        .catch(err => {
            console.log(err);
            // setIsLoggedIn(false);
            // setInitialised(true);
        });
    }, []);

    useEffect(() => {
        fetchKnowledgeItems();
    }, [fetchKnowledgeItems]);

    return (
        <>
            <Header title="Knowledge Base" />
            <div className="home-container">
                <div className="video-library blog">
                    {knowledgeItems && knowledgeItems.map((item, index) => 
                        <a key={index} href={`${globalVariables.api}/media/${item.file}`} target="_blank" className="video-library-item">
                            <div className="video-library-item__image">
                                <img src={`${globalVariables.api}/media/${item.image}`} alt={item.title} />
                            </div>
                            <div className="video-library-item__title">
                                {item.title}
                            </div>
                        </a>
                    )}
                </div>
            </div>
        </>
    );
}

export default KnowledgeBase;