import React, { useState, useEffect, useCallback } from 'react';
import Header from '../../Components/Header/Header';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

interface IRecipe {
    _id?: string,
    name: string,
    slug: string,
    ingredients: Array<string>,
    directions: Array<string>,
    kcals: number,
    protein: number,
    carbohydrates: number,
    fats: number,
    time: number,
    prepTime?: number,
    servings?: number,
    image?: string,
    category?: Array<string>,
    [x:string]: any
}

const RecipeEditor = () => {
    const globalVariables: any = window;
    const [recipe, setRecipe] = useState<any | undefined>(undefined);
    const { slug } = useParams();

    const fetchRecipe = useCallback(async () => {
        await fetch(`${globalVariables.api}/recipe/slug/${slug}`, {
            method: 'GET',
            credentials: 'include',
            mode: 'cors',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            })
        })
        .then(async (res) => {
            let data = await res.json();

            if(res.status === 200) {
                setRecipe(data);
            } else if (res.status === 401) {
                window.location.href = "/";
            } else {
                alert("Error");
                console.log(res);
            }
        })
        .catch(err => {
            console.log(err);
            alert("error");
        });
    }, []);

    const handleChange = (e: any, type: any = null) => {
        if(type) {
            switch(type) {
                case "ingredient":
                    let ingredients = [...recipe.ingredients];
                    ingredients[parseInt(e.target.name)] = e.target.value;
                    setRecipe({...recipe, ingredients: ingredients});
                    break;
                case "direction":
                    let directions = [...recipe.directions];
                    directions[parseInt(e.target.name)] = e.target.value;
                    setRecipe({...recipe, directions: directions});
                    break;
                case "category":
                    let categorys = [...recipe.category];
                    categorys[parseInt(e.target.name)] = e.target.value;
                    setRecipe({...recipe, category: categorys});
                    break;
                case "addIngredient":
                    let ingredientList = [...recipe.ingredients];
                    ingredientList.push("");
                    setRecipe({...recipe, ingredients: ingredientList});
                    break;
                case "addDirection":
                    let directionList = [...recipe.directions];
                    directionList.push("");
                    setRecipe({...recipe, directions: directionList});
                    break;
                case "addCategory":
                    let categoryList = [...recipe.category];
                    categoryList.push("");
                    setRecipe({...recipe, category: categoryList});
                    break;
            }
        } else {
            let kcals: any = undefined;
            if(e.target.name === "protein") {
                kcals = (recipe.carbohydrates * 4) + (recipe.fats * 9) + (e.target.value * 4);
            } else if (e.target.name === "carbohydrates") {
                kcals = (recipe.protein * 4) + (recipe.fats * 9) + (e.target.value * 4);
            } else if (e.target.name === "fats") {
                kcals = (recipe.carbohydrates * 4) + (recipe.protein * 4) + (e.target.value * 9);
            }
            if(kcals) {
                setRecipe({...recipe, [e.target.name]: e.target.value, kcals: kcals});
            } else {
                setRecipe({...recipe, [e.target.name]: e.target.value});
            }
        }
    }

    const uploadImage = async (e: any) => {
        let items = e.target.files.length;

        let formData = new FormData();
        
        for(let i = 0; i < items; i++) {
            formData.append('images', e.target.files[i]);
        }

        await fetch(`${globalVariables.api}/media/image`, {
            method: 'POST',
            credentials: 'include',
            mode: 'cors',
            body: formData
        })
        .then(async res => {
            let data = await res.json();
            if(res.status === 200) {
                setRecipe({...recipe, image: data.files});
            } else if (res.status === 401) {
                window.location.href = "/";
            } else {
                console.log(await res.json());
            }
        })
        .catch(err => {
            console.log(err);
        });
    }

    const updateRecipe = async () => {
        await fetch(`${globalVariables.api}/recipe/${slug}`, {
            method: 'PUT',
            credentials: 'include',
            mode: 'cors',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }),
            body: JSON.stringify({...recipe})
        })
        .then(res => {
            if(res.status === 200) {
                return res.json();
            } else if (res.status === 401) {
                window.location.href = "/";
            } else {
                console.log(res);
            }
        })
        .then(res => {
            toast.success("Recipe Updated");
        })
        .catch(err => {
            console.log(err);
        });
    }

    const saveRecipe = async () => {
        await fetch(`${globalVariables.api}/recipe`, {
            method: 'POST',
            credentials: 'include',
            mode: 'cors',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }),
            body: JSON.stringify({...recipe})
        })
        .then(res => {
            if(res.status === 200) {
                return res.json();
            } else if (res.status === 401) {
                window.location.href = "/";
            } else {
                console.log(res);
            }
        })
        .then(res => {
            toast.success("Recipe Saved");
        })
        .catch(err => {
            console.log(err);
        });
    }

    useEffect(() => {
        if(slug) {
            fetchRecipe();
        } else {
            setRecipe({
                _id: "",
                name: "",
                slug: "",
                ingredients: [],
                directions: [],
                kcals: 0,
                protein: 0,
                carbohydrates: 0,
                fats: 0,
                time: 0,
                prepTime: 0,
                servings: 1,
                image: "",
                category: []
            });
        }
    }, [fetchRecipe]);

    return (
        <>
            <Header title="Edit" />
            <div className="home-container">
                {recipe && 
                    <>
                    <div className="image-editor">
                        <img src={`${globalVariables.api}/media/${recipe.image}`} />
                        <input type="file" name="image" onChange={(e) => uploadImage(e)} />
                    </div>
                    <div className="form-item">
                        <label>Name</label>
                        <input type="text" name="name" value={recipe.name} onChange={(e) => handleChange(e)} />
                    </div>
                    <div className="form-item">
                        <label>Kcals</label>
                        <input type="number" name="kcals" value={recipe.kcals} />
                    </div>
                    <div className="form-item">
                        <label>Protein</label>
                        <input type="number" name="protein" value={recipe.protein} onChange={(e) => handleChange(e)} />
                    </div>
                    <div className="form-item">
                        <label>Carbs</label>
                        <input type="number" name="carbohydrates" value={recipe.carbohydrates} onChange={(e) => handleChange(e)} />
                    </div>
                    <div className="form-item">
                        <label>Fats</label>
                        <input type="number" name="fats" value={recipe.fats} onChange={(e) => handleChange(e)} />
                    </div>
                    <div className="form-item">
                        <label>Prep Time</label>
                        <input type="number" name="prepTime" value={recipe.prepTime} onChange={(e) => handleChange(e)} />
                    </div>
                    <div className="form-item">
                        <label>Cook Time</label>
                        <input type="number" name="time" value={recipe.time} onChange={(e) => handleChange(e)} />
                    </div>
                    <div className="form-item">
                        <label>Servings</label>
                        <input type="number" name="servings" value={recipe.servings} onChange={(e) => handleChange(e)} />
                    </div>
                    <div className="form-item">
                        <label>Ingredients</label>
                        {recipe.ingredients.map((ingredient: any, index: any) =>
                            <input type="text" name={index} value={ingredient} key={index} onChange={(e) => handleChange(e, "ingredient")} />
                        )}
                        <button className="icon-btn" onClick={(e) => handleChange(e, "addIngredient")}>+</button>
                    </div>
                    <hr />
                        <p>°</p>
                    <hr />
                    <div className="form-item">
                        <label>Directions</label>
                        {recipe.directions.map((direction: any, index: any) =>
                            <input type="text" name={index} value={direction} key={index} onChange={(e) => handleChange(e, "direction")} />
                        )}
                        <button className="icon-btn" onClick={(e) => handleChange(e, "addDirection")}>+</button>
                    </div>
                    <hr />
                    <div className="form-item">
                        <label>Categories</label>
                        {recipe.category.map((cat: any, index: any) =>
                            <input type="text" name={index} value={cat} key={index} onChange={(e) => handleChange(e, "category")} />
                        )}
                        <button className="icon-btn" onClick={(e) => handleChange(e, "addCategory")}>+</button>
                    </div>
                    <hr />
                    <div className="form-item">
                        <label>Tip</label>
                        <input type="text" name="tip" value={recipe.tip} onChange={(e) => handleChange(e)} />
                    </div>
                    <br />
                    <button className="btn btn--green" onClick={slug ? () => updateRecipe() : () => saveRecipe()}>{slug ? 'Update' : 'Save'}</button>
                    </>
                }
            </div>
        </>
    );
}

export default RecipeEditor;