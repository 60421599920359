import React, { useContext, useState, useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useParams, Link } from 'react-router-dom';
import moment from 'moment';

// Components
import Header from '../../Components/Header/Header';

// Contaiers
import { GlobalContext } from '../../Contexts/Global/GlobalContext';

interface IGlobalContext {
    setLoading?: Function
}

interface IChallenge {
    _id?: string,
    name: string,
    slug?: string,
    duration?: number,
    description?: string,
    image?: string,
    startDate?: Date,
    enrollBy?: Date,
    weeks?: any[]
}

interface IWeek {
    _id: string,
    days: any[],
    checkin: boolean,
    progress: boolean
}

const ChallengeEditor = () => {
    const globalVariables: any = window;
    const globalContext: IGlobalContext = useContext(GlobalContext);
    const [challenge, setChallenge] = useState<IChallenge | undefined>(undefined);
    const [weeks, setWeeks] = useState<IWeek[] | undefined>(undefined);

    const { slug } = useParams();

    const fetchChallenge = useCallback(async() => {
        globalContext.setLoading && globalContext.setLoading(true);
        await fetch(`${globalVariables.api}/workout/challenge/slug/${slug}`, {
            method: 'GET',
            credentials: 'include',
            mode: 'cors',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            })
        })
        .then(async (res) => {
            let data = await res.json();

            if(res.status === 200) {
                setChallenge(data);
                globalContext.setLoading && globalContext.setLoading(false);
            } else {
                toast.error("There was an issue fetching challenge.");
                globalContext.setLoading && globalContext.setLoading(false);
            }
        })
        .catch(err => {
            console.log(err);
            toast.error("There was an issue fetching challenge.");
            globalContext.setLoading && globalContext.setLoading(false);
        });
    }, []);

    const fetchChallengeWeeks = useCallback(async() => {
        if(challenge) {
            globalContext.setLoading && globalContext.setLoading(true);
            await fetch(`${globalVariables.api}/workout/challenge/week/${challenge._id}`, {
                method: 'GET',
                credentials: 'include',
                mode: 'cors',
                headers: new Headers({
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                })
            })
            .then(async (res) => {
                let data = await res.json();
    
                if(res.status === 200) {
                    setWeeks(data);
                    globalContext.setLoading && globalContext.setLoading(false);
                } else {
                    toast.error("There was an issue fetching challenge.");
                    globalContext.setLoading && globalContext.setLoading(false);
                }
            })
            .catch(err => {
                console.log(err);
                toast.error("There was an issue fetching challenge.");
                globalContext.setLoading && globalContext.setLoading(false);
            });
        }
    }, [challenge]);

    const addWeek = async () => {
        if(challenge) {
            await fetch(`${globalVariables.api}/workout/challenge/week`, {
                method: 'POST',
                credentials: 'include',
                mode: 'cors',
                headers: new Headers({
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }),
                body: JSON.stringify({cid: challenge._id, checkin: true, progress: true, days: []})
            })
            .then(async (res) => {
                let data = await res.json();
    
                if(res.status === 200) {
                    if (weeks) {
                        let weeksData = [...weeks];
                        weeksData.push(data);
                        setWeeks(weeksData);
                    }
                    globalContext.setLoading && globalContext.setLoading(false);
                } else {
                    toast.error("There was an issue adding day to challenge.");
                    globalContext.setLoading && globalContext.setLoading(false);
                }
            })
            .catch(err => {
                console.log(err);
                toast.error("There was an issue adding day to challenge.");
                globalContext.setLoading && globalContext.setLoading(false);
            });
        }
    }

    const addDay = async (weekid: string) => {
        if(challenge) {
            await fetch(`${globalVariables.api}/workout/challenge/day`, {
                method: 'POST',
                credentials: 'include',
                mode: 'cors',
                headers: new Headers({
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }),
                body: JSON.stringify({wid: weekid, type: "workout", data: [], video: "", name: ""})
            })
            .then(async (res) => {
                let data = await res.json();
    
                if(res.status === 200) {
                    if (weeks) {
                        let weeksData = [...weeks];
                        for(let i = 0; i < weeksData.length; i++) {
                            weeksData[i].days.push(data._id);
                        }
                    }
                    globalContext.setLoading && globalContext.setLoading(false);
                } else {
                    toast.error("There was an issue adding week to challenge.");
                    globalContext.setLoading && globalContext.setLoading(false);
                }
            })
            .catch(err => {
                console.log(err);
                toast.error("There was an issue adding week to challenge.");
                globalContext.setLoading && globalContext.setLoading(false);
            });
        }
    }

    useEffect(() => {
        fetchChallenge();
    }, [fetchChallenge]);

    useEffect(() => {
        if(challenge) {
            fetchChallengeWeeks();
        }
    }, [challenge]);

    return (
        <>
            <Header title="Challenge Editor" />
            <div className="home-container">
                {challenge &&
                    <>
                        <h1 className="mb-1">{challenge.name}</h1>
                        <p><b>Start Date: </b>{moment(challenge.startDate).format("Do MMMM YYYY")}</p>
                        <p><b>Sign-up Deadline: </b>{moment(challenge.enrollBy).format("Do MMMM YYYY")}</p>
                        <hr />
                        <br />
                        <h2 className="mb-1">Challenge Weeks</h2>
                        {weeks && weeks.length > 0 ?
                            <>
                                {weeks.map((week, index) =>
                                <>
                                    <div className="select-tabs mb-1">
                                        <p><b>Week {index + 1}</b></p>
                                        <button className="btn ml-auto" onClick={() => addDay(week._id)}>Add Day +</button>
                                    </div>
                                    <hr />
                                    {week.days.length > 0 && week.days.map((day, i) =>
                                        <div className="action-bar mb-1">
                                            <p>{day.name && day.name !== "" ? day.name : `Day ${i + 1}`}</p>
                                            <Link to={`/challenge-day/${day}`} className="btn ml-auto">Edit</Link>
                                        </div>
                                    )}
                                </>
                                )}
                            </>
                        :
                            <p>No Weeks Yet.</p>
                        }
                        <button className="btn" onClick={() => addWeek()}>Add Week +</button>
                    </>
                }
            </div>
        </>
    );
}

export default ChallengeEditor;