import React, { useContext } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

// Contexts
import { GlobalContext } from '../../Contexts/Global/GlobalContext';

// Layouts
import MainLayout from '../../Layouts/MainLayout/MainLayout';

// Containers
import Home from '../../Containers/Home/Home';
import Workout from '../../Containers/Workout/Workout';
import FavouriteWorkouts from '../../Containers/FavouriteWorkouts/FavouriteWorkouts';
import FavouriteRecipes from '../../Containers/FavouriteRecipes/FavouriteRecipes';
import WorkoutCategory from '../../Containers/WorkoutCategory/WorkoutCategory';
import WorkoutDetail from '../../Containers/WorkoutDetail/WorkoutDetail';
import KnowledgeBase from '../../Containers/KnowledgeBase/KnowledgeBase';
import Login from '../../Containers/Login/Login';
import Register from '../Register/Register';
import Upgrade from '../Upgrade/Upgrade';
import Recipes from '../Recipes/Recipes';
import Recipe from '../Recipe/Recipe';
import Nutrition from '../Nutrition/Nutrition';
import CheckIn from '../CheckIn/CheckIn';
import Progress from '../Progress/Progress';
import Guides from '../Guides/Guides';
import GuideCreator from '../GuideCreator/GuideCreator';
import GuideDetail from '../GuideDetail/GuideDetail';
import Logging from '../Logging/Logging';
import LoggingSingle from '../LoggingSingle/Logging';
import ActiveWorkoutPopover from '../../Components/ActiveWorkoutPopover/ActiveWorkoutPopover';
import ForgotPassword from '../ForgotPassword/ForgotPassword';
import ForgotPasswordUpdate from '../ForgotPassword/ForgotPasswordUpdate';
import RecipesCategory from '../RecipesCategory/RecipesCategory';
import Profile from '../Profile/Profile';
import NotFound from '../NotFound/NotFound';
import RecipeEditorList from '../RecipeEditor/RecipeEditorList';
import RecipeEditor from '../RecipeEditor/RecipeEditor';
import TermsPopUp from '../../Components/TermsPopUp/TermsPopUp';
import LogBook from '../LogBook/LogBook';
import LogBookEntry from '../LogBook/LogBookEntry';
import RecipesCalories from '../Recipes/RecipesCalories';
import ChallengeDetail from '../ChallengeDetail/ChallengeDetail';
import ChallengeManager from '../ChallengeManager/ChallengeManager';
import ChallengeEditor from '../ChallengeEditor/ChallengeEditor';
import ChallengeDayEditor from '../ChallengeDayEditor/ChallengeDayEditor';
import ChallengeLogging from '../ChallengeLogging/Logging';
import ChallengeResult from '../ChallengeResult/ChallengeResult';
import WorkoutEditor from '../WorkoutEditor/WorkoutEditor';

interface IGlobalContext {
    state?: any
}

const AuthRouter = () => {
    const globalContext: IGlobalContext = useContext(GlobalContext);

    return (
        <>
        {globalContext.state.initialised &&
            <>
                {globalContext.state.isLoggedIn ?
                    <>
                        <MainLayout>
                            <Switch>
                                <Route path="/" exact component={Home} />
                                <Route path="/workout" exact component={WorkoutCategory} />
                                <Route path="/workout/:category" exact component={Workout} />
                                <Route path="/favourites/workout" exact component={FavouriteWorkouts} />
                                <Route path="/favourites/recipes" exact component={FavouriteRecipes} />
                                <Route path="/workout/detail/:slug" exact component={WorkoutDetail} />
                                <Route path="/knowledge" exact component={KnowledgeBase} />
                                <Route path="/nutrition" exact component={Nutrition} />
                                <Route path="/recipes" exact component={RecipesCategory} />
                                <Route path="/recipes/:category" exact component={Recipes} />
                                <Route path="/recipes/calories/:calories" exact component={RecipesCalories} />
                                <Route path="/recipes/detail/:slug" exact component={Recipe} />
                                <Route path="/check-in" exact component={CheckIn} />
                                <Route path="/progress" exact component={Progress} />
                                <Route path="/plan" exact component={Guides} />
                                <Route path="/plan/detail/:slug" exact component={GuideDetail} />
                                <Route path="/challenge/:slug" exact component={ChallengeDetail} />
                                <Route path="/logging/:planid/:week/:dayid" exact component={Logging} />
                                <Route path="/logging/challenge/:dayid" exact component={ChallengeLogging} />
                                <Route path="/logging/single/:workoutslug" exact component={LoggingSingle} />
                                <Route path="/logbook" exact component={LogBook} />
                                <Route path="/logbook/entry/:entryid" exact component={LogBookEntry} />
                                <Route path="/go-premium" exact component={Upgrade} />
                                <Route path="/profile" exact component={Profile} />
                                {/* To be removed/disabled */}
                                <Route path="/guide-build/:slug?" exact component={GuideCreator} />
                                <Route path="/challenge-manage" exact component={ChallengeManager} />
                                <Route path="/challenge-manage/:slug" exact component={ChallengeEditor} />
                                <Route path="/challenge-day/:id" exact component={ChallengeDayEditor} />
                                <Route path="/recipeeditor" exact component={RecipeEditorList} />
                                <Route path="/recipeeditor/edit/:slug?" exact component={RecipeEditor} />
                                <Route path="/challenge-results" exact component={ChallengeResult} />
                                <Route path="/workout-build" exact component={WorkoutEditor} />
                                <Route component={NotFound} />
                            </Switch>
                            <ActiveWorkoutPopover />
                            <TermsPopUp />
                        </MainLayout>
                    </>
                :
                    <Switch>
                        <Route path="/" exact component={Login} />
                        <Route path="/register" exact component={Register} />
                        <Route path="/forgotten-password" exact component={ForgotPassword} />
                        <Route path="/reset/:code" exact component={ForgotPasswordUpdate} />
                        <Redirect to="/" />
                    </Switch>
                }
            </>
        }
        </>
    );
}

export default AuthRouter;