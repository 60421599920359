import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

// Contexts
import { GlobalContext } from '../../Contexts/Global/GlobalContext';

interface IGlobalContext {
    state?: any,
    setUser?: Function,
    setLoading?: Function
}

const ProfileCard = () => {
    const globalVariables: any = window;
    const globalContext: IGlobalContext = useContext(GlobalContext);

    const initialChar = (text: string) => {
        return text.substring(0, 1).toLowerCase();
    }

    const handleAvatarUpload = async (e: any) => {
        if(e.target.files.length > 0) {
            let formData = new FormData();

            formData.append('single_file', e.target.files[0]);
            
            globalContext.setLoading && globalContext.setLoading(true);

            await fetch(`${globalVariables.api}/user/avatar`, {
                method: 'PUT',
                credentials: 'include',
                mode: 'cors',
                body: formData
            })
            .then(res => {
                if(res.status === 200) {
                    return res.json();
                } else if (res.status === 401) {
                    window.location.href = "/";
                } else {
                    globalContext.setLoading && globalContext.setLoading(false);
                    toast.error("There was an issue updating avatar. Please try again.");
                }
            })
            .then(res => {
                globalContext.setLoading && globalContext.setLoading(false);
                toast.success("Profile picture updated.");
                globalContext.setUser && globalContext.setUser({...globalContext.state.user, avatar: res.avatar});
            })
            .catch(err => {
                globalContext.setLoading && globalContext.setLoading(false);
                toast.error("There was an issue updating avatar. Please try again.");
            });
        }
    }

    return (
        <div className="profile-card">
            <div className="profile-card__inner">
                <div className="profile-card__avatar">
                    {/* {globalContext.state && <span>{initialChar(globalContext.state.user.firstname) + "" + initialChar(globalContext.state.user.lastname)}</span>} */}
                    {globalContext.state && globalContext.state.user.avatar  ? 
                        <img src={`${globalVariables.api}/media/${globalContext.state.user.avatar}`} alt="User Avatar" />
                    :
                        <div className="profile-card__avatar-es">
                            <div>
                                <svg>
                                    <use xlinkHref="/assets/icon-library.svg#camera" />
                                </svg>
                                <p>+ Add Profile Picture</p>
                            </div>
                        </div>}
                    <input type="file" name="single" onChange={(e) => handleAvatarUpload(e)} />
                </div>
                <div className="profile-card__main">
                    <div className="profile-card__name">
                        <div>
                            <p>Hello</p>
                            <p>{globalContext.state && <>{globalContext.state.user.firstname + " " + globalContext.state.user.lastname}</>}</p>
                        </div>
                    </div>
                </div>
                <Link to="/profile" className="profile-card__action">
                    <p>My Profile</p>
                    <svg>
                        <use xlinkHref="/assets/icon-library.svg#arrow-right" />
                    </svg>
                </Link>
            </div>
        </div>
    );
}

export default ProfileCard;