import React from 'react';
import { NavLink } from 'react-router-dom';

const NutritionSubNav = () => {
    return (
        <>
            <ul className="tab-nav">
                <li className="tab-nav__item">
                    <NavLink to="/nutrition" strict exact activeClassName="is-active">Diary</NavLink>
                </li>
                <li className="tab-nav__item">
                    <NavLink to="/recipes" activeClassName="is-active">Recipes</NavLink>
                </li>
            </ul>
        </>
    );
}

export default NutritionSubNav;