import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../logo-white.svg';

// Contexts
import { GlobalContext } from '../../Contexts/Global/GlobalContext';

interface IGlobalContext {
    state?: any,
    setIsLoggedIn?: Function,
    setLoading?: Function,
    setInitialAppData?: Function
}

const Login = () => {
    const globalContext: IGlobalContext = useContext(GlobalContext);
    const [formData, setFormData] = useState({ email: "", password: "" });
    const [error, setError] = useState<string | undefined>(undefined);

    const handleInputChange = (e: any) => {
        setFormData({...formData, [e.target.name]: e.target.value});
    }

    const handleLogin = async (e: any) => {
        e.preventDefault();
        let globalVariables: any = window;
        globalContext.setLoading && globalContext.setLoading(true);

        if(formData.email !== "" && formData.password !== "") {
            await fetch(`${globalVariables.api}/auth/login`, {
                method: 'POST',
                credentials: 'include',
                mode: 'cors',
                headers: new Headers({
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }),
                body: JSON.stringify({...formData})
            })
            .then(res => {
                if(res.status === 200) {
                    globalContext.setInitialAppData &&  globalContext.setInitialAppData();
                } else if (res.status === 500) {
                    setError("Server error. Please try again.");
                    globalContext.setLoading && globalContext.setLoading(false);
                } else {
                    setError("Email address or password do not match.");
                    globalContext.setLoading && globalContext.setLoading(false);
                }
            })
            .catch(err => {
                setError("Server error. Please try again.");
                globalContext.setLoading && globalContext.setLoading(false);
            });
        } else {
            setError("Please enter valid details.");
            globalContext.setLoading && globalContext.setLoading(false);
        }
        
    }

    return (
        <div className="login-container">
            <div className="login-container__panel">
                <img className="login-container__logo" src={logo} />
            </div>
            <div className="login-container__form">
                <h1>Login</h1>
                <form onSubmit={(e) => handleLogin(e)}>
                    <div className="form-item">
                        <label>Email address</label>
                        <input type="email" name="email" value={formData.email} placeholder="Email address" onChange={(e) => handleInputChange(e)} />
                    </div>
                    <div className="form-item">
                        <label>Password</label>
                        <input type="password" name="password" value={formData.password} placeholder="Password" onChange={(e) => handleInputChange(e)} />
                        <Link to="/forgotten-password" className="form-input-link">Forgotten password?</Link>
                    </div>
                    {error && <div className="form-error">{error}</div>}
                    <button className="btn btn--block">
                        Sign In 
                    </button>
                    <Link className="btn btn--inverse btn--block" to="/register">Sign Up</Link>
                </form>
            </div>
        </div>
    );
}

export default Login;