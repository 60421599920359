import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { GlobalContext } from '../../Contexts/Global/GlobalContext';

interface IGlobalContext {
    state?: any,
    setUser?: Function
}

const TermsPopUp = () => {
    const globalVariables: any = window;
    const globalContext: IGlobalContext = useContext(GlobalContext);
    const [terms, setTerms] = useState(true);

    const acceptTerms = async () => {
        globalContext.setUser && globalContext.setUser({...globalContext.state.user, terms: true});
        await fetch(`${globalVariables.api}/user`, {
            method: 'PUT',
            credentials: 'include',
            mode: 'cors',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }),
            body: JSON.stringify({terms: true})
        })
        .then(res => {
            if(res.status === 200) {
                return res.json();
            } else if (res.status === 401) {
                window.location.href = "/";
            } else {
                console.log(res);
            }
        })
        .catch(err => {
            console.log(err);
        });
    }

    return (
        <>
        {globalContext.state && globalContext.state.user && !globalContext.state.user.terms &&
            <>
                <div className={`overlay level-2 ${terms ? 'is-active' : ''}`} onClick={() => acceptTerms()}></div>
                <div className={`modal level-2 ${terms ? 'is-open' : ''}`}>
                    <h2>Updated Terms & Conditions</h2>
                    <p>By continuing to use this you agree to the <a href="https://strongeryou.app/terms-and-conditions" target="_blank">Terms & Conditions</a></p>
                    <button className="btn btn--text btn--block" onClick={() => acceptTerms()}>Dismiss</button>
                </div>
            </>
        }
        </>
    );
}

export default TermsPopUp;