import React, { useContext } from 'react';
import { GlobalContext } from '../../Contexts/Global/GlobalContext';
import Upgrade from '../Upgrade/Upgrade';

interface IGlobalContext {
    state?: any
}

interface IPremiumOnlyProps {
    children?: any,
    history?: any
}

const PremiumOnly = ({ children, history }: IPremiumOnlyProps) => {
    const globalContext: IGlobalContext = useContext(GlobalContext);

    return (
        <>
            {globalContext.state && (globalContext.state.user.type === "premium" || globalContext.state.user.type === "admin") ?
                <>
                    {children}
                </>
            :
                <Upgrade history={history} />
            }
        </>
    );
}

export default PremiumOnly;