import React, { useContext, useState, useEffect, useCallback } from 'react';
import Carousel from 'react-multi-carousel';
import "react-multi-carousel/lib/styles.css";
import { Link } from 'react-router-dom';
import { GlobalContext } from '../../Contexts/Global/GlobalContext';
import FavouriteRecipes from '../../Containers/FavouriteRecipes/FavouriteRecipes';

interface IRecipe {
    _id: string,
    name: string,
    slug: string,
    ingredients: Array<string>,
    directions: Array<string>,
    kcals: number,
    protein: number,
    carbohydrates: number,
    fats: number,
    time: number,
    servings?: number,
    image?: string,
    category?: Array<string>,
    premium?: boolean
}

interface IGlobalContext {
    setLoading?: Function,
    state?: any,
    setFavourites?: Function
}

const FavouriteRecipesCarousel = () => {
    const globalVariables: any = window;
    const globalContext: IGlobalContext = useContext(GlobalContext);
    const [recipes, setRecipes] = useState<Array<IRecipe> | null>(null);
    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 10
          },
          desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 8
          },
          tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 5
          },
          mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 2
          }
    }

    const fetchRecipes = useCallback(async () => {
        await fetch(`${globalVariables.api}/recipe`, {
            method: 'GET',
            credentials: 'include',
            mode: 'cors',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            })
        })
        .then(async (res) => {
            let data = await res.json();

            if(res.status === 200) {
                let recipesToSave = data.slice(0, 5);
                setRecipes(recipesToSave);
            } else if (res.status === 401) {
                window.location.href = "/";
            } else {
                alert("Error");
                console.log(res);
            }
        })
        .catch(err => {
            console.log(err);
            alert("error");
        });
    }, []);

    useEffect(() => {
        fetchRecipes();
    }, [fetchRecipes]);

    return (
        <div className="recipe-carousel">
            {recipes && recipes.length > 0 &&
                <>
                <h2>
                    My Favourites
                    <Link to="/favourites/recipes">
                        View all
                        <svg>
                            <use xlinkHref="/assets/icon-library.svg#chevron-right" />
                        </svg>    
                    </Link>
                </h2>
                <Carousel responsive={responsive}
                        showDots={false}
                        arrows={false}
                        infinite={false}>
                {recipes.map((recipe, index) => {
                    let toLink = recipe.slug !== null ? `/recipes/detail/${recipe.slug}` : '/go-premium';

                    return <Link to={toLink} key={index} className="recipe-carousel-card small">
                                <img src={`${globalVariables.api}/media/${recipe.image}`} alt={recipe.name} />
                                <div className="recipe-carousel-card__overlay"></div>
                                <div className="recipe-carousel-card__title">{recipe.name}</div>
                                {recipe.category?.includes("vegan") &&
                                    <div className="vegan-icon">
                                        <span>V</span>
                                        <div className="vegan-icon__tooltip">
                                            Vegan Friendly
                                        </div>
                                    </div>
                                }
                            </Link>
                    }
                )}
                </Carousel>
                </>
            }
        </div>
    );
}

export default FavouriteRecipesCarousel;