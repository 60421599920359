import React, { useState, useContext, useEffect, useCallback } from 'react';
import { GlobalContext } from '../../Contexts/Global/GlobalContext';
import { toast } from 'react-toastify';
import NutritionCalculator from './NutritionCalculator';
import NutritionBreakDown from './NutritionBreakDown';
import PremiumOnly from '../PremiumOnly/PremiumOnly';

interface INutritionProps {
    history: any
}

interface IGlobalContext {
    state?: any,
    setLoading?: Function
}

interface INutrition {
    _id: string,
    uid: string,
    date: Date,
    kcal: number,
    protein: number,
    carbohydrates: number,
    fats: number
}

const Nutrition = ({ history }: INutritionProps) => {
    const globalVariables: any = window;
    const globalContext: IGlobalContext = useContext(GlobalContext);
    const [loading, setLoading] = useState<boolean>(true);
    const [nutrition, setNutrition] = useState<INutrition | undefined>(undefined);

    const getNutrition = useCallback(async () => {
        globalContext.setLoading && globalContext.setLoading(true);

        await fetch(`${globalVariables.api}/nutrition/current`, {
            method: 'GET',
            credentials: 'include',
            mode: 'cors',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            })
        })
        .then(res => {
            if(res.status === 200) {
                return res.json();
            } else if (res.status === 404) {
                globalContext.setLoading && globalContext.setLoading(false);
                setLoading(false);
            } else if (res.status === 401) {
                window.location.href = "/";
            } else {
                setLoading(false);
                globalContext.setLoading && globalContext.setLoading(false);
                toast.error("There was a problem getting your nutrition information. Please try again.");
            }
        })
        .then(res => {
            globalContext.setLoading && globalContext.setLoading(false);
            setNutrition(res);
            setLoading(false);
        })
        .catch(err => {
            setLoading(false);
            globalContext.setLoading && globalContext.setLoading(false);
            toast.error("There was a problem getting your nutrition information. Please try again.");
        });
    }, []);

    useEffect(() => {
        getNutrition();
    }, [getNutrition]);

    return (
        <PremiumOnly history={history}>
            {!loading ?
                <>
                    {nutrition === undefined ?
                        <NutritionCalculator updateNutrition={(data: INutrition) => setNutrition(data)} />
                        :
                        <NutritionBreakDown nutritionData={nutrition} resetNutrition={() => setNutrition(undefined)} />
                    }
                </>
                :
                <></>
            }
        </PremiumOnly>
    );
}

export default Nutrition;