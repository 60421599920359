import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';

const ChallengeResult = () => {
    const globalVariables: any = window;
    const [gymData, setGymData] = useState<any>(undefined);
    const [homeData, setHomeData] = useState<any>(undefined);
    const [selected, setSelected] = useState<any>(undefined);

    const fetchGymChallengeResultData = useCallback(async () => {
        await fetch(`${globalVariables.api}/workout/challenge/results/60207007c20703001c6a2a4f`, {
            method: 'GET',
            credentials: 'include',
            mode: 'cors',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            })
        })
        .then(async (res) => {
            let data = await res.json();
            setGymData(data);
            // fetchHomeChallengeResultData();
        })
        .catch(err => {
            console.log(err);
        });
    }, []);

    const fetchHomeChallengeResultData = useCallback(async () => {
        await fetch(`${globalVariables.api}/workout/challenge/results/5f61121e9eebb066389f81e2`, {
            method: 'GET',
            credentials: 'include',
            mode: 'cors',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            })
        })
        .then(async (res) => {
            let data = await res.json();
            setHomeData(data);
        })
        .catch(err => {
            console.log(err);
        });
    }, []);

    useEffect(() => {
        fetchGymChallengeResultData();
    }, [fetchGymChallengeResultData]);


    return (
        <>
            <h1 className="mb-1">Challenge Participants</h1>
            {!gymData && <p>Loading Challenge Results</p>}
            {gymData && gymData.map((result: any) =>
            <>
            {result.user &&
                <div className="result-item">
                    <div className="result-item__header" onClick={() => (selected === undefined || selected !== result.user._id) ? setSelected(result.user._id) : setSelected(undefined)}>
                        <p>{result.user.firstname} {result.user.lastname} <span className="ml-auto">{result.checkins.length > 0 && result.checkins[0].weight} - {result.checkins.length > 0 && result.checkins[result.checkins.length -1].weight}kg | {result.logs.length} Logs | {result.progressPictures.length} Pictures</span></p>
                    </div>
                    {selected === result.user._id &&
                        <div className="result-item__content">
                            {result.progressPictures.map((picture: any) => 
                                <div data-date={moment(picture.date).format("Do MMM YYYY")}><img src={`${globalVariables.api}/media/admin/${picture.image}`} alt={moment(picture.date).format("Do MMM YYYY")} /></div>
                            )}
                        </div>
                    }
                </div>
            }
            </>
            )}
            <br />
            {/* <h1 className="mb-1">Home Challenge Participants</h1>
            {!homeData && <p>Loading Home Challenge Results</p>}
            {homeData && homeData.map((result: any) =>
                <div className="result-item">
                    <div className="result-item__header" onClick={() => (selected === undefined || selected !== result.user._id) ? setSelected(result.user._id) : setSelected(undefined)}>
                        <p>{result.user.firstname} {result.user.lastname} <span className="ml-auto">{result.checkins.length > 0 && result.checkins[0].weight} - {result.checkins.length > 0 && result.checkins[result.checkins.length -1].weight}kg | {result.logs.length} Logs | {result.progressPictures.length} Pictures</span></p>
                    </div>
                    {selected === result.user._id &&
                        <div className="result-item__content">
                            {result.progressPictures.map((picture: any) => 
                                <div data-date={moment(picture.date).format("Do MMM YYYY")}><img src={`${globalVariables.api}/media/admin/${picture.image}`} alt={moment(picture.date).format("Do MMM YYYY")} /></div>
                            )}
                        </div>
                    }
                </div>
            )} */}
        </>
    );
}

export default ChallengeResult;