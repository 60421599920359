import React, { useRef, useEffect } from "react";

/**
 * Hook that alerts clicks outside of the passed ref
 */
const useOutsideAlerter = (ref: any, closeAction: any) => {
  /**
   * Alert if clicked on outside of element
   */
  const handleClickOutside = (event: any) => {
    if (ref.current && !ref.current.contains(event.target)) {
        closeAction();
    }
  }

  useEffect(() => {
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });
}

/**
 * Component that alerts if you click outside of it
 */
export default function ClickToClose(props: any) {
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, props.close);

  return <div ref={wrapperRef}>{props.children}</div>;
}