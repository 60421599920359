import React, { useState, useContext } from 'react';
import * as yup from 'yup'; 
import logo from '../../logo-white.svg';

// Contexts
import { GlobalContext } from '../../Contexts/Global/GlobalContext';

interface IGlobalContext {
    setLoading?: Function
}

const ForgotPassword = () => {
    const globalVariables: any = window;
    const globalContext: IGlobalContext = useContext(GlobalContext);
    const [email, setEmail] = useState<string>("");
    const [error, setError] = useState<string | undefined>(undefined);
    const [complete, setComplete] = useState<boolean>(false);
    const emailSchema = yup.string().required().email();

    const submitResetRequest = async () => {
        let isValid = await emailSchema.isValid(email);

        if(isValid) {
            setError(undefined);
            globalContext.setLoading && globalContext.setLoading(true);

            await fetch(`${globalVariables.api}/user/reset`, {
                method: 'POST',
                credentials: 'include',
                mode: 'cors',
                headers: new Headers({
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }),
                body: JSON.stringify({email})
            })
            .then(async res => {
                if(res.status === 200) {
                    globalContext.setLoading && globalContext.setLoading(false);
                    setComplete(true);
                } else {
                    globalContext.setLoading && globalContext.setLoading(false);
                    setError("There was a problem trying to reset password. Please try again.");
                }
            })
            .catch(err => {
                globalContext.setLoading && globalContext.setLoading(false);
                setError("There was a problem trying to reset password. Please try again.");
            });
        } else {
            setError("Please enter a valid email address.");
        }
    }

    return (
        <div className="login-container">
            <div className="login-container__panel">
                <img className="login-container__logo" src={logo} />
            </div>
            <div className="login-container__form">
                <h1>Forgotten Password</h1>
                {complete ?
                <>
                    <p>We have sent a password reset link to {email}. Please check your junk/spam folder if it is not in your inbox. If you do not receive this email please get in contact with us.</p>
                </>
                :
                <>
                    <p>If you are having issues logging in or have forgotten your passsword simply enter your email address in the box below and we will send you a password reset link.</p>
                    <div className="form-item">
                        <label>Email address</label>
                        <input type="email" name="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                    </div>
                    {error && <div className="form-error">{error}</div>}
                    <button className="btn" onClick={() => submitResetRequest()}>Reset Password</button>
                </>
                }
            </div>
        </div>
    );
}

export default ForgotPassword;