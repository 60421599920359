import React, { useState, useContext } from 'react';
import { Formik, Form, Field } from 'formik';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import logo from '../../logo-white.svg';

// Contexts
import { GlobalContext } from '../../Contexts/Global/GlobalContext';

// Components
import PaymentMethod from '../../Components/PaymentMethod/PaymentMethod';
import Upgrade from '../Upgrade/Upgrade';

const registerSchema = Yup.object().shape({
    firstname: Yup.string()
                .required('Please provide your first name.'),
    lastname: Yup.string()
                .required('Please provide your last name.'),
    email: Yup.string()
                .email('Please enter a valid email address.')
                .required('Please provide your email address.'),
    password: Yup.string()
                .required('Please provide a password.')
                .min(8, "Password must be at least 8 characters long."),
    passwordConfirm: Yup.string()
                .required('Please confirm your password.')
                .min(8, "Password must be at least 8 characters long.")
});

interface IRegisterDetails {
    firstname: string,
    lastname: string,
    email: string,
    password: string,
    passwordConfirm: string
}

interface IGlobalContext {
    state?: any,
    setIsLoggedIn?: Function,
    setLoading?: Function,
    setInitialAppData?: Function
}

interface IRegisterProps {
    history: any
}

const Register = ({ history }: IRegisterProps) => {
    const [error, setError] = useState<string | undefined>(undefined);
    const [userType, setUserType] = useState<string>("premium");
    const [paymentMethod, setPaymentMethod] = useState<boolean>(false);
    const globalVariables: any = window;
    const globalContext: IGlobalContext = useContext(GlobalContext);

    const handleRegistration = async (data: IRegisterDetails) => {
        setError(undefined);

        if(data.password === data.passwordConfirm) {
            globalContext.setLoading && globalContext.setLoading(true);
            
            await fetch(`${globalVariables.api}/auth/register`, {
                method: 'POST',
                credentials: 'include',
                mode: 'cors',
                headers: new Headers({
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }),
                body: JSON.stringify({...data, type: userType})
            })
            .then(res => {
                if(res.status === 200) {
                    if(userType === "free") {
                        history.push("/");
                        // toast.success("You have succesfully registered.");
                        globalContext.setInitialAppData && globalContext.setInitialAppData();
                        globalContext.setLoading && globalContext.setLoading(false);
                    } else {
                        setPaymentMethod(true);
                        globalContext.setLoading && globalContext.setLoading(false);
                    }
                    
                } else if (res.status === 500) {
                    setError("Server error. Please try again.");
                    globalContext.setLoading && globalContext.setLoading(false);
                } else {
                    setError("Email address or password do not match.");
                    globalContext.setLoading && globalContext.setLoading(false);
                }
            })
            .catch(err => {
                setError("Server error. Please try again.");
                globalContext.setLoading && globalContext.setLoading(false);
            });
        } else {
            setError("Passwords do not match!");
        }
    }

    return (
        <>
        {!paymentMethod &&
            <div className="login-container">
            <div className="login-container__panel">
                <img className="login-container__logo" src={logo} />
            </div>
            <div className="login-container__form">
                <h1>Register</h1>
                <Formik
                    initialValues={{
                        firstname: "",
                        lastname: "",
                        email: "",
                        password: "",
                        passwordConfirm: ""
                    }}
                    validationSchema={registerSchema}
                    onSubmit={(data: IRegisterDetails) => handleRegistration(data)}>
                        {({ errors, touched }) => (
                            <Form>
                                <div className="form-item">
                                    <label htmlFor="firstname">First name</label>
                                    <Field type="text" name="firstname" id="firstname" placeholder="First name" />
                                    {errors.firstname && touched.firstname ? <div className="form-error">{errors.firstname}</div> : null}
                                </div>
                                <div className="form-item">
                                    <label htmlFor="lastname">Last name</label>
                                    <Field type="text" name="lastname" id="lastname" placeholder="Last name" />
                                    {errors.lastname && touched.lastname ? <div className="form-error">{errors.lastname}</div> : null}
                                </div>
                                <div className="form-item">
                                    <label htmlFor="email">Email address</label>
                                    <Field type="email" name="email" id="email" placeholder="Email address" />
                                    {errors.email && touched.email ? <div className="form-error">{errors.email}</div> : null}
                                </div>
                                <div className="form-item">
                                    <label htmlFor="password">Password</label>
                                    <Field type="password" name="password" id="password" placeholder="Password" />
                                    {errors.password && touched.password ? <div className="form-error">{errors.password}</div> : null}
                                </div>
                                <div className="form-item">
                                    <label htmlFor="passwordConfirm">Confirm password</label>
                                    <Field type="password" name="passwordConfirm" id="passwordConfirm" placeholder="Confirm password" />
                                    {errors.passwordConfirm && touched.passwordConfirm ? <div className="form-error">{errors.passwordConfirm}</div> : null}
                                </div>
                                <div className="form-item">
                                    <label htmlFor="User Type">User Type</label>
                                    <div className="option-buttons">
                                        <button className={`btn ${userType === "free" ? 'btn--orange' : ''}`} onClick={(e) => { e.preventDefault(); setUserType("free")}}>Free</button>
                                        <button className={`btn ${userType === "premium" ? 'btn--orange' : ''}`} onClick={(e) => { e.preventDefault(); setUserType("premium")}}>Premium</button>
                                    </div>
                                </div>
                                {error && <div className="form-error">{error}</div>}
                                <button className="btn btn--block" type="submit">
                                    Sign Up  
                                </button>
                            </Form>
                        )}
                </Formik>
                <Link to="/" className="form-link">Already have an account? Log In</Link>
            </div>
        </div>
        }
        {paymentMethod &&
            <main className="site-main">
                <Upgrade newUser={true} history={history} />
            </main>   
            }
        </>
    );
}

export default Register;