import React, { useState, useEffect, useCallback, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { GlobalContext } from '../../Contexts/Global/GlobalContext';
import { toast } from 'react-toastify';

interface IRecipe {
    _id: string,
    name: string,
    slug: string,
    ingredients: Array<string>,
    directions: Array<string>,
    kcals: number,
    protein: number,
    carbohydrates: number,
    fats: number,
    time: number,
    servings?: number,
    image?: string,
    category?: Array<string>
}

interface IAddTo {
    open: boolean,
    category: string
}

interface IGlobalContext {
    setLoading?: Function,
    state?: any,
    setFavourites?: Function
}

const Recipe = () => {
    const globalVariables: any = window;
    const globalContext: IGlobalContext = useContext(GlobalContext);
    const { slug } = useParams();
    const [recipe, setRecipe] = useState<IRecipe | null>(null);
    const [tab, setTab] = useState<"ingredients" | "method">("ingredients");
    const [addTo, setAddTo] = useState<IAddTo>({ open: false, category: "" });

    const fetchRecipe = useCallback(async () => {
        await fetch(`${globalVariables.api}/recipe/slug/${slug ? slug : ''}`, {
            method: 'GET',
            credentials: 'include',
            mode: 'cors',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            })
        })
        .then(async (res) => {
            let data = await res.json();

            if(res.status === 200) {
                setRecipe(data);
            } else if (res.status === 401) {
                window.location.href = "/";
            } else {
                alert("Error");
                console.log(res);
                // setIsLoggedIn(false);
                // setInitialised(true);
                // setLoading(false);
            }
        })
        .catch(err => {
            console.log(err);
            alert("error");
            // setIsLoggedIn(false);
            // setInitialised(true);
        });
    }, []);

    const addToDiary = async (category: string) => {
        globalContext.setLoading && globalContext.setLoading(true);

        setAddTo({ ...addTo, open: false });
        
        let data = {
            name: recipe?.name,
            kcals: recipe?.kcals,
            protein: recipe?.protein,
            carbohydrates: recipe?.carbohydrates,
            fats: recipe?.fats,
            servings: 1,
            image: recipe?.image,
            category: category,
            eid: uuidv4()
        }

        await fetch(`${globalVariables.api}/nutrition/diary/add`, {
            method: 'POST',
            credentials: 'include',
            mode: 'cors',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }),
            body: JSON.stringify({date: new Date(), data: data})
        })
        .then(async res => {
            let data = await res.json();
            if(res.status === 200) {
                globalContext.setLoading && globalContext.setLoading(false);
                toast.success("Added to todays nutrition.");
            } else if (res.status === 401) {
                window.location.href = "/";
            } else {
                globalContext.setLoading && globalContext.setLoading(false);
                toast.error("There was a problem adding to your nutrition diary.");
            }
        })
        .catch(err => {
            globalContext.setLoading && globalContext.setLoading(false);
            console.log(err);
            toast.error("There was a problem adding to your nutrition diary.");
        });
    }

    const handleFavouriteClick = (recipeid: string) => {
        let data = {...globalContext.state.favourites};

        if(data.recipes.length > 0 && data.recipes.includes(recipeid)) {
            data.recipes = data.recipes.filter((recipe: any) => recipe !== recipeid);
        } else {
            data.recipes.push(recipeid);
        }

        globalContext.setFavourites && globalContext.setFavourites(data);
    }

    useEffect(() => {
        fetchRecipe();
    }, [fetchRecipe]);

    return (
        <>
        <div className="recipe-detail-container">
            {recipe && 
            <>
                <div className="video-library-item">
                    <div className="video-library-item__image recipe-detail">
                        {recipe.image && <img src={`${globalVariables.api}/media/${recipe.image}`} alt={recipe.name} />}
                        <div className="video-library-item__info">
                            {recipe.time && 
                                <div className="video-library-item__time">
                                    <svg>
                                        <use xlinkHref="/assets/icon-library.svg#stopwatch" />
                                    </svg>
                                    <span>{recipe.time} mins</span>
                                </div>}
                            {recipe.servings && 
                                <div className="video-library-item__time">
                                    <svg>
                                        <use xlinkHref="/assets/icon-library.svg#plate" />
                                    </svg>
                                    <span>{recipe.servings}</span>
                                </div>
                            }
                        </div>
                        {recipe.category?.includes("vegan") &&
                            <div className="vegan-icon">
                                <span>V</span>
                                <div className="vegan-icon__tooltip">
                                    Vegan Friendly
                                </div>
                            </div>
                        }
                        <button className={`favourite-btn ${globalContext.state.favourites && globalContext.state.favourites.recipes.includes(recipe._id) ? 'is-active' : ''}`} onClick={() => handleFavouriteClick(recipe._id)}>
                            {globalContext.state.favourites && globalContext.state.favourites.recipes.includes(recipe._id) ?
                                <svg>
                                    <use xlinkHref="/assets/icon-library.svg#star-solid" />
                                </svg>
                                :
                                <svg>
                                    <use xlinkHref="/assets/icon-library.svg#star-outline" />
                                </svg>
                            }
                        </button>
                        {globalContext.state && globalContext.state.user.type === "premium" &&
                            <button className="icon-btn add-to" onClick={() => setAddTo({ ...addTo, open: true })}>+</button>
                        }
                    </div>
                    <div className="video-library-item__title">{recipe.name}</div>
                </div>
                <div className="recipe-detail">
                    <div className="recipe-detail__nutrition">
                        <div>
                            {recipe.kcals}
                            <span>Kcals</span>
                        </div>
                        <div>
                            {recipe.protein}g
                            <span>Protein</span>
                        </div>
                        <div>
                            {recipe.carbohydrates}g
                            <span>Carbs</span>
                        </div>
                        <div>
                            {recipe.fats}g
                            <span>Fats</span>
                        </div>
                    </div>
                    <div className="recipe-detail__tabs">
                        <button className={`recipe-detail__tab ${tab === "ingredients" ? 'is-selected' : ''}`} onClick={() => setTab("ingredients")}>
                            Ingredients
                        </button>
                        <button className={`recipe-detail__tab ${tab === "method" ? 'is-selected' : ''}`} onClick={() => setTab("method")}>
                            Method
                        </button>
                    </div>
                </div>
                <div className={`recipe-detail__content ${tab === "ingredients" ? 'is-active' : ''}`}>
                    <ul>
                    {recipe.ingredients && recipe.ingredients.map((ingredient, index) => {
                        if(ingredient !== "") {
                            return <li key={index}>{ingredient}</li>
                        }
                    }  
                    )}
                    </ul>
                </div>
                <div className={`recipe-detail__content ${tab === "method" ? 'is-active' : ''}`}>
                    <ol>
                    {recipe.directions && recipe.directions.map((direction, index) => {
                        if(direction !== "") {
                            return <li key={index}>{direction}</li>
                        }
                    }
                    )}
                    </ol>
                </div>
            </>
            }
        </div>
        <div className={`overlay level-2 ${addTo.open ? 'is-active' : ''}`} onClick={() => setAddTo({...addTo, open: false})}></div>
        <div className={`modal level-2 ${addTo.open ? 'is-open' : ''}`}>
            <h2>Add to today:</h2>
            <button className="card generic-card category-card" onClick={() => addToDiary("Breakfast")}>
                Breakfast
                <svg>
                    <use xlinkHref="/assets/icon-library.svg#chevron-right" />
                </svg>
            </button>
            <button className="card generic-card category-card" onClick={() => addToDiary("Lunch")}>
                Lunch
                <svg>
                    <use xlinkHref="/assets/icon-library.svg#chevron-right" />
                </svg>
            </button>
            <button className="card generic-card category-card" onClick={() => addToDiary("Dinner")}>
                Dinner
                <svg>
                    <use xlinkHref="/assets/icon-library.svg#chevron-right" />
                </svg>
            </button>
            <button className="card generic-card category-card" onClick={() => addToDiary("Snacks")}>
                Snacks
                <svg>
                    <use xlinkHref="/assets/icon-library.svg#chevron-right" />
                </svg>
            </button>
            <div className="modal__actions">
                <button className="btn btn--block btn--text" onClick={() => setAddTo({ ...addTo, open: false })}>Cancel</button>
            </div>
        </div>
        </>
    );
}

export default Recipe;