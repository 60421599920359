import React, { useEffect, useCallback, useState, useContext } from 'react';
import { Link, useParams } from 'react-router-dom';
import { GlobalContext } from '../../Contexts/Global/GlobalContext';
import Header from '../../Components/Header/Header';

interface IRecipe {
    _id: string,
    name: string,
    slug: string,
    ingredients: Array<string>,
    directions: Array<string>,
    kcals: number,
    protein: number,
    carbohydrates: number,
    fats: number,
    time: number,
    prepTime?: number,
    servings?: number,
    image?: string,
    category?: Array<string>,
    premium?: boolean
}

interface IGlobalContext {
    setLoading?: Function,
    state?: any,
    setFavourites?: Function
}

const FavouriteRecipes = () => {
    const globalVariables: any = window;
    const globalContext: IGlobalContext = useContext(GlobalContext);
    const [recipes, setRecipes] = useState<Array<IRecipe> | null>(null);
    const { category } = useParams();

    const fetchRecipes = useCallback(async () => {
        await fetch(`${globalVariables.api}/recipe`, {
            method: 'GET',
            credentials: 'include',
            mode: 'cors',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            })
        })
        .then(async (res) => {
            let data = await res.json();

            if(res.status === 200) {
                setRecipes(data);
            } else if (res.status === 401) {
                window.location.href = "/";
            } else {
                alert("Error");
                console.log(res);
            }
        })
        .catch(err => {
            console.log(err);
            alert("error");
        });
    }, []);

    useEffect(() => {
        fetchRecipes();
    }, [fetchRecipes]);

    return (
        <>
            <Header title="Favourite Recipes" />
            <div className="home-container">
                {globalContext.state && globalContext.state.favourites && globalContext.state.favourites.recipes.length > 0 ?
                    <div className="recipe-library">
                        {recipes && recipes.filter(recipe => globalContext.state.favourites.recipes.includes(recipe._id)).map((recipe, index) => {
                            let toLink = recipe.slug !== null ? `/recipes/detail/${recipe.slug}` : `/go-premium`;

                            return <Link key={index} to={toLink} className="recipe-item">
                                        <div className="recipe-item__image recipe">
                                            <img src={`${globalVariables.api}/media/${recipe.image}`} alt={recipe.name} />
                                            {recipe.slug === null &&
                                                <div className="recipe-lock">
                                                    <svg>
                                                        <use xlinkHref="/assets/icon-library.svg#lock" />
                                                    </svg>
                                                </div>
                                            }
                                            {globalContext.state.favourites && globalContext.state.favourites.recipes.includes(recipe._id) &&
                                            <button className={`favourite-btn is-active`}>
                                                <svg>
                                                    <use xlinkHref="/assets/icon-library.svg#star-solid" />
                                                </svg>
                                            </button>}
                                        </div>
                                        <div className="recipe-item__content">
                                            <h3>{recipe.name}</h3>
                                            <p className="recipe-item__tags">
                                                <span>{recipe.time + (recipe.prepTime ? recipe.prepTime : 0)} Mins</span>
                                                {recipe.category?.includes("vegan") &&
                                                    <span>Vegan</span>
                                                }
                                            </p>
                                        </div>
                                    </Link>
                        }
                        )}
                    </div>
                :
                        <>
                            <p>You do not currently have any favourite recipes.<br /><br /> You can select your favourite recipes by clicking the star icon on the recipes you like the most!</p>
                            <Link to="/recipes" className="btn btn--block">View recipes</Link>
                        </>
                }
            </div>
        </>
    );
}

export default FavouriteRecipes;