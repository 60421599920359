import React, { useEffect, useCallback, useState, useContext } from 'react';
import { Link, useParams } from 'react-router-dom';
import Header from '../../Components/Header/Header';
import { GlobalContext } from '../../Contexts/Global/GlobalContext';

interface IWorkout {
    _id: string,
    title: string,
    slug: string,
    duration?: number,
    description?: string,
    video?: string,
    image?: string,
    date: Date,
    type: string
    [x:string]: any
}

interface IGlobalContext {
    state?: any,
    setIsLoggedIn?: Function,
    setLoading?: Function,
    setInitialAppData?: Function,
    setFavourites?: Function,
}

const Workout = () => {
    const [workouts, setWorkouts] = useState<IWorkout[] | undefined>(undefined);
    const globalContext: IGlobalContext = useContext(GlobalContext);
    const globalVariables: any = window;
    const { category } = useParams();

    const fetchWorkouts = useCallback(async () => {
        await fetch(`${globalVariables.api}/workout/${category ? category : ''}`, {
            method: 'GET',
            credentials: 'include',
            mode: 'cors',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            })
        })
        .then(async (res) => {
            let data = await res.json();

            if(res.status === 200) {
                setWorkouts(data);
            } else if (res.status === 401) {
                window.location.href = "/";
            } else {
                // setIsLoggedIn(false);
                // setInitialised(true);
                // setLoading(false);
            }
        })
        .catch(err => {
            console.log(err);
            // setIsLoggedIn(false);
            // setInitialised(true);
        });
    }, []);

    useEffect(() => {
        fetchWorkouts();
    }, [fetchWorkouts]);

    return (
        <>
            <Header title="Select a Workout" />
            <div className="home-container">
                <div className="video-library">
                    {workouts && workouts.map((workout, index) => {
                        let toLink = workout.slug !== null ? `/workout/detail/${workout.slug}` : '/go-premium';

                        return <Link key={index} to={toLink} className="video-library-item">
                                    <div className="video-library-item__image">
                                        {workout.image &&
                                            <img src={`${globalVariables.api}/media/${workout.image}`} alt={workout.title} />
                                        }
                                        {workout.duration &&
                                            <div className="video-library-item__time">
                                                <svg>
                                                    <use xlinkHref="/assets/icon-library.svg#stopwatch" />
                                                </svg>
                                                <span>{workout.duration} mins</span>
                                            </div>
                                        }
                                        {workout.slug === null &&
                                            <div className="video-library-item__upgrade">
                                                <div>
                                                    <p>Only available for Premium Members</p>
                                                    <Link to="/go-premium" className="btn">Upgrade</Link>
                                                </div>
                                            </div>
                                        }
                                        {globalContext.state.favourites && globalContext.state.favourites.workouts.includes(workout._id) &&
                                        <button className={`favourite-btn is-active`}>
                                            <svg>
                                                <use xlinkHref="/assets/icon-library.svg#star-solid" />
                                            </svg>
                                        </button>}
                                    </div>
                                    <div className="video-library-item__title">
                                        {workout.title}
                                    </div>
                                </Link>
                    }
                    )}
                </div>
            </div>
        </>
    );
}

export default Workout;