import React, { useEffect, useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment'

interface IScheduleProps {
    plan?: IPlan
}

interface IPlan {
    [x:string]: any
}

interface IScheduleData {
    week?: number,
    weekCount?: number,
    days?: any[],
    future?: boolean
}

interface IWorkout {
    image: string
}

const Schedule = ({ plan }: IScheduleProps) => {
    const globalVariables: any = window;
    const [scheduleData, setScheduleData] = useState<IScheduleData | undefined>(undefined);
    const [comparisonDate, setComparisonDate] = useState<Date | undefined>(undefined);
    const [complete, setComplete] = useState<boolean>(false);
    const [detail, setDetail] = useState<IWorkout | undefined>(undefined);

    const createSchedule = useCallback(async (data: any) => {
        let future = moment(data.startDate).isAfter(new Date(), 'day');
        
        if(!future) {
            let week = moment(data.startDate).diff(new Date(), 'weeks') * -1;
            
            if(week >= data.data.length) {
                setComplete(true);
                setScheduleData({week: week + 1, weekCount: data.data.length, days: data.data[0]});
            } else {
                let days = data.data[week].days.map((day: any) => { return { id: day.id, complete: false }});

                let compare = new Date();
    
                if(week === 0) {
                    compare = data.startDate;
                } else {
                    compare = moment(data.startDate).add(week, 'weeks').toDate();
                }
    
                let logs = await fetch(`${globalVariables.api}/log/after/${moment(compare).format("DD-MM-YYYY")}`, {
                        method: 'GET',
                        credentials: 'include',
                        mode: 'cors',
                        headers: new Headers({
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'
                        })
                    })
                    .then(async (res) => {
                        let data = await res.json();
            
                        if(res.status === 200) {
                            return data;
                        } else if (res.status === 401) {
                            window.location.href = "/";
                        } else {
                            return [];
                        }
                    })
                    .catch(err => {
                        return [];
                    });
    
                days.forEach((day: any) => {
                    logs.length > 0 && logs.forEach((log: any) => {
                        if(day.id === log.id) {
                            day.complete = true;
                        }
                    });
                });
    
                setComparisonDate(compare);
                setScheduleData({week: week + 1, weekCount: data.data.length, days: days});
            }
        } else {
            setScheduleData({future: true});
        }
        
    }, []);

    const fetchDetail = useCallback(async () => {
        const slug = plan ? plan.name.replace(/\s+/g, '-').toLowerCase() : '';
        await fetch(`${globalVariables.api}/workout/detail/${slug}`, {
            method: 'GET',
            credentials: 'include',
            mode: 'cors',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            })
        })
        .then(async (res) => {
            let data = await res.json();
            setDetail(data);
        })
        .catch(err => {
            console.log(err);
        });
    }, []);

    useEffect(() => {
        createSchedule(plan);
        fetchDetail();
    }, [plan, fetchDetail]);

    return (
        <>
        {!complete && plan && detail && scheduleData &&
            <div className="plan-card">
                <div className="plan-card__image">
                    <img src={`${globalVariables.api}/media/${detail.image}`} />
                </div>
                <div className="plan-card__header">
                    <div className="plan-card__name">{plan.name}</div>
                    {!scheduleData.future &&<div className="plan-card__week">
                        <p>Week <span>{scheduleData.week}</span></p>
                    </div>}
                </div>
                {scheduleData.future ?
                    <p>Plan not yet started - begins {moment(plan.startDate).format("DD-MM-YYYY")}</p>
                    :
                    <div className="plan-card__itinerary">
                    {scheduleData.days?.map((day, index) =>
                        <Link to={`/logging/${plan._id}/${scheduleData.week}/${day.id}`} className={`plan-card__item ${day.complete ? 'is-complete': ''}`} key={index}>
                            <p>Workout {index + 1} {day.name && `- ${day.name}`}</p>
                            <span>
                                <svg>
                                    <use xlinkHref={`/assets/icon-library.svg#${day.complete ? 'check': 'arrow-right'}`} />
                                </svg>
                            </span>
                        </Link>
                    )}
                    </div>
                }
            </div>
            }
        </>
    );
}

export default Schedule;