import React, { useState, useContext } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import Header from '../../Components/Header/Header';
import NutritionSubNav from '../../Components/SubNavs/NutritionSubNav';
import { GlobalContext } from '../../Contexts/Global/GlobalContext';

const macroCalcSchema = Yup.object().shape({
    dob: Yup.date().required("Please select your date of birth."),
    weight: Yup.number().required("Please enter your current body weight."),
    height: Yup.number().required("Please enter your height."),
    activity: Yup.string().required("Please select your activity level."),
    goal: Yup.string().required("Please select your goal.")
});

interface IMacroCalcData {
    gender?: string,
    dob: Date,
    weight: number | string,
    height: number | string,
    activity: string,
    goal: string
}

interface IGlobalContext {
    state?: any,
    setLoading?: Function
}

interface INutrtionCalculatorProps {
    updateNutrition: Function
}

const NutritionCalculator = ({ updateNutrition }: INutrtionCalculatorProps) => {
    const globalVariables: any = window;
    const globalContext: IGlobalContext = useContext(GlobalContext);
    const [gender, setGender] = useState("female");

    const handleSubmit: Function = async (data: IMacroCalcData) => {
        globalContext.setLoading && globalContext.setLoading(true);

        await fetch(`${globalVariables.api}/nutrition/calculate`, {
            method: 'POST',
            credentials: 'include',
            mode: 'cors',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }),
            body: JSON.stringify({...data, date: new Date()})
        })
        .then(res => {
            if(res.status === 200) {
                return res.json();
            } else if (res.status === 401) {
                window.location.href = "/";
            } else {
                console.log(res);
            }
        })
        .then(res => {
            console.log(res);
            globalContext.setLoading && globalContext.setLoading(false);
            // alert(`Kcals: ${res.kcal} | Protein: ${res.protein}g | Carbohydrates: ${res.carbohydrates}g | Fats: ${res.fats}g`);
            updateNutrition(res);
        })
        .catch(err => {
            console.log(err);
        });
    }

    return (
        <>
            <Header title="Calculate your macros" />
            <NutritionSubNav />
            <div className="home-container">
                {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero. Sed cursus ante dapibus diam.</p>
                <p>Sed nisi. Nulla quis sem at nibh elementum imperdiet. Duis sagittis ipsum. Praesent mauris. Fusce nec tellus sed.</p> */}
                <div className="internal-container">
                    <Formik
                        initialValues={{
                            dob: new Date(),
                            weight: "",
                            height: "",
                            activity: "sedentary",
                            goal: "deficit"
                        }}
                        validationSchema={macroCalcSchema}
                        onSubmit={(data: IMacroCalcData) => handleSubmit(data)}>
                            {({ errors, touched }) => (
                                <Form>
                                <div className="form-item">
                                    <label>Gender</label>
                                    <div className="select-tabs">
                                        <div className={`select-tabs__item ${gender === "male" ? 'is-selected' : ''}`}>
                                            <label>Male</label>
                                            <input type="radio" name="gender" value="male" checked={gender === "male"} onChange={() => setGender("male")} />
                                        </div>
                                        <div className={`select-tabs__item ${gender === "female" ? 'is-selected' : ''}`}>
                                            <label>Female</label>
                                            <input type="radio" name="gender" value="female" checked={gender === "female"} onChange={() => setGender("female")} />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-item">
                                    <label htmlFor="dob">Date of birth</label>
                                    <Field type="date" name="dob" id="dob" />
                                    {errors.dob && touched.dob ? <div className="form-error">{errors.dob}</div> : null}
                                </div>
                                <div className="grid-2-col">
                                    <div className="form-item">
                                        <label htmlFor="weight">Weight (KG)</label>
                                        <Field type="number" step="0.01" min="0" name="weight" id="weight" />
                                        {errors.weight && touched.weight ? <div className="form-error">{errors.weight}</div> : null}
                                    </div>
                                    <div className="form-item">
                                        <label htmlFor="height">Height (CM)</label>
                                        <Field type="number" name="height" id="height" />
                                        {errors.height && touched.height ? <div className="form-error">{errors.height}</div> : null}
                                    </div>
                                </div>
                                <div className="form-item">
                                    <label htmlFor="activity">Activity level</label>
                                    <Field as="select" name="activity" id="activity">
                                        <option value="sedentary">Sedentary (little to no activity)</option>
                                        <option value="light">Light (3-4 days exercise)</option>
                                        <option value="moderate">Moderate (4-5 days intense exercise with high step count)</option>
                                        <option value="Very">Very (5-6 days intense exercise with high step count)</option>
                                    </Field>
                                    {errors.activity && touched.activity ? <div className="form-error">{errors.activity}</div> : null}
                                </div>
                                <div className="form-item">
                                    <label htmlFor="goal">Goal</label>
                                    <Field as="select" name="goal" id="goal">
                                        <option value="deficit">Lose weight and tone-up</option>
                                        <option value="maintenance">Maintain weight</option>
                                        <option value="surplus">Gain strength and muscle</option>
                                    </Field>
                                    {errors.goal && touched.goal ? <div className="form-error">{errors.goal}</div> : null}
                                </div>
                                <button className="btn btn--block">Calculate</button>
                                </Form>
                            )}
                    </Formik>
                </div>
            </div>
        </>
    );
}

export default NutritionCalculator;