import React from 'react';

interface ITotals {
    kcal: number,
    protein: number,
    carbohydrates: number,
    fats: number
}

interface INutritionTargetProps {
    target?: any
    totals?: undefined | ITotals
}

const NutritionTarget = ({ target, totals }: INutritionTargetProps) => {
    return (
        <div className="nutrition-target">
            <h2>Daily Target</h2>
            <div className="nutrition-target-item">
                <div className="nutrition-target-item__label">
                    <label>Kcals</label>
                    <span><b className={totals && totals?.kcal > target.kcal ? 'text-red' : ''}>{totals?.kcal.toFixed(0)}</b>/{target.kcal} kcal</span>
                </div>
                <div className="nutrition-target-item__progress">
                    <div className="nutrition-target-item__bar kcals" style={{width: `${totals ? (totals?.kcal / target.kcal) * 100 : 0}%`}}></div>
                </div>
            </div>
            <div className="nutrition-target-item">
                <div className="nutrition-target-item__label">
                    <label>Protein</label>
                    <span><b>{totals?.protein}</b>/{target.protein.toFixed(0)}g</span>
                </div>
                <div className="nutrition-target-item__progress">
                    <div className="nutrition-target-item__bar protein" style={{width: `${totals ? (totals?.protein / target.protein) * 100 : 0}%`}}></div>
                </div>
            </div>
            <div className="nutrition-target-item">
                <div className="nutrition-target-item__label">
                    <label>Carbohydrates</label>
                    <span><b>{totals?.carbohydrates}</b>/{target.carbohydrates.toFixed(0)}g</span>
                </div>
                <div className="nutrition-target-item__progress">
                    <div className="nutrition-target-item__bar carbohydrates" style={{width: `${totals ? (totals?.carbohydrates / target.carbohydrates) * 100 : 0}%`}}></div>
                </div>
            </div>
            <div className="nutrition-target-item">
                <div className="nutrition-target-item__label">
                    <label>Fats</label>
                    <span><b>{totals?.fats}</b>/{target.fats.toFixed(0)}g</span>
                </div>
                <div className="nutrition-target-item__progress">
                    <div className="nutrition-target-item__bar fats" style={{width: `${totals ? (totals?.fats / target.fats) * 100 : 0}%`}}></div>
                </div>
            </div>
        </div>
    );
}

export default NutritionTarget;