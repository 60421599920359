import React, { useState, useCallback, useEffect, useContext } from 'react';
import { toast } from 'react-toastify'; 
import { Link } from 'react-router-dom';
import moment from 'moment';

// Components
import Header from '../../Components/Header/Header';

// Context
import { GlobalContext } from '../../Contexts/Global/GlobalContext';

interface IChallenge {
    _id?: string,
    name: string,
    slug?: string,
    duration?: number,
    description?: string,
    image?: string,
    startDate?: Date,
    enrollBy?: Date,
    weeks?: any[]
}

interface IGlobalContext {
    setLoading?: Function
}

const ChallengeManager = () => {
    const globalVariables: any = window;
    const globalContext: IGlobalContext = useContext(GlobalContext);
    const [challenges, setChallenges] = useState<IChallenge[] | undefined>(undefined);

    const fetchChallenges = useCallback(async () => {
        globalContext.setLoading && globalContext.setLoading(true);
        await fetch(`${globalVariables.api}/workout/challenge/getall`, {
            method: 'GET',
            credentials: 'include',
            mode: 'cors',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            })
        })
        .then(async (res) => {
            let data = await res.json();

            if(res.status === 200) {
                if(data.length > 0) {
                    setChallenges(data);
                }
                globalContext.setLoading && globalContext.setLoading(false);
            } else {
                toast.error("There was an issue fetching challenges.");
                globalContext.setLoading && globalContext.setLoading(false);
            }
        })
        .catch(err => {
            console.log(err);
            toast.error("There was an issue fetching challeges.");
            globalContext.setLoading && globalContext.setLoading(false);
        });
    }, []);

    useEffect(() => {
        fetchChallenges();
    }, [fetchChallenges]);
    return (
        <>
            <Header title="Challenge Manager" />
            <div className="home-container">
                {challenges &&
                <div className="challenge-library">
                    {challenges.map((challenge, index) =>
                        <Link to={`/challenge-manage/${challenge.slug}`} className="challenge-card" key={index}>
                            <img src={`${globalVariables.api}/media/${challenge.image}`} alt={challenge.name} />
                            <div className="challenge-card__content">
                                <div className="challenge-card__title">{challenge.name}</div>
                                <div className="challenge-card__start">Start date: {moment(challenge.startDate).format("Do MMMM")}</div>
                            </div>
                        </Link>
                    )}
                </div>
                }
            </div>
        </>
    );
}

export default ChallengeManager;