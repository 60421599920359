import React, { useContext } from 'react';
import LoggingTimerBar from './LoggingTmerBar';
// Contexts
import { ChallengeContext } from '../../Contexts/ChallengeContext/ChallengeContext';

interface ILoggingSupersetProps {
    setIndex: number,
    exerciseId: string,
    exerciseIndex: any,
    setData: any,
    ssIndex: number
}

interface IChallengeContext {
    state?: any,
    updateLogData?: Function
}

const LoggingSuperset = ({ setIndex, exerciseId, setData, exerciseIndex, ssIndex }: ILoggingSupersetProps) => {
    const challengeContext: IChallengeContext = useContext(ChallengeContext);
    const alphabet: string[] = ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z"];

    return (
        <div className="workout-set">
            <div className="workout-set__header">
                Set {setIndex + 1}{alphabet[ssIndex + 1].toUpperCase()}
            </div>
            {setData.type === "reps" &&
                <>
                <div className="workout-set__target">
                    <div>
                        <label>Target</label>
                        <p>{setData.reps.target} <span>Reps</span></p>
                    </div>
                </div>
                <div className="workout-set__logging">
                    <div>
                        <label>Achieved</label>
                        <div className="input-group">
                            <input type="number" name="reps" 
                                   value={setData.log && setData.log?.reps ? setData.log.reps : ''}
                                   onChange={(e) => challengeContext.updateLogData && challengeContext.updateLogData(e, setIndex, setData.id)} />
                            <p>Reps</p>
                        </div>
                    </div>
                    <div>
                        <div className="input-group">
                            <input type="number" name="weight"
                                   value={setData.log && setData.log?.weight ? setData.log.weight : ''}
                                   onChange={(e) => challengeContext.updateLogData && challengeContext.updateLogData(e, setIndex, setData.id)} />
                            <p>KG</p>
                        </div>
                    </div>
                </div>
                </>
            }
            {setData.type === "range" &&
                <>
                <div className="workout-set__target">
                    <div>
                        <label>Target</label>
                        <p>{setData.range.from}-{setData.range.to} <span>Reps</span></p>
                    </div>
                </div>
                <div className="workout-set__logging">
                    <div>
                        <label>Achieved</label>
                        <div className="input-group">
                            <input type="number" name="reps" 
                                   value={setData.log && setData.log?.reps ? setData.log.reps : ''}
                                   onChange={(e) => challengeContext.updateLogData && challengeContext.updateLogData(e, setIndex, setData.id)} />
                            <p>Reps</p>
                        </div>
                    </div>
                    <div>
                        <div className="input-group">
                            <input type="number" name="weight"
                                   value={setData.log && setData.log?.weight ? setData.log.weight : ''}
                                   onChange={(e) => challengeContext.updateLogData && challengeContext.updateLogData(e, setIndex, setData.id)} />
                            <p>KG</p>
                        </div>
                    </div>
                </div>
                </>
            }
            {setData.type === "liss" &&
                <>
                <div className="workout-set__target">
                    <div>
                        <label>Target</label>
                        <p>{setData.liss.duration < 1 ? `${setData.liss.duration * 60}s` : `${setData.liss.duration} Minutes`}</p>
                    </div>
                    {setData.pace &&
                        <div>
                            <p> at {setData.pace} <span>km/h</span></p>
                        </div>
                    }
                </div>
                <div className="workout-set__timer">
                    <div>
                        <label>Timer</label>
                        <LoggingTimerBar time={setData.liss.duration} />
                    </div>
                </div>
                </>
            }
        </div>
    );
}

export default LoggingSuperset;