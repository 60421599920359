import React, { useContext, useState } from 'react';
import * as yup from 'yup';
import { useParams } from 'react-router-dom';
import logo from '../../logo-white.svg';

// Contexts
import { GlobalContext } from '../../Contexts/Global/GlobalContext';

interface IGlobalContext {
    setLoading?: Function
}

const ForgotPasswordUpdate = () => {
    const globalVariables: any = window;
    const globalContext: IGlobalContext = useContext(GlobalContext);
    const [password, setPassword] = useState<string>("");
    const [confirmPassword, setConfirmPassword] = useState<string>("");
    const [error, setError] = useState<string | undefined>(undefined);
    const [complete, setComplete] = useState<boolean>(false);
    const passwordSchema = yup.string().required().min(8);
    const { code } = useParams();

    const submitPasswordReset = async () => {
        let passwordValid = await passwordSchema.isValid(password),
            confirmPasswordValid = await passwordSchema.isValid(confirmPassword),
            match = password === confirmPassword;

        if(passwordValid && confirmPasswordValid && match) {
            setError(undefined);
            globalContext.setLoading && globalContext.setLoading(true);

            await fetch(`${globalVariables.api}/user/reset`, {
                method: 'PUT',
                credentials: 'include',
                mode: 'cors',
                headers: new Headers({
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }),
                body: JSON.stringify({password, code})
            })
            .then(async res => {
                if(res.status === 200) {
                    globalContext.setLoading && globalContext.setLoading(false);
                    setComplete(true);
                } else {
                    globalContext.setLoading && globalContext.setLoading(false);
                    setError("There was a problem trying to reset password. Please try again.");
                }
            })
            .catch(err => {
                globalContext.setLoading && globalContext.setLoading(false);
                setError("There was a problem trying to reset password. Please try again.");
            });
        } else {
            setError("Please make sure your password is at least 8 characters long and passwords match.");
        }
    }

    return (
        <div className="login-container">
            <div className="login-container__panel">
                <img className="login-container__logo" src={logo} />
            </div>
            <div className="login-container__form">
                <h1>Reset your password</h1>
                {complete ?
                    <>
                        <p>Your password has been reset - <a href="/">Login Now.</a></p>
                    </>
                :
                    <>
                        <p>Please enter a new password below:</p>
                        <div className="form-item">
                            <label>Password</label>
                            <input type="password" name="password" value={password} onChange={(e) => setPassword(e.target.value)}/>
                        </div>
                        <div className="form-item">
                            <label>Confirm Password</label>
                            <input type="password" name="confirmPassword" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)}/>
                        </div>
                        {error && <div className="form-error">{error}</div>}
                        <button className="btn" onClick={() => submitPasswordReset()}>Reset Password</button>
                    </>
                }
            </div>
        </div>
    );
}

export default ForgotPasswordUpdate;