import React, { useState, useEffect, useCallback, useContext } from 'react';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import Header from '../../Components/Header/Header';
import RecipeCarousel from '../../Components/RecipeCarousel/RecipeCarousel';
import IconDropdown from '../../Components/IconDropdown/IconDropdown';
import NutritionTarget from '../../Components/NutritionTarget/NutritionTarget';
import NutritionDiary from '../../Components/NutritionDiary/NutritionDiary';
import { GlobalContext } from '../../Contexts/Global/GlobalContext';
import NutritionSubNav from '../../Components/SubNavs/NutritionSubNav';

interface IGlobalContext {
    setLoading?: Function
}

interface IMongoNutrition {
    _id: string,
    uid: string,
    date: Date,
    kcal: number,
    protein: number,
    carbohydrates: number,
    fats: number
}

interface INutritionBreakDownProps {
    nutritionData: IMongoNutrition,
    resetNutrition: Function,
    [x:string]: any
}

interface IDiary {
    _id?: string,
    eid?: string,
    uid?: string,
    date?: Date,
    data?: any[],
    [x:string]: any
}

interface ITotals {
    kcal: number,
    protein: number,
    carbohydrates: number,
    fats: number
}

const NutritionBreakDown = ({ nutritionData, resetNutrition }: INutritionBreakDownProps) => {
    const globalVariables: any = window;
    const globalContext: IGlobalContext = useContext(GlobalContext);
    const [diary, setDiary] = useState<IDiary | undefined>(undefined);
    const [totals, setTotals] = useState<ITotals | undefined>(undefined);
    const [selectedDate, setSelectedDate] = useState<Date>(new Date());

    const defaultInit = () => {
        setDiary({
            date: new Date(),
            data: [],
        });

        setTotals({
            kcal: 0,
            protein: 0,
            carbohydrates: 0,
            fats: 0
        });
    }

    const fetchDiary = useCallback(async (date: Date = new Date()) => {
        globalContext.setLoading && globalContext.setLoading(true);

        await fetch(`${globalVariables.api}/nutrition/diary/findByDate`, {
            method: 'POST',
            credentials: 'include',
            mode: 'cors',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }),
            body: JSON.stringify({date: selectedDate})
        })
        .then(res => {
            if(res.status === 200) {
                return res.json();
            } else if (res.status === 404) {
                defaultInit();
                globalContext.setLoading && globalContext.setLoading(false);
                return null;
            } else if (res.status === 401) {
                window.location.href = "/";
                return null
            } else {
                globalContext.setLoading && globalContext.setLoading(false);
                return null;
            }
        })
        .then((data: any) => {
            if(data !== null) {
                setDiary(data);
            }
            globalContext.setLoading && globalContext.setLoading(false);
        })
        .catch(err => {
            globalContext.setLoading && globalContext.setLoading(false);
        });
    }, [selectedDate]);

    const saveDiary = async () => {
        globalContext.setLoading && globalContext.setLoading(true);
        if(diary) {
            await fetch(`${globalVariables.api}/nutrition/diary`, {
                method: diary && diary.uid ? 'PUT' : 'POST',
                credentials: 'include',
                mode: 'cors',
                headers: new Headers({
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }),
                body: JSON.stringify({...diary, date: selectedDate})
            })
            .then(async res => {
                let data = await res.json();
                if(res.status === 200) {
                    globalContext.setLoading && globalContext.setLoading(false);
                    if(!diary.uid) {
                        setDiary(data);
                    }
                } else if (res.status === 401) {
                    window.location.href = "/";
                } else {
                    globalContext.setLoading && globalContext.setLoading(false);
                    toast.error("There was a problem updating your nutrition diary.");
                }
            })
            .catch(err => {
                globalContext.setLoading && globalContext.setLoading(false);
                console.log(err);
                toast.error("There was a problem updating your nutrition diary.");
            });
        }
    }

    const calculateTotals = async () => {
        let sum: ITotals = {
            kcal: 0,
            protein: 0,
            carbohydrates: 0,
            fats: 0
        };

        if(diary && diary.data) {
            for(let i = 0; i < diary.data?.length; i++) {
                sum.kcal = sum.kcal + (diary.data[i].kcals * diary.data[i].servings);
                sum.protein = sum.protein + (diary.data[i].protein * diary.data[i].servings);
                sum.carbohydrates = sum.carbohydrates + (diary.data[i].carbohydrates * diary.data[i].servings);
                sum.fats = sum.fats + (diary.data[i].fats * diary.data[i].servings);
            }
        }

        setTotals(sum);

        if(diary && diary.data) {
            if(diary.data.length > 0 || diary.uid) {
                saveDiary();
            }
        }        
    }

    const addToDiary = async (data: any, category: string) => {
        let diaryData = diary && diary.data ? [...diary.data] : [];
        data.category = category;
        diaryData.push({...data, eid: uuidv4()});
        setDiary({...diary, data: diaryData});
    }

    const editDiary = (data: any) => {
        let diaryData = diary && diary.data ? [...diary.data] : [];

        for(let i = 0; i < diaryData.length; i++) {
            if(diaryData[i].eid === data.eid) {
                diaryData[i] = {...data};
            }
        }

        setDiary({...diary, data: diaryData});
    }

    const deleteDiary = (data: any) => {
        let diaryData = diary && diary.data ? [...diary.data] : [];

        diaryData = diaryData.filter((entry: any) => entry.eid !== data.eid);

        setDiary({...diary, data: diaryData});
    }

    useEffect(() => {
        fetchDiary();
    }, [fetchDiary]);

    useEffect(() => {
        calculateTotals();
    }, [diary]);

    useEffect(() => {
        fetchDiary();
    }, [selectedDate, fetchDiary]);

    return (
        <>
            <Header title="Nutrition" />
            <NutritionSubNav />
            <div className="horizontal-datepicker">
                <DatePicker selected={selectedDate} onChange={(date: any) => setSelectedDate(date)} withPortal dateFormat="do MMMM" />
            </div>
            <div className="home-container">
                <NutritionTarget target={nutritionData} totals={totals} />
                {diary && <NutritionDiary diaryData={diary} diaryAddition={(data: any, category: string) => addToDiary(data, category)} diaryEdit={(data: any) => editDiary(data)} diaryDelete={(data: any) => deleteDiary(data)} />}
                <div className="card-option">
                    <IconDropdown icon="options" location="right">
                        <a href="" onClick={(e) => { e.preventDefault(); resetNutrition(); }}>Reset</a>
                    </IconDropdown>
                </div>
                <RecipeCarousel />
            </div>
        </>
    );
}

export default NutritionBreakDown;