import React, { useState, useEffect, useCallback, useContext } from 'react';
import { GlobalContext } from '../../Contexts/Global/GlobalContext';
import { toast } from 'react-toastify';
import Carousel from 'react-multi-carousel';
import "react-multi-carousel/lib/styles.css";
import { Link } from 'react-router-dom';

interface IGuide {
    _id: string,
    date: Date,
    data: any,
    category: string[],
    type: string,
    premium: boolean,
    title: string,
    slug: string,
    description?: string,
    video?: string,
    image?: string
}

interface IGlobalContext {
    setLoading?: Function
}

const GuideCarousel = () => {
    const globalVariables: any = window;
    const globalContext: IGlobalContext = useContext(GlobalContext);
    const [guides, setGuides] = useState<IGuide[] | undefined>(undefined);
    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 10
          },
          desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 6
          },
          tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 3
          },
          mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1.4
          }
    }

    const fetchGuides = useCallback(async() => {
        globalContext.setLoading && globalContext.setLoading(true);
        await fetch(`${globalVariables.api}/workout/Guide`, {
            method: 'GET',
            credentials: 'include',
            mode: 'cors',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            })
        })
        .then(async (res) => {
            let data = await res.json();

            if(res.status === 200) {
                setGuides(data);
                globalContext.setLoading && globalContext.setLoading(false);
            } else {
                toast.error("There was an issue fetching plans.");
                globalContext.setLoading && globalContext.setLoading(false);
            }
        })
        .catch(err => {
            console.log(err);
            toast.error("There was an issue fetching plans.");
            globalContext.setLoading && globalContext.setLoading(false);
        });
    }, []);
    
    useEffect(() => {
        fetchGuides();
    }, []);

    return (
        <div className="guide-carousel">
            {guides &&
                <Carousel responsive={responsive}
                showDots={false}
                arrows={false}
                infinite={false}>
                    {guides.map((guide, index) => {
                        let toLink = guide.slug !== null ? `/plan/detail/${guide.slug}` : '/go-premium';
                        
                        return <Link to={toLink} className="guide-carousel-card" key={index}>
                                    <div className="guide-carousel-card__image">
                                        <img src={`${globalVariables.api}/media/${guide.image}`} alt={guide.title} />
                                    </div>
                                    <div className="guide-carousel-card__content">
                                        <p className="guide-carousel-card__title">{guide.title}</p>
                                        <p className="guide-carousel-card__details">{guide.data.length} Weeks</p>
                                        <div className="guide-carousel-card__action">
                                            <svg>
                                                <use xlinkHref="/assets/icon-library.svg#arrow-right" />
                                            </svg>
                                        </div>
                                    </div>
                                </Link>
                    }
                    )}
                </Carousel>
            }
        </div>
    );
}

export default GuideCarousel;