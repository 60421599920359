import React, { useState, useEffect, useCallback, useContext } from 'react';
import Header from '../../Components/Header/Header';
import { GlobalContext } from '../../Contexts/Global/GlobalContext';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import WorkoutSubNav from '../../Components/SubNavs/WorkoutSubNav';

interface IGuide {
    _id: string,
    date: Date,
    data: any,
    category: string[],
    type: string,
    premium: boolean,
    title: string,
    slug: string,
    description?: string,
    video?: string,
    image?: string
}

interface IGlobalContext {
    setLoading?: Function
}

const Guides = () => {
    const globalVariables: any = window;
    const globalContext: IGlobalContext = useContext(GlobalContext);
    const [guides, setGuides] = useState<IGuide[] | undefined>(undefined);

    const fetchGuides = useCallback(async () => {
        globalContext.setLoading && globalContext.setLoading(true);
        await fetch(`${globalVariables.api}/workout/Guide`, {
            method: 'GET',
            credentials: 'include',
            mode: 'cors',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            })
        })
        .then(async (res) => {
            let data = await res.json();

            if(res.status === 200) {
                setGuides(data);
                globalContext.setLoading && globalContext.setLoading(false);
            } else if (res.status === 401) {
                window.location.href = "/";
            } else {
                toast.error("There was an issue fetching plans.");
                globalContext.setLoading && globalContext.setLoading(false);
            }
        })
        .catch(err => {
            console.log(err);
            toast.error("There was an issue fetching plans.");
            globalContext.setLoading && globalContext.setLoading(false);
        });
    }, []);

    useEffect(() => {
        fetchGuides();
    }, [fetchGuides]);

    return (
        <>
            <Header title="Guides" />
            <WorkoutSubNav />
            <div className="home-container">
                {guides && guides.map((guide, index) =>
                    <Link key={index} to={`/plan/detail/${guide.slug}`} className="video-library-item">
                    <div className="video-library-item__image">
                        {guide.image &&
                            <img src={`${globalVariables.api}/media/${guide.image}`} alt={guide.title} />
                            // <img src={guide.image} />
                        }
                        {guide.slug === null &&
                            <div className="video-library-item__upgrade">
                                <div>
                                    <p>Only available for Premium Members</p>
                                    <Link to="/go-premium" className="btn">Upgrade</Link>
                                </div>
                            </div>
                        }
                    </div>
                    <div className="video-library-item__title">
                        {guide.title}
                    </div>
                </Link>
                )}
            </div>
        </>
    );
}

export default Guides;