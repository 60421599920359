import React from 'react';
import { NavLink } from 'react-router-dom';

const ProgressSubNav = () => {
    return (
        <>
            <ul className="tab-nav">
                <li className="tab-nav__item">
                    <NavLink to="/progress" strict exact activeClassName="is-active">Progress</NavLink>
                </li>
                <li className="tab-nav__item">
                    <NavLink to="/check-in" activeClassName="is-active">Check-In</NavLink>
                </li>
                <li className="tab-nav__item">
                    <NavLink to="/logbook" activeClassName="is-active">Log Book</NavLink>
                </li>
            </ul>
        </>
    );
}

export default ProgressSubNav;