import React, { useState, useCallback, useEffect } from 'react';
import NutritionDiaryPicker from './NutritionDiaryPicker';
import { string } from 'yup';

interface INutritionDiaryProps {
    diaryData?: any,
    diaryAddition: Function,
    diaryEdit: Function,
    diaryDelete: Function
}

interface IRecipe {
    _id: string,
    name: string,
    slug: string,
    ingredients: Array<string>,
    directions: Array<string>,
    kcals: number,
    protein: number,
    carbohydrates: number,
    fats: number,
    time: number,
    servings?: number,
    image?: string,
    category?: Array<string>
}

interface IPicker {
    open: boolean,
    selected: any[],
    category: string
}

interface IDiaryData {
    eid: string,
    name: string,
    kcals: number,
    protein: number,
    carbohydrates: number,
    fats: number,
    servings: number,
    image: string,
    category: string
}

interface IDetail {
    open: boolean,
    eid: string,
    [x:string]: any
}

interface IMyMeals {
    _id?: string,
    uid: string,
    items?: any[],
    recent?: string[]
}

const NutritionDiary = ({ diaryData, diaryAddition, diaryEdit, diaryDelete }: INutritionDiaryProps) => {
    const globalVariables: any = window;
    const [recipes, setRecipes] = useState<IRecipe[] | undefined>(undefined);
    const [myMeals, setMyMeals] = useState<IMyMeals | undefined>(undefined);
    const [picker, setPicker] = useState<IPicker>({ open: false, selected: [], category: "Breakfast" });
    const [detail, setDetail] = useState<IDetail>({ open: false, eid: "" });

    const fetchRecipes = useCallback(async () => {
        await fetch(`${globalVariables.api}/recipe`, {
            method: 'GET',
            credentials: 'include',
            mode: 'cors',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            })
        })
        .then(async (res) => {
            let data = await res.json();

            if(res.status === 200) {
                setRecipes(data);
            } else if (res.status === 401) {
                window.location.href = "/";
            } else {
                console.log(res);
            }
        })
        .catch(err => {
            console.log(err);
        });
    }, []);

    const fetchMyMeals = useCallback(async () => {
        await fetch(`${globalVariables.api}/nutrition/personal`, {
            method: 'GET',
            credentials: 'include',
            mode: 'cors',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            })
        })
        .then(async (res) => {
            let data = await res.json();

            if(res.status === 200) {
                setMyMeals(data);
            } else if (res.status === 401) {
                window.location.href = "/";
            } else {
                console.log(res);
            }
        })
        .catch(err => {
            console.log(err);
        });
    }, []);

    const updateDiaryEntry = () => {
        let data = {...detail};
        setDetail({ open: false, eid: ""});
        diaryEdit(data);
    }

    const deleteDiaryEntry = () => {
        let data = {...detail};
        setDetail({ open: false, eid: ""});
        diaryDelete(data);
    }

    useEffect(() => {
        fetchRecipes();
        fetchMyMeals();
    }, [fetchRecipes, fetchMyMeals]);

    return (
        <>
        <div className="internal-container grey">
            <>
                <h2 className="mb-1-5">Breakfast</h2>
                {diaryData.data.map((entry: IDiaryData, index: number) => 
                    <>
                    {entry.category === "Breakfast" &&
                        <div className="nutrition-diary-card" key={index} onClick={() => setDetail({...entry, open: true})}>
                            <div className="nutrition-diary-card__image">
                                {entry.image &&
                                    <img src={`${globalVariables.api}/media/${entry.image}`} alt={entry.name} />
                                }
                            </div>
                            <div className="nutrition-diary-card__inner">
                                <div className="nutrition-diary-card__title">{entry.name}</div>
                                <div className="nutrition-diary-card__nutrition">
                                    <div className="nutrition-diary-card__stat">
                                        {(entry.kcals * entry.servings).toFixed(0)} kcal
                                    </div>
                                    <div className="nutrition-diary-card__stat protein">
                                        <span></span>
                                        {(entry.protein * entry.servings).toFixed(0)}g
                                    </div>
                                    <div className="nutrition-diary-card__stat carbohydrates">
                                        <span></span>
                                        {(entry.carbohydrates * entry.servings).toFixed(0)}g
                                    </div>
                                    <div className="nutrition-diary-card__stat fats">
                                        <span></span>
                                        {(entry.fats * entry.servings).toFixed(0)}g
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    </>
                )}
                <button className="guide-add small mb-2" onClick={() => setPicker({...picker, open: true, category: "Breakfast"})}>Add +</button>
            </>
            <>
                <h2 className="mb-1-5">Lunch</h2>
                {diaryData.data.map((entry: IDiaryData, index: number) => 
                    <>
                    {entry.category === "Lunch" &&
                        <div className="nutrition-diary-card" key={index} onClick={() => setDetail({...entry, open: true})}>
                            <div className="nutrition-diary-card__image">
                                {entry.image &&
                                    <img src={`${globalVariables.api}/media/${entry.image}`} alt={entry.name} />
                                }
                            </div>
                            <div className="nutrition-diary-card__inner">
                                <div className="nutrition-diary-card__title">{entry.name}</div>
                                <div className="nutrition-diary-card__nutrition">
                                    <div className="nutrition-diary-card__stat">
                                        {(entry.kcals * entry.servings).toFixed(0)} kcal
                                    </div>
                                    <div className="nutrition-diary-card__stat protein">
                                        <span></span>
                                        {(entry.protein * entry.servings).toFixed(0)}g
                                    </div>
                                    <div className="nutrition-diary-card__stat carbohydrates">
                                        <span></span>
                                        {(entry.carbohydrates * entry.servings).toFixed(0)}g
                                    </div>
                                    <div className="nutrition-diary-card__stat fats">
                                        <span></span>
                                        {(entry.fats * entry.servings).toFixed(0)}g
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    </>
                )}
                <button className="guide-add small mb-2" onClick={() => setPicker({...picker, open: true, category: "Lunch"})}>Add +</button>
            </>
            <>
                <h2 className="mb-1-5">Dinner</h2>
                {diaryData.data.map((entry: IDiaryData, index: number) => 
                    <>
                    {entry.category === "Dinner" &&
                        <div className="nutrition-diary-card" key={index} onClick={() => setDetail({...entry, open: true})}>
                            <div className="nutrition-diary-card__image">
                                {entry.image &&
                                    <img src={`${globalVariables.api}/media/${entry.image}`} alt={entry.name} />
                                }
                            </div>
                            <div className="nutrition-diary-card__inner">
                                <div className="nutrition-diary-card__title">{entry.name}</div>
                                <div className="nutrition-diary-card__nutrition">
                                    <div className="nutrition-diary-card__stat">
                                        {(entry.kcals * entry.servings).toFixed(0)} kcal
                                    </div>
                                    <div className="nutrition-diary-card__stat protein">
                                        <span></span>
                                        {(entry.protein * entry.servings).toFixed(0)}g
                                    </div>
                                    <div className="nutrition-diary-card__stat carbohydrates">
                                        <span></span>
                                        {(entry.carbohydrates * entry.servings).toFixed(0)}g
                                    </div>
                                    <div className="nutrition-diary-card__stat fats">
                                        <span></span>
                                        {(entry.fats * entry.servings).toFixed(0)}g
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    </>
                )}
                <button className="guide-add small mb-2" onClick={() => setPicker({...picker, open: true, category: "Dinner"})}>Add +</button>
            </>
            <>
                <h2 className="mb-1-5">Snacks</h2>
                {diaryData.data.map((entry: IDiaryData, index: number) => 
                    <>
                    {entry.category === "Snacks" &&
                        <div className="nutrition-diary-card" key={index} onClick={() => setDetail({...entry, open: true})}>
                            <div className="nutrition-diary-card__image">
                                {entry.image &&
                                    <img src={`${globalVariables.api}/media/${entry.image}`} alt={entry.name} />
                                }
                            </div>
                            <div className="nutrition-diary-card__inner">
                                <div className="nutrition-diary-card__title">{entry.name}</div>
                                <div className="nutrition-diary-card__nutrition">
                                    <div className="nutrition-diary-card__stat">
                                        {(entry.kcals * entry.servings).toFixed(0)} kcal
                                    </div>
                                    <div className="nutrition-diary-card__stat protein">
                                        <span></span>
                                        {(entry.protein * entry.servings).toFixed(0)}g
                                    </div>
                                    <div className="nutrition-diary-card__stat carbohydrates">
                                        <span></span>
                                        {(entry.carbohydrates * entry.servings).toFixed(0)}g
                                    </div>
                                    <div className="nutrition-diary-card__stat fats">
                                        <span></span>
                                        {(entry.fats * entry.servings).toFixed(0)}g
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    </>
                )}
                <button className="guide-add small" onClick={() => setPicker({...picker, open: true, category: "Snacks"})}>Add +</button>
            </>
        </div>
        {recipes &&
            <NutritionDiaryPicker open={picker.open} closeModal={() => setPicker({open: false, selected: [], category: "Breakfast"})} recipes={recipes} myMeals={myMeals} setMyMeals={(data: any) => setMyMeals(data)} diaryAddition={(data: any) => { setPicker({...picker, open: false}); diaryAddition(data, picker.category)}} />
        }
        <div className={`overlay level-2 ${detail.open ? 'is-active' : ''}`} onClick={() => setDetail({...detail, open: false})}></div>
            <div className={`modal level-2 ${detail.open ? 'is-open' : ''}`}>
                {detail.open &&
                    <>
                        <h2>{detail.name}</h2>
                        <div className="nutrition-diary-card__nutrition">
                            <div className="nutrition-diary-card__stat">
                                {(detail.kcals * detail.servings).toFixed(0)} kcal
                            </div>
                            <div className="nutrition-diary-card__stat protein">
                                <span></span>
                                {(detail.protein * detail.servings).toFixed(0)}g
                            </div>
                            <div className="nutrition-diary-card__stat carbohydrates">
                                <span></span>
                                {(detail.carbohydrates * detail.servings).toFixed(0)}g
                            </div>
                            <div className="nutrition-diary-card__stat fats">
                                <span></span>
                                {(detail.fats * detail.servings).toFixed(0)}g
                            </div>
                        </div>
                        <div className="form-item mt-1">
                            <label>Servings</label>
                            <input type="number" step={0.1} min="0" value={detail.servings} onChange={(e) => setDetail({...detail, servings: parseFloat(e.target.value)})} />
                        </div>
                        <div className="modal__actions">
                            <button className="btn btn--text" onClick={() => setDetail({...detail, open: false})}>Cancel</button>
                            <button className="btn" onClick={() => updateDiaryEntry()}>Update</button>
                        </div>
                        <button className="delete" onClick={() => deleteDiaryEntry()}>
                            <svg>
                                <use xlinkHref="/assets/icon-library.svg#trash" />
                            </svg>
                        </button>
                    </>
                }

            </div>
        </>
    );
}

export default NutritionDiary;