import React, { useContext, useEffect, useState } from 'react';

// Context
import { LoggingContext } from '../../Contexts/LoggingContext/LoggingContext';

interface ILoggingContext {
    state?: any,
    setLoading?: Function
}

interface ILogBookEntryExerciseProps {
    exercise: any
}

const LogBookEntryExercise = ({ exercise }: ILogBookEntryExerciseProps) => {
    const loggingContext: ILoggingContext = useContext(LoggingContext);
    const [exerciseData, setExerciseData] = useState<any>(undefined);
    const alphabet: string[] = ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z"];

    useEffect(() => {
        if(loggingContext.state && loggingContext.state.exercises) {
            let data = loggingContext.state.exercises.filter((e: any) => e._id === exercise.eid)[0];

            setExerciseData(data);
        }
    }, []);

    return (
        <div className="mb-1">
        {exerciseData &&
            <>
            <p><b>{exercise.superset ? `a. ${exerciseData.name}` : exerciseData.name}</b></p>
            {exercise.superset && exercise.supersetExercises.map((exercise: any, index: any) =>
                <p><b>{alphabet[index + 1]}. {loggingContext.state.exercises.filter((e: any) => e._id === exercise.eid)[0].name}</b></p>
            )}
            {exercise.sets.map((set: any, index: number) =>
                <>
                    <div className="log-set">
                        <span className="log-set__no">Set {index + 1}{exercise.superset && 'a'}</span>
                        {set.log &&
                            <>
                                {set.log.reps && <span className="log-set__stat">{set.log.reps} <span>Reps</span></span>}
                                {set.log.weight && <span className="log-set__stat">{set.log.weight} <span>KG</span></span>}
                            </>
                        }
                        {set.type === "liss" && <span className="log-set__stat">{set.liss.duration} <span>Mins</span></span>}
                        {set.type === "hiit" && <span className="log-set__stat">{set.hiit.on}s <span>ON</span></span>} 
                        {set.type === "hiit" && <span className="log-set__stat">{set.hiit.off}s <span>OFF</span></span>}
                        {set.type === "hiit" && <span className="log-set__stat">{set.hiit.duration}<span>Mins</span></span>} 
                    </div>
                    {exercise.superset && exercise.supersetExercises.map((exercise: any, j: number) =>
                        <>
                            {exercise.sets &&
                                <div className="log-set">
                                    <span className="log-set__no">Set {index + 1}{alphabet[j + 1]}</span>
                                    {exercise.sets[index].log &&
                                        <>
                                            {exercise.sets[index].log.reps && <span className="log-set__stat">{exercise.sets[index].log.reps} <span>Reps</span></span>}
                                            {exercise.sets[index].log.weight && <span className="log-set__stat">{exercise.sets[index].log.weight} <span>KG</span></span>}
                                        </>
                                    }
                                    {exercise.sets[index].type === "liss" && <span className="log-set__stat">{exercise.sets[index].liss.duration} <span>Mins</span></span>}
                                    {exercise.sets[index].type === "hiit" && <span className="log-set__stat">{exercise.sets[index].hiit.on}s <span>ON</span></span>} 
                                    {exercise.sets[index].type === "hiit" && <span className="log-set__stat">{exercise.sets[index].hiit.off}s <span>OFF</span></span>}
                                    {exercise.sets[index].type === "hiit" && <span className="log-set__stat">{exercise.sets[index].hiit.duration}<span>Mins</span></span>} 
                                </div>
                            }
                        </>
                    )}
                </>
            )}
            </>
        }
        </div>
    );
}

export default LogBookEntryExercise;