import React from 'react';
import { useHistory } from 'react-router-dom';

interface IHeaderProps {
    title: string
}

const Header = ({ title }: IHeaderProps) => {
    let history = useHistory();

    return (
        <div className="page-header">
            <div className="page-header__inner">
                <button onClick={() => history.goBack()}>
                    <svg>
                        <use xlinkHref="/assets/icon-library.svg#arrow-left" />
                    </svg>
                </button>
                <h1>{title}</h1>
            </div>
        </div>
    );
}

export default Header;