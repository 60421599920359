import React, { useState, useContext, useCallback, useEffect } from 'react';
import moment from 'moment';
import Header from '../../Components/Header/Header';
import { GlobalContext } from '../../Contexts/Global/GlobalContext';
import { toast } from 'react-toastify';
import WeightChart from '../../Components/WeightChart/WeightChart';
import CheckInAdjustment from './CheckInAdjustment';
import PremiumOnly from '../PremiumOnly/PremiumOnly';
import ProgressSubNav from '../../Components/SubNavs/ProgressSubNav';

interface IGlobalContext {
    setLoading?: Function
}

interface INutrition {
    _id?: string,
    uid?: string,
    date?: Date,
    kcal?: number,
    protein?: number,
    carbohydrates?: number,
    fats?: number
}

interface ICheckIn {
    _id: string,
    uid: string,
    weight: number,
    date: Date,
    mood: string,
    neck?: number,
    arm?: number,
    chest?: number,
    waist?: number,
    glutes?: number,
    thigh?: number,
    calf?: number
}

interface ICheckInProps {
    history?: any
}

interface IView {
    label: string,
    chartkey: string
}

const CheckIn = ({ history }: ICheckInProps) => {
    const globalVariables: any = window;
    const globalContext: IGlobalContext = useContext(GlobalContext);
    const [checkInToggle, setCheckInToggle] = useState<boolean>(false);
    const [advancedToggle, setAdvancedToggle] = useState<boolean>(false);
    const [weight, setWeight] = useState<any>(0.00);
    const [neck, setNeck] = useState<any>(0.00);
    const [arm, setArm] = useState<any>(0.00);
    const [chest, setChest] = useState<any>(0.00);
    const [waist, setWaist] = useState<any>(0.00);
    const [glutes, setGlutes] = useState<any>(0.00);
    const [thigh, setThigh] = useState<any>(0.00);
    const [calf, setCalf] = useState<any>(0.00);
    const [checkInError, setCheckInError] = useState<string | undefined>(undefined);
    const [mood, setMood] = useState<string>("happy");
    const [checkIns, setCheckIns] = useState<ICheckIn[] | undefined>(undefined);
    const [allowCheckin, setAllowCheckin] = useState<boolean>(false);
    const [checkInDue, setCheckInDue] = useState<String>("");
    const [nutritionData, setNutritionData] = useState<INutrition | undefined>(undefined);
    const [adjustmentModal, setAdjustmentModal] = useState<boolean>(false);
    const [view, setView] = useState<IView>({label: "Body weight", chartkey: "weight"});

    const fetchCheckIns = useCallback(async () => {
        globalContext.setLoading && globalContext.setLoading(true);

        await fetch(`${globalVariables.api}/checkin`, {
            method: 'GET',
            credentials: 'include',
            mode: 'cors',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            })
        })
        .then(async (res) => {
            let data = await res.json();

            if(res.status === 200) {
                setCheckIns(data.length > 0 ? data : undefined);
                if(data.length === 0 || moment(moment(data[0].date).startOf('day').toDate()).diff(moment(), 'days') < -6) {
                    setAllowCheckin(true);
                } else {
                    setCheckInDue(moment(data[0].date).add(1, "week").format("Do MMMM"));
                }
                globalContext.setLoading && globalContext.setLoading(false);
            } else if (res.status === 401) {
                window.location.href = "/";
            } else {
                globalContext.setLoading && globalContext.setLoading(false);
                toast.error("There was an issue fetching check-in data. Please try again.");
            }
        })
        .catch(err => {
            globalContext.setLoading && globalContext.setLoading(false);
            toast.error("There was an issue fetching check-in data. Please try again.");
        });
    }, []);

    useEffect(() => {
        fetchCheckIns();
    }, [fetchCheckIns]);

    const handleCheckInSubmit = async () => {
        globalContext.setLoading && globalContext.setLoading(true);

        if(weight === 0) {
            globalContext.setLoading && globalContext.setLoading(false);
            setCheckInError("Please enter a valid weight.");
        } else {
            setCheckInError("");
            const formData = {
                weight: weight,
                mood: mood,
                date: new Date(),
                neck: neck > 0 ? neck : undefined,
                arm: arm > 0 ? arm : undefined,
                chest: chest > 0 ? chest : undefined,
                waist: waist > 0 ? waist : undefined,
                glutes: glutes > 0 ? glutes : undefined,
                thigh: thigh > 0 ? thigh : undefined,
                calf: calf > 0 ? calf : undefined
            }
            await fetch(`${globalVariables.api}/checkin`, {
                method: 'POST',
                credentials: 'include',
                mode: 'cors',
                headers: new Headers({
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }),
                body: JSON.stringify(formData)
            })
            .then(async (res) => {
                if(res.status === 200) {
                    let data = await res.json();
                    setCheckIns(data.checkIns);
                    setCheckInToggle(false);
                    setAdvancedToggle(false);
                    setWeight(0.00);
                    setNeck(0.00);
                    setArm(0.00);
                    setChest(0.00);
                    setWaist(0.00);
                    setGlutes(0.00);
                    setThigh(0.00);
                    setCalf(0.00);
                    setMood("happy");

                    if(data.checkIns.length === 0 || moment(moment(data.checkIns[0].date).startOf('day').toDate()).diff(moment(), 'days') < -6) {
                        setAllowCheckin(true);
                    } else {
                        setAllowCheckin(false);
                        setCheckInDue(moment(data.checkIns[0].date).add(1, "week").format("Do MMMM"));
                    }

                    if(data.adjustment) {
                        setNutritionData(data.adjustmentData);
                        setAdjustmentModal(true);
                    } else {
                        toast.success("Your Check-In has been saved.");
                    }

                    globalContext.setLoading && globalContext.setLoading(false);
                } else if (res.status === 401) {
                    window.location.href = "/";
                } else {
                    toast.error("There was a problem checking-in. Please try again.")
                    globalContext.setLoading && globalContext.setLoading(false);
                }
            })
            .catch(err => {
                toast.error("Server error. Please try again.");
                globalContext.setLoading && globalContext.setLoading(false);
            });
        }
    }

    return (
        <PremiumOnly history={history}>
        <>
            <Header title="Check-In" />
            <ProgressSubNav />
            <div className="home-container">
                <div className="expander">
                    <div className="expander__heading">
                        <h2>New Check-In</h2>
                        <button className="icon-btn" onClick={() => setCheckInToggle(!checkInToggle)}>
                            {checkInToggle ? '-' : '+'}
                        </button>
                    </div>
                    <div className={`expander__content ${checkInToggle ? 'is-open' : ''}`}>
                        {allowCheckin ?
                            <>
                            <div className="form-item">
                                <label>Weight (KG)</label>
                                <input type="number" step="0.01" min="0.00" name="weight" value={weight} onChange={(e) => setWeight(parseFloat(e.target.value))} />
                            </div>
                            <div className="expander">
                                <div className="expander__heading">
                                    <a href="#0" onClick={(e) => {e.preventDefault(); setAdvancedToggle(!advancedToggle)}}>Advanced (optional) {advancedToggle ? '-' : '+'}</a>
                                </div>
                                <div className={`expander__content ${advancedToggle ? 'is-open' : ''}`}>
                                    <div className="form-item">
                                        <label>Neck (cm)</label>
                                        <input type="number" step="0.01" min="0.00" name="neck" value={neck} onChange={(e) => setNeck(parseFloat(e.target.value))} />
                                    </div>
                                    <div className="form-item">
                                        <label>Arm (cm)</label>
                                        <input type="number" step="0.01" min="0.00" name="arm" value={arm} onChange={(e) => setArm(parseFloat(e.target.value))} />
                                    </div>
                                    <div className="form-item">
                                        <label>Chest (cm)</label>
                                        <input type="number" step="0.01" min="0.00" name="chest" value={chest} onChange={(e) => setChest(parseFloat(e.target.value))} />
                                    </div>
                                    <div className="form-item">
                                        <label>Waist (cm)</label>
                                        <input type="number" step="0.01" min="0.00" name="waist" value={waist} onChange={(e) => setWaist(parseFloat(e.target.value))} />
                                    </div>
                                    <div className="form-item">
                                        <label>Glutes (cm)</label>
                                        <input type="number" step="0.01" min="0.00" name="glutes" value={glutes} onChange={(e) => setGlutes(parseFloat(e.target.value))} />
                                    </div>
                                    <div className="form-item">
                                        <label>Thigh (cm)</label>
                                        <input type="number" step="0.01" min="0.00" name="thigh" value={thigh} onChange={(e) => setThigh(parseFloat(e.target.value))} />
                                    </div>
                                    <div className="form-item">
                                        <label>Calf (cm)</label>
                                        <input type="number" step="0.01" min="0.00" name="calf" value={calf} onChange={(e) => setCalf(parseFloat(e.target.value))} />
                                    </div>
                                </div>
                            </div>
                            <div className="form-item">
                                <button className="btn btn--block" onClick={() => handleCheckInSubmit()}>Submit</button>
                            </div>
                            </>
                        :
                            <p>Next check-in not due until {checkInDue}.</p>
                        }
                        {checkInError && <div className="form-error">{checkInError}</div>}
                    </div>
                </div>
                <div className="internal-container">
                    {checkIns ?
                        <>
                        <WeightChart data={[...checkIns].reverse()} chartkey={view.chartkey} label={view.label} />
                        <div className="metric-grid">
                            <button className={`metric-item ${view.chartkey === "weight" ? 'is-selected': ''}`} onClick={() => setView({label: "Body weight", chartkey: "weight"})}>
                                <div className="metric-item__inner">
                                    <span className="metric-item__label">Weight</span>
                                    <div className="metric-item__main">
                                        <span className="metric-item__value">{checkIns[0].weight ? checkIns[0].weight : '----'}</span>
                                        <span className="metric-item__unit">kg</span>
                                    </div>
                                </div>
                            </button>
                            <button className={`metric-item ${view.chartkey === "neck" ? 'is-selected': ''}`} onClick={() => setView({label: "Neck", chartkey: "neck"})}>
                                <div className="metric-item__inner">
                                    <span className="metric-item__label">Neck</span>
                                    <div className="metric-item__main">
                                        <span className="metric-item__value">{checkIns[0].neck ? checkIns[0].neck : '----'}</span>
                                        <span className="metric-item__unit">cm</span>
                                    </div>
                                </div>
                            </button>
                            <button className={`metric-item ${view.chartkey === "arm" ? 'is-selected': ''}`} onClick={() => setView({label: "Arm", chartkey: "arm"})}>
                                <div className="metric-item__inner">
                                    <span className="metric-item__label">Arm</span>
                                    <div className="metric-item__main">
                                        <span className="metric-item__value">{checkIns[0].arm ? checkIns[0].arm : '----'}</span>
                                        <span className="metric-item__unit">cm</span>
                                    </div>
                                </div>
                            </button>
                            <button className={`metric-item ${view.chartkey === "chest" ? 'is-selected': ''}`} onClick={() => setView({label: "Chest", chartkey: "chest"})}>
                                <div className="metric-item__inner">
                                    <span className="metric-item__label">Chest</span>
                                    <div className="metric-item__main">
                                        <span className="metric-item__value">{checkIns[0].chest ? checkIns[0].chest : '----'}</span>
                                        <span className="metric-item__unit">cm</span>
                                    </div>
                                </div>
                            </button>
                            <button className={`metric-item ${view.chartkey === "waist" ? 'is-selected': ''}`} onClick={() => setView({label: "Waist", chartkey: "waist"})}>
                                <div className="metric-item__inner">
                                    <span className="metric-item__label">Waist</span>
                                    <div className="metric-item__main">
                                        <span className="metric-item__value">{checkIns[0].waist ? checkIns[0].waist : '----'}</span>
                                        <span className="metric-item__unit">cm</span>
                                    </div>
                                </div>
                            </button>
                            <button className={`metric-item ${view.chartkey === "glutes" ? 'is-selected': ''}`} onClick={() => setView({label: "Glutes", chartkey: "glutes"})}>
                                <div className="metric-item__inner">
                                    <span className="metric-item__label">Glutes</span>
                                    <div className="metric-item__main">
                                        <span className="metric-item__value">{checkIns[0].glutes ? checkIns[0].glutes : '----'}</span>
                                        <span className="metric-item__unit">cm</span>
                                    </div>
                                </div>
                            </button>
                            <button className={`metric-item ${view.chartkey === "thigh" ? 'is-selected': ''}`} onClick={() => setView({label: "Thigh", chartkey: "thigh"})}>
                                <div className="metric-item__inner">
                                    <span className="metric-item__label">Thigh</span>
                                    <div className="metric-item__main">
                                        <span className="metric-item__value">{checkIns[0].thigh ? checkIns[0].thigh : '----'}</span>
                                        <span className="metric-item__unit">cm</span>
                                    </div>
                                </div>
                            </button>
                            <button className={`metric-item ${view.chartkey === "calf" ? 'is-selected': ''}`} onClick={() => setView({label: "Calf", chartkey: "calf"})}>
                                <div className="metric-item__inner">
                                    <span className="metric-item__label">Calf</span>
                                    <div className="metric-item__main">
                                        <span className="metric-item__value">{checkIns[0].calf ? checkIns[0].calf : '----'}</span>
                                        <span className="metric-item__unit">cm</span>
                                    </div>
                                </div>
                            </button>
                        </div>
                        </>
                        :
                        <>
                            <p>You currently have no check-ins.</p> 
                            <p>A chart and history of your check-ins will display here letting you see your progress over time!</p>
                            <p>To get started - add your first check-in above!</p>
                        </>
                    }
                </div>
                <>
                {checkIns &&
                    <>
                    <div className="list-item mt-1"><b>History</b></div>
                    {checkIns.length > 0 ?
                        <>
                        {checkIns.map((checkin, index) => 
                            <div className="list-item" key={index}>
                                {moment(checkin.date).format("Do MMM YYYY")}
                                <span>{checkin.weight}kg</span>
                            </div>
                        )}
                        </>
                        :
                        <div className="list-item">A history of your check-ins will display here.</div>
                    }
                    </>
                }
                </>
            </div>
            <CheckInAdjustment open={adjustmentModal} nutritionData={nutritionData} close={() => setAdjustmentModal(false)} />
        </>
        </PremiumOnly>
    );
}

export default CheckIn;

// TEMP KEEP INCASE REVERT - DELETE AFTER JUNE 2020

{/* <label>Mood</label>
<div className="mood-buttons">
    <button className={`mood-btn ${mood === "sad" ? 'is-selected' : ''}`} onClick={() => setMood("sad")}>
        <svg>
            <use xlinkHref="/assets/icon-library.svg#sad" />
        </svg>
    </button>
    <button className={`mood-btn ${mood === "meh" ? 'is-selected' : ''}`} onClick={() => setMood("meh")}>
        <svg>
            <use xlinkHref="/assets/icon-library.svg#meh" />
        </svg>
    </button>
    <button className={`mood-btn ${mood === "happy" ? 'is-selected' : ''}`} onClick={() => setMood("happy")}>
        <svg>
            <use xlinkHref="/assets/icon-library.svg#happy" />
        </svg>
    </button>
</div> */}