import React, { useState, useEffect, useCallback, useContext } from 'react';
import { useParams, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Header from '../../Components/Header/Header';
import Icon from '../../Components/Icon/Icon';

// Contexts
import { GlobalContext } from '../../Contexts/Global/GlobalContext';

interface IWorkout {
    _id: string,
    title: string,
    slug: string,
    duration?: number,
    description?: string,
    video?: string,
    image?: string,
    date: Date,
    type: string
    [x:string]: any
}

interface IGlobalContext {
    state?: any,
    setIsLoggedIn?: Function,
    setLoading?: Function,
    setInitialAppData?: Function,
    setFavourites?: Function,
}

const WorkoutDetail = () => {
    const [overlayOpen, setOverlayOpen] = useState(false);
    const [workout, setWorkout] = useState<IWorkout | undefined>(undefined);
    const globalVariables: any = window;

    const globalContext: IGlobalContext = useContext(GlobalContext);

    const { slug } = useParams();
    
    const getWorkout = useCallback(async () => {
        let globalVariables: any = window;

        await fetch(`${globalVariables.api}/workout/detail/${slug}`, {
            method: 'GET',
            credentials: 'include',
            mode: 'cors',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            })
        })
        .then(async (res) => {
            let data = await res.json();

            if(res.status === 200) {
                setWorkout(data);
            } else if (res.status === 401) {
                window.location.href = "/";
            } else {
                // setIsLoggedIn(false);
                // setInitialised(true);
                // setLoading(false);
            }
        })
        .catch(err => {
            console.log(err);
            // setIsLoggedIn(false);
            // setInitialised(true);
        });
    }, []);

    useEffect(() => {
        getWorkout();
    }, [getWorkout]);

    const handleWorkoutComplete = async () => {
        let wid = workout?._id,
            date = new Date();

        globalContext.setLoading && globalContext.setLoading(true);

        await fetch(`${globalVariables.api}/log`, {
            method: 'POST',
            credentials: 'include',
            mode: 'cors',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }),
            body: JSON.stringify({ wid, date })
        })
        .then(res => {
            if(res.status === 200) {
                globalContext.setLoading && globalContext.setLoading(false);
                setOverlayOpen(false);
                toast.success("Workout complete - well done!");
            } else if (res.status === 500) {
                globalContext.setLoading && globalContext.setLoading(false);
                toast.error("There has been a server error. Please try again.");
            } else {
                globalContext.setLoading && globalContext.setLoading(false);
                toast.error("There has been a server error. Please try again.");
            }
        })
        .catch(err => {
            globalContext.setLoading && globalContext.setLoading(false);
            toast.error("There has been a server error. Please try again.");
        });
    }

    const handleFavouriteClick = (workoutid: string) => {
        let data = {...globalContext.state.favourites};

        if(data.workouts.length > 0 && data.workouts.includes(workoutid)) {
            data.workouts = data.workouts.filter((workout: any) => workout !== workoutid);
        } else {
            data.workouts.push(workoutid);
        }

        globalContext.setFavourites && globalContext.setFavourites(data);
    }

    return (
        <>
        <Header title="Workout Detail" />
        <div className="home-container">
        {workout ?
            <>
                <div className="btn-float-container">
                    <div className="split-layout">
                    <div className="video-library-item detail">
                        <div className="video-library-item__image">
                            {workout.image && <img src={`${globalVariables.api}/media/${workout.image}`} alt={workout.title} />}
                            {workout.duration &&
                                <div className="video-library-item__time">
                                    <svg>
                                        <use xlinkHref="/assets/icon-library.svg#stopwatch" />
                                    </svg>
                                    <span>{workout.duration} mins</span>
                                </div>
                            }
                            <button className={`favourite-btn ${globalContext.state.favourites && globalContext.state.favourites.workouts.includes(workout._id) ? 'is-active' : ''}`} onClick={() => handleFavouriteClick(workout._id)}>
                                {globalContext.state.favourites && globalContext.state.favourites.workouts.includes(workout._id) ?
                                <svg>
                                    <use xlinkHref="/assets/icon-library.svg#star-solid" />
                                </svg>
                                :
                                <svg>
                                    <use xlinkHref="/assets/icon-library.svg#star-outline" />
                                </svg>
                                }
                            </button>
                        </div>
                    </div>
                    <div>
                        <div className="video-library-item__title detail">
                            {workout.title}
                        </div>
                        {workout.description &&
                            <div dangerouslySetInnerHTML={{__html: workout.description}}></div>
                        }
                    </div>
                    </div>
                    <div className="btn-float-bottom">
                    {workout.type === "singular" ?
                        <Link to={`/logging/single/${workout.slug}`} className="btn btn--block" onClick={() => setOverlayOpen(true)}>
                            Let's Begin   
                        </Link>
                     :
                        <button className="btn btn--block" onClick={() => setOverlayOpen(true)}>
                            Let's Begin   
                        </button>
                    }
                    </div>
                </div>
                <div className={`video-overlay ${overlayOpen? 'is-open': ''}`}>
                    <button className="video-overlay__close" onClick={() => setOverlayOpen(false)}>
                        <svg>
                            <use xlinkHref="/assets/icon-library.svg#close" />
                        </svg>
                    </button>
                    <div className="video-overlay__media">
                        {workout.video && overlayOpen &&
                            <iframe src={`https://player.vimeo.com/video/${workout.video}`} frameBorder="0" allow="autoplay; fullscreen"></iframe>
                        }
                    </div>
                    <div className="video-overlay__action">
                        <button className="btn btn--green btn--block" onClick={() => handleWorkoutComplete()}>
                            Complete   
                        </button>
                    </div>
                </div>
            </>
        :
            <p>Loading</p>
        }
        </div>
        </>
    );
}

export default WorkoutDetail;