import React, { useState } from 'react';
import LoggingTimerCountDown from './LoggingTimerCountDown';

const LoggingRoundsExercise = ({exerciseData, exerciseInstructions, prevExercise, nextExercise, exerciseId, round}: any) => {
    const [countdownStart, setCountdownStart] = useState<boolean>(false);
    return (
        <>
        <div className="home-container home-container--superset mb-2" id={exerciseId}>
            <div className="workout-exercise">
                <div className="challenge-exercise__video">
                    <iframe src={`https://player.vimeo.com/video/${exerciseData.video}?muted=1`} frameBorder="0" allow="autoplay; fullscreen"></iframe>
                </div>
                <div className="workout-exercise__title">
                    <p>{exerciseData.name}</p>
                </div>
                {exerciseInstructions.sets[round - 1].type === "reps" &&
                <>
                <div className="workout-set__target">
                    <div>
                        <label>Target</label>
                        <p>{exerciseInstructions.sets[round - 1].reps.target} <span>Reps</span></p>
                    </div>
                </div>
                </>
            }
            {exerciseInstructions.sets[round - 1].type === "range" &&
                <>
                <div className="workout-set__target">
                    <div>
                        <label>Target</label>
                        <p>{exerciseInstructions.sets[round - 1].range.from}-{exerciseInstructions.sets[round - 1].range.to} <span>Reps</span></p>
                    </div>
                </div>
                </>
            }
            {exerciseInstructions.sets[round - 1].type === "liss" &&
                <>
                    <div>
                        <LoggingTimerCountDown time={exerciseInstructions.sets[round - 1].liss.duration * 60} start={countdownStart} stopTimer={() => setCountdownStart(false)} />
                    </div>
                </>
            }
            <div className="superset-actions">
                <div className="superset-actions__col">
                    {prevExercise && <a className="icon-btn icon-btn--lrg" onClick={() => setCountdownStart(false)} href={`#${prevExercise}`}><svg><use xlinkHref="/assets/icon-library.svg#arrow-up" /></svg></a>}
                </div>
                <div className="superset-actions__col">
                    {exerciseInstructions.sets[round - 1].type === "liss" && <button className="icon-btn icon-btn--lrg icon-btn--orange" onClick={() => setCountdownStart(!countdownStart)}>{countdownStart ? <svg><use xlinkHref="/assets/icon-library.svg#stop" /></svg> : <svg><use xlinkHref="/assets/icon-library.svg#play" /></svg>}</button>}
                </div>
                <div className="superset-actions__col">
                    {nextExercise && <a className="icon-btn icon-btn--lrg" onClick={() => setCountdownStart(false)} href={`#${nextExercise}`}><svg><use xlinkHref="/assets/icon-library.svg#arrow-down" /></svg></a>}
                </div>
            </div>
            </div>
        </div>
        </>
    );
}

export default LoggingRoundsExercise;