import React, { useState, useEffect, useCallback } from 'react';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';

interface INutrition {
    _id: string,
    uid: string,
    date: Date,
    kcal: number,
    protein: number,
    carbohydrates: number,
    fats: number
}

const NutritionCard = () => {
    const globalVariables: any = window;
    const [loading, setLoading] = useState<boolean>(true);
    const [nutrition, setNutrition] = useState<INutrition | undefined>(undefined)

    const getNutrition = useCallback(async () => {
        await fetch(`${globalVariables.api}/nutrition/current`, {
            method: 'GET',
            credentials: 'include',
            mode: 'cors',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            })
        })
        .then(res => {
            if(res.status === 200) {
                return res.json();
            } else if (res.status === 404) {
                setLoading(false);
            } else if (res.status === 401) {
                window.location.href = "/";
            } else {
                setLoading(false);
                toast.error("There was a problem getting your nutrition information. Please try again.");
            }
        })
        .then(res => {
            setNutrition(res);
            setLoading(false);
        })
        .catch(err => {
            setLoading(false);
            toast.error("There was a problem getting your nutrition information. Please try again.");
        });
    }, []);

    useEffect(() => {
        getNutrition();
    }, [getNutrition]);

    return (
        <div className="nutrition-card">
            {!loading &&
                <>
                {nutrition ? 
                <>
                    <h2 className="section-title section-title--icon">
                        <svg>
                            <use xlinkHref="/assets/icon-library.svg#nutrition" />
                        </svg>
                        Todays Nutrition
                    </h2>
                    <div className="macro-breakdown">
                        <Link to="/nutrition" className="macro-breakdown__pcf">
                            <div className="macro-breakdown__item protein">
                                <p>{nutrition.kcal} <span>kcals</span></p>
                            </div>
                            <div className="macro-breakdown__item protein">
                                <p>{nutrition.protein}g <span>Protein</span></p>
                            </div>
                            <div className="macro-breakdown__item carbs">
                                <p>{nutrition.carbohydrates}g <span>Carbs</span></p>
                            </div>
                            <div className="macro-breakdown__item fats">
                                <p>{nutrition.fats}g <span>Fats</span></p>
                            </div>
                        </Link>
                    </div>
                </>
                :   
                <>
                    <div className="macro-breakdown-es">
                        <div className="macro-breakdown-es__main">
                            <h2>Let's get started</h2>
                            <p>Welcome to Stronger You - your online personal trainer! Together we will help you with your nutrition, workouts and knowledge and get you moving in the right direction to achieve your goals.</p>
                        </div>
                        <div className="macro-breakdown-es__action">
                            <Link to="/nutrition" className="btn btn--block btn--orange">Let's Go</Link>
                        </div>
                    </div>
                </>
                }
                </>
            }
        </div>
    );
}

export default NutritionCard;