import React, { useEffect, useCallback, useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import LoggingExercise from './LoggingExercise';
import LoggingControl from './LoggingControl';

// Contexts
import { LoggingContext } from '../../Contexts/LoggingContext/LoggingContext';

// Interfaces

interface ILoggingContext {
    state?: any,
    initialiseWorkout?: Function,
    setExerciseIndex?: Function,
    completeWorkout?: Function,
    resetLogging?: Function
}

interface ILoggingProps {
    history: any
}

const Logging = ({ history }: ILoggingProps) => {
    const globalVariables: any = window;
    const loggingContext: ILoggingContext = useContext(LoggingContext);
    const [showControls, setShowControls] = useState<boolean>(true);
    const { planid, week, dayid } = useParams();

    const initialiseWorkout = useCallback(async (planid, week, dayid) => {
        if(!loggingContext.state.workout) {
            loggingContext.initialiseWorkout && loggingContext.initialiseWorkout(planid, week, dayid);
        }
    }, []);

    useEffect(() => {
        initialiseWorkout(planid, week, dayid);
    }, [week, dayid, initialiseWorkout]);
    
    return (
        <>
            <div className="workout-container">
                {loggingContext.state && loggingContext.state.workout && loggingContext.state.exercises &&
                <>
                    <div className="workout-header">
                        <div className="workout-header__inner">
                            <h1>{loggingContext.state.workout.name}</h1>
                            {loggingContext.state.timer && 
                                <div className="workout-header__timer">{loggingContext.state.timer}</div>
                            }
                            <div className="workout-header__indicator">
                                {loggingContext.state.workout.exercises.map((exercise: any, index: any) =>
                                    <span className={index + 1 <= loggingContext.state.exerciseIndex ? 'is-solid' : ''} key={index}></span>
                                )}
                            </div>
                        </div>
                    </div>
                    <LoggingExercise currentStep={loggingContext.state.exerciseIndex}
                                     workout={loggingContext.state.workout.exercises}
                                     exercises={loggingContext.state.exercises}
                                     logGhost={loggingContext.state.logGhost}
                                     sound={loggingContext.state.sound}
                                     showControls={(value: boolean) => setShowControls(value)} />
                    {showControls && <LoggingControl currentStep={loggingContext.state.exerciseIndex} 
                                    stepCount={loggingContext.state.workout.exercises.length}
                                    setStep={(step: number) => loggingContext.setExerciseIndex && loggingContext.setExerciseIndex(step)}
                                    completeWorkout={() => loggingContext.completeWorkout && loggingContext.completeWorkout()}
                                    resetLogging={() => loggingContext.resetLogging && loggingContext.resetLogging()}
                                    history={history} />
                    }
                </>
                }
            </div>
        </>
    );
}

export default Logging;