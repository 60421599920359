import React, { useState } from 'react';
import ClickToClose from '../ClickToClose/ClickToClose';

interface IDropdownProps {
    size?: string,
    location: string,
    icon: string,
    children?: any
}

const IconDropdown = ({ size, location, icon, children }: IDropdownProps) => {
    const [open, setOpen] = useState(false);

    const toggleMenu = () => {
        let currentStatus = open;
        setOpen(!currentStatus);
    }

    const closeMenu = () => {
        setOpen(false);
    }

    return (
        <ClickToClose close={() => closeMenu()}>
            <div className={`icon-dropdown ${size && size} ${location}`}>
                <button className="icon-dropdown__trigger" aria-expanded={open} onClick={() => toggleMenu()}>
                    <svg>
                        <use xlinkHref={`/assets/icon-library.svg#${icon}`} />
                    </svg>
                </button>
                <div className="icon-dropdown__menu">
                    {children}
                </div>
            </div>
        </ClickToClose>
    );
}

export default IconDropdown;