import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../../Components/Header/Header';
import RecipeCollections from '../../Components/RecipeCollections/RecipeCollections';
import FavouriteRecipesCarousel from '../../Components/FavouriteRecipesCarousel/FavouriteRecipesCarousel';
import NutritionSubNav from '../../Components/SubNavs/NutritionSubNav';

const RecipesCategory = () => {
    const globalVariables: any = window;
    return (
        <>
            <Header title="Recipes" />
            <NutritionSubNav />
            <div className="home-container">
                    {/* <Link to="/favourites/recipes" className="card generic-card category-card favourites">
                        My favourites
                        <svg>
                            <use xlinkHref="/assets/icon-library.svg#star-solid" />
                        </svg>
                    </Link> */}
                <Link to="/recipes/Breakfast" className="card generic-card recipe-category" style={{backgroundImage: `url(${globalVariables.api}/media/images-1594212193478.jpg)`}}>
                    <span>Breakfast</span>
                </Link>
                <Link to="/recipes/Lunch" className="card generic-card recipe-category" style={{backgroundImage: `url(${globalVariables.api}/media/images-1594212233099.jpg)`}}>
                    <span>Lunch</span>
                </Link>
                <Link to="/recipes/Dinner" className="card generic-card recipe-category" style={{backgroundImage: `url(${globalVariables.api}/media/images-1594212262501.jpg)`}}>
                    <span>Dinner</span>
                </Link>
                <Link to="/recipes/Desserts" className="card generic-card recipe-category" style={{backgroundImage: `url(${globalVariables.api}/media/images-1594211925375.jpeg)`}}>
                    <span>Desserts</span>
                </Link>
                <Link to="/recipes/Snacks" className="card generic-card recipe-category" style={{backgroundImage: `url(${globalVariables.api}/media/images-1594212296618.jpg)`}}>
                    <span>Snacks</span>
                </Link>

                <RecipeCollections />
                <FavouriteRecipesCarousel />
            </div>
        </>
    );
}

export default RecipesCategory;