import React, { useContext, useState, useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import medal from '../../medal.svg';

// Components
import Header from '../../Components/Header/Header';

// Contaiers
import { GlobalContext } from '../../Contexts/Global/GlobalContext';

interface IGlobalContext {
    setLoading?: Function
}

interface IChallenge {
    _id?: string,
    name: string,
    slug?: string,
    duration?: number,
    description?: string,
    image?: string,
    startDate?: Date,
    enrollBy?: Date,
    weeks?: any[]
}

const ChallengeDetail = () => {
    const globalVariables: any = window;
    const globalContext: IGlobalContext = useContext(GlobalContext);
    const [challenge, setChallenge] = useState<IChallenge | undefined>(undefined);
    const [participant, setParticipant] = useState<boolean>(false);
    const [modal, setModal] = useState<boolean>(false);

    const { slug } = useParams();

    const fetchChallenge = useCallback(async() => {
        globalContext.setLoading && globalContext.setLoading(true);
        await fetch(`${globalVariables.api}/workout/challenge/slug/${slug}`, {
            method: 'GET',
            credentials: 'include',
            mode: 'cors',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            })
        })
        .then(async (res) => {
            let data = await res.json();

            if(res.status === 200) {
                setChallenge(data);
                globalContext.setLoading && globalContext.setLoading(false);
            } else {
                toast.error("There was an issue fetching challenge.");
                globalContext.setLoading && globalContext.setLoading(false);
            }
        })
        .catch(err => {
            console.log(err);
            toast.error("There was an issue fetching challenge.");
            globalContext.setLoading && globalContext.setLoading(false);
        });
    }, []);

    const checkParticipancy = useCallback(async () => {
            await fetch(`${globalVariables.api}/workout/challenge/participant/${challenge && challenge._id}`, {
                method: 'GET',
                credentials: 'include',
                mode: 'cors',
                headers: new Headers({
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                })
            })
            .then(async (res) => {
                let data = await res.json();

                if(res.status === 200) {
                    setParticipant(true);
                    globalContext.setLoading && globalContext.setLoading(false);
                } else {
                    globalContext.setLoading && globalContext.setLoading(false);
                }
            })
            .catch(err => {
                console.log(err);
                globalContext.setLoading && globalContext.setLoading(false);
            });
    }, [challenge]);

    const challengeSignUp = async () => {
        if(challenge) {
            globalContext.setLoading && globalContext.setLoading(true);
            await fetch(`${globalVariables.api}/workout/challenge/join`, {
                method: 'POST',
                credentials: 'include',
                mode: 'cors',
                headers: new Headers({
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }),
                body: JSON.stringify({challengeid: challenge._id})
            })
            .then(async (res) => {
                let data = await res.json();
    
                if(res.status === 200) {
                    setParticipant(true);
                    setModal(true);
                    globalContext.setLoading && globalContext.setLoading(false);
                } else {
                    toast.error("There was an issue joining challenge.");
                    globalContext.setLoading && globalContext.setLoading(false);
                }
            })
            .catch(err => {
                console.log(err);
                toast.error("There was an issue joining challenge.");
                globalContext.setLoading && globalContext.setLoading(false);
            });
        }
    }

    useEffect(() => {
        fetchChallenge();
    }, [fetchChallenge]);

    useEffect(() => {
        if(challenge) {
            checkParticipancy();
        }
    }, [challenge]);

    return (
        <>
            <Header title="Challenge Detail" />
            <div className="home-container">
                {challenge &&
                    <div className="btn-float-container">
                        <div className="split-layout">
                            <div className="challenge-card">
                                <img src={`${globalVariables.api}/media/${challenge.image}`} alt={challenge.name} />
                                <div className="challenge-card__content">
                                    <div className="challenge-card__title">{challenge.name}</div>
                                    <div className="challenge-card__start">Start date: {moment(challenge.startDate).format("Do MMMM")}</div>
                                </div>
                            </div>
                            <div>
                                <div className="video-library-item__title detail">
                                    {challenge.name}
                                </div>
                                {challenge.description &&
                                    <p dangerouslySetInnerHTML={{__html: challenge.description}}></p>
                                }
                            </div>
                            <div className="btn-float-bottom">
                                {participant ?
                                    <div className="btn btn--green">Joined</div>
                                :
                                    <button className="btn btn--block" onClick={() => challengeSignUp()}>
                                        Sign Me Up
                                    </button>
                                }
                            </div>
                        </div>
                    </div>
                }
            </div>
            {challenge &&
                <>
                <div className={`overlay ${modal ? 'is-active': ''}`}></div>
                <div className={`modal ${modal ? 'is-open': ''}`}>
                    <div className="workout-complete">
                        <p className="workout-complete__title">Get Ready!</p>
                        <img src={medal} alt="Gold Medal" />
                        <p className="workout-complete__message">You have joined the {challenge.name} starting on the {moment(challenge.startDate).format("Do MMMM")}. Your challenge schedule will show on your homescreen on the start date and guide you from week to week.</p>
                        <button className="btn btn--text" onClick={() => setModal(false)}>Dismiss</button>
                    </div>
                </div>
                </>
            }
        </>
    );
}

export default ChallengeDetail;