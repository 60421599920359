import React, { useState, useEffect, useCallback } from 'react';
const countdown = require('../../countdown-beep.mp3');

interface ILoggingTimerHIITProps {
    on: any,
    off: any,
    duration: any
}

const LoggingTimerHIIT = ({ on, off, duration }: ILoggingTimerHIITProps) => {
    const [seconds, setSeconds] = useState<number>(duration * 60);
    const [current, setCurrent] = useState<number>(0);
    const [timingData, setTimingData] = useState<any>(undefined);
    const [timingIndex, setTimingIndex] = useState<number>(0);
    const [started, setStarted] = useState<boolean>(false);
    const [restart, setRestart] = useState<boolean>(false);
    const [complete, setComplete] = useState<boolean>(false);

    const setup = useCallback(() => {
        let data = [];
        let isOn = true;
        let totalDuration = duration * 60;
        for (let i = 0; i < totalDuration;) {
            let obj = {
                type: isOn ? "on" : "off",
                duration: isOn ? on : off
            }

            data.push(obj);

            i = i + (isOn ? parseInt(on) : parseInt(off));
            isOn = !isOn;
        }

        setTimingData(data);

    }, []);

    const start = () => {
        setStarted(true);
        setComplete(false);
        setCurrent(0);
        setTimingIndex(0);
        startTimer();
    }

    const startTimer = (time = 0, index = 0, total = 0) => {
        time = time + 1;
        total = total + 1;
        setCurrent(time);

        if(time === parseInt(timingData[index].duration)) {
            if(total === seconds) {
                setComplete(true);
            } else {
                setTimeout(() => {
                    index = index + 1;
                    setTimingIndex(index);
                    startTimer(0, index, total);
                }, 1000);
            }
        } else {
            setTimeout(() => {
                startTimer(time, index, total);
            }, 1000);
        }
    }

    useEffect(() => {
        setup();
    }, [setup]);

    return (
        <>
        {timingData &&
            <div className="timer-bar">
                <div className="timer-bar__container">
                    <div className={`timer-bar__progress ${timingData[timingIndex].type === "on" ? 'is-on' : 'is-rest'} ${complete ? 'is-complete' : ''}`} style={{width: `${(100 / parseInt(timingData[timingIndex].duration)) * current}%`}}>

                    </div>
                </div>
                <div className="timer-bar__action">
                    {started ?
                    <button className="icon-btn" onClick={complete ? () => {start();} : () => {}}>
                        <svg>
                            <use xlinkHref="/assets/icon-library.svg#restart" />
                        </svg>
                    </button>    
                    :
                    <button className="icon-btn" onClick={() => start()}>
                        <svg>
                            <use xlinkHref="/assets/icon-library.svg#play" />
                        </svg>
                    </button>
                    }
                </div>
                <audio className="audio">
                    <source src={countdown}></source>
                </audio>
            </div>
        }
        </>
    );
}

export default LoggingTimerHIIT;