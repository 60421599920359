import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import medal from '../../medal.svg';

interface ILoggingControlProps {
    currentStep: number,
    stepCount: number,
    setStep: Function,
    completeWorkout: Function,
    resetLogging: Function,
    history: any
}

const messages: string[] = ["Keep it up!", "You smashed it!", "Well done!", "Great work!", "You are amazing!"];
const message: string = messages[Math.floor(Math.random() * messages.length)];

const LoggingControl = ({ currentStep, stepCount, setStep, completeWorkout, resetLogging, history }: ILoggingControlProps) => {
    const [completeModal, setCompleteModal] = useState<boolean>(false);

    const finishWorkout = async () => {
        let complete = await completeWorkout();

        if(complete) {
            setCompleteModal(true);
        } else {
            toast.error("There was a problem completing this workout. Please try again.");
        }
    }

    return (
        <div className="workout-control">
            {currentStep > 1 &&
                <button className="icon-btn" onClick={() => { window.scrollTo(0, 0); setStep(currentStep - 1)}}>
                    <svg>
                        <use xlinkHref="/assets/icon-library.svg#chevron-left" />
                    </svg>
                </button>
            }
            
            {currentStep < stepCount &&
                <button className="icon-btn ml-auto" onClick={() => { window.scrollTo(0, 0); setStep(currentStep + 1)}}>
                    <svg>
                        <use xlinkHref="/assets/icon-library.svg#chevron-right" />
                    </svg>
                </button>
            }

            {currentStep === stepCount &&
                <button className="btn btn--green" onClick={() => finishWorkout()}>Complete</button>
            }

            <div className={`overlay ${completeModal ? 'is-active': ''}`}></div>
            <div className={`modal ${completeModal ? 'is-open': ''}`}>
                <div className="workout-complete">
                    <p className="workout-complete__title">Workout Complete</p>
                    <img src={medal} alt="Gold Medal" />
                    <p className="workout-complete__message">{message}</p>
                    <Link to="/" onClick={() => resetLogging()} className="btn btn--text">Dismiss</Link>
                </div>
            </div>
        </div>
    );
}

export default LoggingControl;