import React, { createContext, useState, useEffect, useCallback } from 'react';
import { toast } from 'react-toastify';

const GlobalContext = createContext({});

type GlobalProviderProps = {
    children?: any
}

interface IUser {
    _id: string,
    firstname: string,
    lastname: string,
    email: string,
    status: string,
    type: string,
    [x:string]: any
}

interface IPlan {
    _id: string,
    uid: string,
    name: string,
    data: any,
    startDate: Date,
    created: Date,
    isActive?: boolean,
    sound?: boolean
}

interface IFavourites {
    _id?: string,
    uid?: string,
    workouts: string[],
    recipes: string[]
}

const GlobalProvider = ({ children }: GlobalProviderProps) => {
    const [loading, setLoading] = useState(false);
    const [initialised, setInitialised] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [user, setUser] = useState<IUser | undefined>(undefined);
    const [plan, setPlan] = useState<IPlan[] | undefined | null>(null);
    const [favourites, setFavourites] = useState<IFavourites | undefined>(undefined);

    let globalVariables: any = window;

    const authenticationCheck = useCallback(async () => {
        setLoading(true);
        
        await fetch(`${globalVariables.api}/auth/check`, {
            method: 'POST',
            credentials: 'include',
            mode: 'cors',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            })
            })
            .then(res => {
                if(res.status === 200) {
                    setInitialAppData();
                } else if (res.status === 401) {
                    window.location.href = "/";
                } else {
                    setIsLoggedIn(false);
                    setInitialised(true);
                }
            })
            .catch(err => {
                console.log(err);
                setIsLoggedIn(false);
                setInitialised(true);
            });

            setLoading(false);
    }, []);

    const setInitialAppData = useCallback(async () => {
        // 1. Get user data
        let user = await fetch(`${globalVariables.api}/user`, {
            method: 'GET',
            credentials: 'include',
            mode: 'cors',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            })
        })
        .then(async (res) => {
            let data = await res.json();

            if(res.status === 200) {
                setUser(data);
                setIsLoggedIn(true);
            } else {
                setIsLoggedIn(false);
            }

            return true;
        })
        .catch(err => {
            console.log(err);
            return true;
        });

        // 2. Get user plan
        let plan = await fetch(`${globalVariables.api}/plan/all`, {
            method: 'GET',
            credentials: 'include',
            mode: 'cors',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            })
        })
        .then(async (res) => {
            let data = await res.json();

            let recentPlan = data[0];

            if(res.status === 200) {
                if(recentPlan.isActive || recentPlan.isActive === undefined) {
                    setPlan([recentPlan]);
                } else {
                    let activePlans = data.filter((plan: IPlan) => plan.isActive === true);
                    
                    if(activePlans.length > 0) {
                        setPlan(activePlans);
                    }
                }
            }
            
            return true;
        })
        .catch(err => {
            console.log(err);
            return true;
        });

        //3. Get user favourites
        let favourites = await fetch(`${globalVariables.api}/favourites`, {
            method: 'GET',
            credentials: 'include',
            mode: 'cors',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            })
        })
        .then(async (res) => {
            let data = await res.json();

            if(res.status === 200) {
                setFavourites(data);
            } else {
                setFavourites({workouts: [], recipes: []});
            }
            
            return true;
        })
        .catch(err => {
            setFavourites({workouts: [], recipes: []});
            console.log(err);
            return true;
        });

        // 4. Check all data fetching complete
        if(user && plan && favourites) {
            setInitialised(true);
            setLoading(false);
        }
    }, []);

    const logOut = useCallback(async () => {
        await fetch(`${globalVariables.api}/auth/logout`, {
            method: 'GET',
            credentials: 'include',
            mode: 'cors',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            })
        })
        .then(async (res) => {
            setIsLoggedIn(false);
            setUser(undefined);
            setPlan(undefined);
        })
        .catch(err => {
            console.log(err);
            setIsLoggedIn(false);
            setInitialised(true);
        });
    }, []);

    const saveFavourites = async () => {
        await fetch(`${globalVariables.api}/favourites`, {
            method: 'POST',
            credentials: 'include',
            mode: 'cors',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }),
            body: JSON.stringify({...favourites})
        })
        .then(async (res) => {
            return true;
        })
        .catch(err => {
            console.log(err);
            return true;
        });
    }

    useEffect(() => {
        authenticationCheck();
    }, [authenticationCheck]);

    useEffect(() => {
        if(initialised) {
            saveFavourites();
        }
    }, [favourites, setFavourites]);

    return (
        <GlobalContext.Provider value={{
            state: {
                loading,
                initialised,
                isLoggedIn,
                user,
                plan,
                favourites
            },
            setLoading,
            setIsLoggedIn,
            setInitialAppData,
            setUser,
            setPlan,
            setFavourites,
            logOut
            }}>
                <>
                {loading && 
                    <div className="loader">
                        <div className="lds-grid"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                    </div>}
                {children}
                </>
        </GlobalContext.Provider>
    );
}

const GlobalConsumer = GlobalContext.Consumer;

export { GlobalContext, GlobalProvider, GlobalConsumer }