import React from 'react';
import iconlibrary from '../../icon-library.svg';

interface IIconProps {
    icon: string
}

const Icon = ({ icon }: IIconProps) => {
    return (
        <svg>
            <use xlinkHref={`${iconlibrary}#${icon}`} />
        </svg>
    );
}

export default Icon;