import React, { useState, useContext, useEffect } from 'react';
import Header from '../../Components/Header/Header';
import moment from 'moment';
import { toast } from 'react-toastify';
import { GlobalContext } from '../../Contexts/Global/GlobalContext';
import { Link } from 'react-router-dom';
import ProgressSubNav from '../../Components/SubNavs/ProgressSubNav';
import DatePicker from 'react-datepicker';

interface IGlobalContext {
    state?: any,
    setLoading?: Function
}

interface ILog {
    _id?: string,
    date: Date,
    exercises?: any[],
    uid: string,
    wid?: string,
    pid?: string,
    id?: string,
    duration?: string,
    name?: string,
    isChallenge?: boolean
}

const LogBook = () => {
    const globalVariables: any = window;
    const globalContext: IGlobalContext = useContext(GlobalContext);
    // const [month, setMonth] = useState<number>(parseInt(moment().format("MM")));
    // const [year, setYear] = useState<number>(2020);
    const [logs, setLogs] = useState<ILog[] | undefined>(undefined);
    const [selectedDate, setSelectedDate] = useState<any>(new Date());

    const fetchLogs = async () => {
        globalContext.setLoading && globalContext.setLoading(true);

        let month = moment(selectedDate).format("M"),
            year = moment(selectedDate).format("YYYY");

        await fetch(`${globalVariables.api}/log/month/${month}/year/${year}`, {
            method: 'GET',
            credentials: 'include',
            mode: 'cors',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            })
        })
        .then(async (res) => {
            let data = await res.json();

            if(res.status === 200) {
                setLogs(data);

                globalContext.setLoading && globalContext.setLoading(false);
            } else if (res.status === 401) {
                window.location.href = "/";
            } else {
                globalContext.setLoading && globalContext.setLoading(false);
                toast.error("Error fetching your progress pictures. Please try again.");
            }
        })
        .catch(err => {
            toast.error("Error fetching your progress pictures. Please try again.");
        });
    }

    useEffect(() => {
        fetchLogs();
    }, [selectedDate]);

    return (
        <>
            <Header title="Log Book" />
            <ProgressSubNav />
            <div className="home-container">
                <div className="upload-library__header">
                    <h2>Workouts: {logs ? logs.length : 0}</h2>
                    <DatePicker
                            selected={selectedDate}
                            onChange={(date) => setSelectedDate(date)}
                            dateFormat="MMMM yyyy"
                            minDate={moment("Thu, 30 Apr 2020 23:59:24 +0000").toDate()}
                            maxDate={moment().toDate()}
                            showMonthYearPicker
                            withPortal />
                    {/* <select value={month} onChange={(e) => setMonth(parseInt(e.target.value))}>
                        <option value="5">May</option>
                        <option value="6">June</option>
                        <option value="7">July</option>
                        <option value="8">August</option>
                        <option value="9">September</option>
                        <option value="10">October</option>
                        <option value="11">November</option>
                    </select> */}
                </div>
                {logs && logs.length > 0 ?
                    <>
                        {logs.map((log, index) => 
                            <>
                            <Link to={`/logbook/entry/${log.isChallenge ? `challenge/${log._id}` : log._id}`} className="expander" key={index}>
                                <div className="expander__heading">
                                    <span><span className="log-date">{moment(log.date).format("Do")}</span> {log.name ? `- ${log.name}` : '- Workout'}</span>
                                    <div className="icon-btn">
                                        <svg>
                                            <use xlinkHref="/assets/icon-library.svg#chevron-right" />
                                        </svg>
                                    </div>
                                </div>
                            </Link>
                            <hr />
                            </>
                        )}
                    </>
                :
                    <>
                    <p>You do not currently have any logs for this month.</p>
                    <Link to="/workout" className="btn btn--block">View Workouts</Link>
                    </>
                }
            </div>
        </>
    );
}

export default LogBook;