import React, { useCallback, useEffect, useState, useContext } from 'react';
import Header from '../../Components/Header/Header';
import moment from 'moment';
import { toast } from 'react-toastify';
import { GlobalContext } from '../../Contexts/Global/GlobalContext';
import { useParams } from 'react-router-dom';
import LogBookEntryExercise from './LogBookEntryExercise';

interface IGlobalContext {
    state?: any,
    setLoading?: Function
}

interface ILog {
    _id?: string,
    date: Date,
    exercises?: any[],
    data?: any[],
    uid: string,
    wid?: string,
    pid?: string,
    id?: string,
    duration?: string,
    name?: string,
    isChallenge?: boolean
}

const LogBookEntry = () => {
    const globalVariables: any = window;
    const globalContext: IGlobalContext = useContext(GlobalContext);
    const [log, setLog] = useState<ILog | undefined>(undefined);

    const { entryid } = useParams();

    const fetchLog = useCallback(async () => {
        globalContext.setLoading && globalContext.setLoading(true);

        await fetch(`${globalVariables.api}/log/${entryid}`, {
            method: 'GET',
            credentials: 'include',
            mode: 'cors',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            })
        })
        .then(async (res) => {
            let data = await res.json();

            if(res.status === 200) {
                setLog(data);

                globalContext.setLoading && globalContext.setLoading(false);
            } else if (res.status === 401) {
                window.location.href = "/";
            } else {
                globalContext.setLoading && globalContext.setLoading(false);
                toast.error("Error fetching your progress pictures. Please try again.");
            }
        })
        .catch(err => {
            toast.error("Error fetching your progress pictures. Please try again.");
        });
    }, []);

    useEffect(() => {
        fetchLog();
    }, [fetchLog]);

    return (
        <>
            <Header title="Log Book Entry" />
            <div className="home-container">
                {log && log.data && log.data.map((exercise, index) => 
                    <LogBookEntryExercise exercise={exercise} key={index} />
                )}
                {log && log.exercises && log.exercises.map((exercise, index) => 
                    <LogBookEntryExercise exercise={exercise} key={index} />
                )}
            </div>
        </>
    )
}

export default LogBookEntry;