import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ChallengeContext } from '../../Contexts/ChallengeContext/ChallengeContext';

interface IChallengeScheduleDayProps {
    day: string,
    complete: boolean | undefined,
    index: number
}

interface IDay {
    _id: string,
    wid: string,
    name?: string,
    type: "video" | "workout",
    data?: any,
    video?: string
}

interface IChallengeContext {
    setDay?: Function
}

const ChallengeScheduleDay = ({ day, complete, index }: IChallengeScheduleDayProps) => {
    const globalVariables: any = window;
    const challengeContext: IChallengeContext = useContext(ChallengeContext);
    const [data, setData] = useState<IDay | undefined>(undefined);

    const fetchDay = useCallback(async () => {
        await fetch(`${globalVariables.api}/workout/challenge/day/${day}`, {
            method: 'GET',
            credentials: 'include',
            mode: 'cors',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            })
        })
        .then(async (res) => {
            let data = await res.json();

            if(res.status === 200) {
                setData(data);
            } 
        })
        .catch(err => {
            console.log(err);
        });
    }, []);

    useEffect(() => {
        fetchDay();
    }, []);

    return (
        <>
        {data &&
            <Link to={`/logging/challenge/${data._id}`} onClick={() => challengeContext.setDay && challengeContext.setDay(data)} className={`plan-card__item ${complete ? 'is-complete': ''}`}>
                <p>{data.name ? data.name : `Day ${index + 1}`}</p>
                <span>
                    <svg>
                        <use xlinkHref={`/assets/icon-library.svg#${complete ? 'check': 'arrow-right'}`} />
                    </svg>
                </span>
            </Link>
        }
        </>
    );
}

export default ChallengeScheduleDay;