import React, { useState, useContext, useEffect } from 'react';
import { withRouter, Link } from 'react-router-dom';

// Contexts
import { LoggingContext } from '../../Contexts/LoggingContext/LoggingContext';
import { ChallengeContext } from '../../Contexts/ChallengeContext/ChallengeContext';

interface IActiveWorkoutPopoverProps {
    location?: any
}

interface ILoggingContext {
    state?: any,
    resetLogging?: Function
}

interface IChallengeContext {
    state?: any,
    resetLogging?: Function
}

const ActiveWorkoutPopover = ({ location }: IActiveWorkoutPopoverProps) => {
    const loggingContext: ILoggingContext = useContext(LoggingContext);
    const challengeContext: IChallengeContext = useContext(ChallengeContext);
    const [display, setDisplay] = useState<boolean>(false);

    const cancelWorkout = (e: any) => {
        e.preventDefault();

        loggingContext.resetLogging && loggingContext.resetLogging();
        challengeContext.resetLogging && challengeContext.resetLogging();
    }

    useEffect(() => {
        if(!location.pathname.startsWith("/logging") && loggingContext.state.workout) {
            setDisplay(true);
        } else if (!location.pathname.startsWith("/logging") && challengeContext.state.day) {
            setDisplay(true);
        } else {
            setDisplay(false);
        }
    }, [location, loggingContext.state, challengeContext.state]);

    return (
        <>
            {display && loggingContext.state.linkHelper &&
                <Link to={`/logging/${loggingContext.state.linkHelper.planid}/${loggingContext.state.linkHelper.week}/${loggingContext.state.linkHelper.dayid}`} className="active-workout">
                    <button className="active-workout__close" onClick={(e) => cancelWorkout(e)}>
                        <svg>
                            <use xlinkHref="/assets/icon-library.svg#close" />
                        </svg>
                    </button>
                    {loggingContext.state.timer}
                    <span className="active-workout__resume">
                        Resume
                    </span>
                </Link> 
            }
            {display && challengeContext.state.day &&
                <Link to={`/logging/challenge/${challengeContext.state.day._id}`} className="active-workout">
                    <button className="active-workout__close" onClick={(e) => cancelWorkout(e)}>
                        <svg>
                            <use xlinkHref="/assets/icon-library.svg#close" />
                        </svg>
                    </button>
                    {challengeContext.state.timer}
                    <span className="active-workout__resume">
                        Resume
                    </span>
                </Link> 
            }
            {display && loggingContext.state.singleLinkHelper &&
                <Link to={`/logging/single/${loggingContext.state.singleLinkHelper}`} className="active-workout">
                    <button className="active-workout__close" onClick={(e) => cancelWorkout(e)}>
                        <svg>
                            <use xlinkHref="/assets/icon-library.svg#close" />
                        </svg>
                    </button>
                    {loggingContext.state.timer}
                    <span className="active-workout__resume">
                        Resume
                    </span>
                </Link> 
            }
        </>
    );
}

export default withRouter(ActiveWorkoutPopover);