import React from 'react';
import { Area, AreaChart, XAxis, Tooltip, Legend, ResponsiveContainer, CartesianGrid } from 'recharts';
import moment from 'moment';

interface IWeightChartProps {
    data?: any,
    chartkey: string,
    label: string
}

const WeightChart = ({ data, chartkey, label }: IWeightChartProps) => {

    const tooltipFormat = (value: any) => {
        return value + (chartkey === "weight" ? "kg" : "cm");
    }

    return (
        <div className="chart-card">
        <label>{label} Progress</label>
        <ResponsiveContainer width="100%" height={250}>
            <AreaChart
                width={500}
                height={300}
                data={data}
                margin={{
                top: 5, right: 0, left: 0, bottom: 0,
                }}>
                <defs>
                    <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor="#FFFFFF" stopOpacity={0.8}/>
                        <stop offset="95%" stopColor="#FFFFFF" stopOpacity={0}/>
                    </linearGradient>
                </defs>
                <XAxis dataKey="date" />
                <Tooltip formatter={tooltipFormat}
                        itemStyle={{ color: '#000' }}
                        labelFormatter={(value) => <b>{moment(value).format("Do MMM YYYY")}</b>} />
                <Legend />
                <Area dot={{ stroke: '#FFFFFF', strokeWidth: 2, fill: '#1cb5e0'}}
                        activeDot={{ stroke: '#1cb5e0', fill: '#1cb5e0' }} 
                        name={label} 
                        type='monotone' 
                        dataKey={chartkey}
                        stroke='#FFFFFF' 
                        strokeWidth="3" 
                        fill="url(#colorUv)"
                        connectNulls
                        isAnimationActive={false} />
                <CartesianGrid stroke="#FFFFFF" />
            </AreaChart>
        </ResponsiveContainer>
        </div>
    );
}

export default WeightChart;